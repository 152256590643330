import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import ResetPasswordForm from '../components/ResetPasswordForm';
import { Button } from 'reactstrap';
import PageHeader from '../components/PageHeader';

import { Colac } from '../components/ParticipantInformationSheet/Colac';
import { BHS } from '../components/ParticipantInformationSheet/BHS';

let PISComp = () => <div />;
switch (process.env.REACT_APP_ORGANIZATION_NAME) {
  case 'colac':
    PISComp = Colac;
    break;
  case 'bhs':
    PISComp = BHS;
    break;
  default:
    break;
}

const CreateAccount = ({ match }) => {
  switch (match.params.site) {
    case 'colac':
      PISComp = Colac;
      break;
    case 'bhs':
      PISComp = BHS;
      break;
    default:
      break;
  }
  const [termsAccepted, setTermsAccepted] = useState(false);
  const component = termsAccepted ? (
    <ResetPasswordForm match={match} />
  ) : (
    <>
      <PISComp />
      <div className="text-center">
        <Button
          onClick={() => setTermsAccepted(true)}
          className="btn btn-primary"
        >
          I AGREE TO THE ABOVE CONDITIONS
        </Button>
      </div>
    </>
  );

  return (
    <>
      <PageHeader
        title={
          termsAccepted ? 'Create an account' : 'Participant Information Sheet'
        }
      />
      <Container>
        <div className="boxitem-content no-top">{component}</div>
        <p className="mt-5 text-right">
          This project is supported by the WorkSafe WorkWell Mental Health
          Improvement Fund.
        </p>
      </Container>
    </>
  );
};

CreateAccount.propTypes = {
  match: PropTypes.object.isRequired
};

export default CreateAccount;
