import React from 'react';

import AppRouter from './components/AppRouter';

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <AppRouter />
      </div>
    );
  }
}
