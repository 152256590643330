import React from 'react';
import { Container, Row } from 'reactstrap';

const TermsOfUse = () => (
  <section>
    <Container>
      <Row>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed mattis
          hendrerit pretium. Vivamus ac lacus nec orci accumsan aliquam.
          Vestibulum vel leo sed purus volutpat facilisis. Ut et lectus semper
          magna sagittis elementum a ut nisi. Nulla facilisi. Vestibulum
          sagittis posuere iaculis. Sed commodo ante sapien, vel iaculis lacus
          feugiat egestas. Phasellus aliquet tortor in efficitur suscipit.
          Vestibulum vehicula libero mauris, ac feugiat tellus egestas eu.
          Integer non fermentum urna. Cras tellus dui, consequat vel pretium
          quis, fringilla nec massa. Fusce sapien ipsum, ultricies non lobortis
          eget, tincidunt ut purus. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Suspendisse at rhoncus erat.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed mattis
          hendrerit pretium. Vivamus ac lacus nec orci accumsan aliquam.
          Vestibulum vel leo sed purus volutpat facilisis. Ut et lectus semper
          magna sagittis elementum a ut nisi. Nulla facilisi. Vestibulum
          sagittis posuere iaculis. Sed commodo ante sapien, vel iaculis lacus
          feugiat egestas. Phasellus aliquet tortor in efficitur suscipit.
          Vestibulum vehicula libero mauris, ac feugiat tellus egestas eu.
          Integer non fermentum urna. Cras tellus dui, consequat vel pretium
          quis, fringilla nec massa. Fusce sapien ipsum, ultricies non lobortis
          eget, tincidunt ut purus. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Suspendisse at rhoncus erat.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed mattis
          hendrerit pretium. Vivamus ac lacus nec orci accumsan aliquam.
          Vestibulum vel leo sed purus volutpat facilisis. Ut et lectus semper
          magna sagittis elementum a ut nisi. Nulla facilisi. Vestibulum
          sagittis posuere iaculis. Sed commodo ante sapien, vel iaculis lacus
          feugiat egestas. Phasellus aliquet tortor in efficitur suscipit.
          Vestibulum vehicula libero mauris, ac feugiat tellus egestas eu.
          Integer non fermentum urna. Cras tellus dui, consequat vel pretium
          quis, fringilla nec massa. Fusce sapien ipsum, ultricies non lobortis
          eget, tincidunt ut purus. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Suspendisse at rhoncus erat.
        </p>
      </Row>
    </Container>
  </section>
);

export default TermsOfUse;
