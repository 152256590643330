import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * Display a confirmation popup for links to external sites
 *
 * @param {Boolean} open - Whether the modal should be open. Ignored if undefined
 * @param {Function} toggle - Callback when the modal is toggled
 * @param {String} href - Href for link
 * @param {String|JSX.Element} children - Link content
 * @param ...props - Additional properties are passed to the link.
 */
const ExternalLink = ({ open, toggle, href, children, ...props }) => {
  const [modalOpen, setModalOpen] = useState(open);
  const onToggle = val => {
    setModalOpen(!modalOpen);
    toggle && toggle(!modalOpen);
  };

  const onClick = e => {
    setModalOpen(true);
    toggle && toggle(true);
    e.preventDefault();
  };

  // if open is set, overwrite the state when it changes
  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <a href={href} onClick={onClick} {...props}>
      {children}
      <Modal isOpen={modalOpen} toggle={onToggle} centered>
        <ModalHeader toggle={onToggle}>External Link</ModalHeader>
        <ModalBody>
          <p>
            This content is not part of WWA, click below to open in a new tab
          </p>
          <a
            href={href}
            onClick={onToggle}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        </ModalBody>
      </Modal>
    </a>
  );
};

ExternalLink.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
};
export default ExternalLink;
