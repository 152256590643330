/**
 * @flow
 * @relayHash c93cdaaa08da276cf4fd0a5d973542f3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FollowOnQuestionsQueryVariables = {||};
export type FollowOnQuestionsQueryResponse = {|
  +followOnQuestions: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +questionName: string,
        +linkedQuestion: {|
          +followOnTitle: string,
          +followOnText: string,
        |},
        +choices: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +text: string
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type FollowOnQuestionsQuery = {|
  variables: FollowOnQuestionsQueryVariables,
  response: FollowOnQuestionsQueryResponse,
|};
*/


/*
query FollowOnQuestionsQuery {
  followOnQuestions {
    edges {
      node {
        questionName
        linkedQuestion {
          followOnTitle
          followOnText
          id
        }
        choices {
          edges {
            node {
              text
              id
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "questionName",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "followOnTitle",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "followOnText",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "text",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FollowOnQuestionsQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "followOnQuestions",
        "storageKey": null,
        "args": null,
        "concreteType": "FollowOnQuestionNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "FollowOnQuestionNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "FollowOnQuestionNode",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "linkedQuestion",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "QuestionNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "choices",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ChoiceNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ChoiceNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ChoiceNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FollowOnQuestionsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "followOnQuestions",
        "storageKey": null,
        "args": null,
        "concreteType": "FollowOnQuestionNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "FollowOnQuestionNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "FollowOnQuestionNode",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "linkedQuestion",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "QuestionNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "choices",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ChoiceNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ChoiceNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ChoiceNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FollowOnQuestionsQuery",
    "id": null,
    "text": "query FollowOnQuestionsQuery {\n  followOnQuestions {\n    edges {\n      node {\n        questionName\n        linkedQuestion {\n          followOnTitle\n          followOnText\n          id\n        }\n        choices {\n          edges {\n            node {\n              text\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4186c157d899dc06f8683549fb92421a';
module.exports = node;
