import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Modal, Button } from 'reactstrap';

const videoSizes = [
  {
    width: 1920,
    height: 1080
  },
  {
    width: 1280,
    height: 720
  },
  {
    width: 854,
    height: 480
  },
  {
    width: 640,
    height: 360
  },
  {
    width: 426,
    height: 240
  }
];

function getVideoDimension() {
  const width = window.innerWidth;
  const size =
    videoSizes.find(videoSize => videoSize.width < width) ||
    videoSizes[videoSizes.length - 1];
  return {
    width: `${size.width}px`,
    height: `${size.height}px`,
    maxWidth: `${size.width}px`
  };
}
/**
 * VideoModal
 *
 * Displays a modal video popout.
 *
 * @param {string} link Url of the video to display. Works with any URL that
 *    https://www.npmjs.com/package/react-player will accept
 * @param {string} heading - Modal heading
 * @param {bool} shown - Whether the modal is open or not. Managed by the parent
 * @param {function} onClose - Triggered when the modal is closed. The parent should ensure that
 *    this causes `shown` to become false
 */
const VideoModal = ({ link, children, shown, onClose, ...props }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  return (
    <Button
      className="btn btn-primary"
      onClick={() => setShowVideoModal(true)}
      {...props}
    >
      {children}
      <Modal
        isOpen={showVideoModal}
        toggle={() => setShowVideoModal(!showVideoModal)}
        centered
        className="video-modal"
        style={{ ...getVideoDimension() }}
      >
        <ReactPlayer url={link} controls {...getVideoDimension()} />
      </Modal>
    </Button>
  );
};

VideoModal.propTypes = {
  link: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  shown: PropTypes.bool,
  onClose: PropTypes.func
};

export default VideoModal;
