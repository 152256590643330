import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import { Field } from 'formik';
import ReactstrapSelectInput from './FormikReactStrapSelectInput';

const SelectInput = ({
  questionName,
  errors,
  submitCount,
  choices,
  ...props
}) => (
  <React.Fragment>
    <Field
      className="selectQuestion"
      name={questionName}
      component={ReactstrapSelectInput}
      choices={choices}
      {...props}
    />
    <div>
      {submitCount > 0 && errors && errors[questionName] ? (
        <FormFeedback className="d-block">{errors[questionName]}</FormFeedback>
      ) : (
        ''
      )}
    </div>
  </React.Fragment>
);

SelectInput.propTypes = {
  questionName: PropTypes.string,
  errors: PropTypes.object,
  submitCount: PropTypes.number,
  choices: PropTypes.array
};

export { SelectInput };
