import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Wrapper that scrolls to a question if it's invalid when attempting to submit
const ErrorScroll = ({ errors, isSubmitting, questionName, children }) => {
  const ref = useRef();

  useLayoutEffect(() => {
    if (
      !isSubmitting ||
      (!errors[questionName] && !errors[`${questionName}_other`]) ||
      !ref.current
    )
      return;
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [errors, isSubmitting, questionName]);

  return <div ref={ref}>{children}</div>;
};

ErrorScroll.propTypes = {
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  questionName: PropTypes.string,
  children: PropTypes.element
};

export default ErrorScroll;
