import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import { Field } from 'formik';
import ReactstrapRadioOther from './FormikReactStrapRadioOther';

const style = {
  maxWidth: '100%'
};

/* A radioChoice component which also takes displays a text input when "other" is selected 
  The schema passed to formik should be something like 
  {
      questionText: 'What is your gender?',
      questionName: 'gender',
      questionNumber: '2',                        // If you use this component, you'll need to add
              these to every question because
      Component: RadioChoiceOther,
      validation: string().required('Please fill in the question above.'),
      choices: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
        { label: 'Other', value: 'O' }            // value: 'O' is required
      ]
    },
    {
      questionName: 'gender_other',               // same name but with _other appended required
      Component: false,                           // Don't display this in AssessmentForm
      validation: string().when('gender', {       // validation linked to base field
        is: 'O',
        then: string().required('Please fill in the question above.'),
        otherwise: string(),
      })
    },

  */
const RadioChoiceOther = ({
  questionName,
  errors,
  submitCount,
  choices,
  ...props
}) => (
  <React.Fragment>
    {choices.map(({ label, value }) => (
      <Field
        key={value}
        type="radio"
        className="radioQuestion"
        name={questionName}
        label={label}
        value={value}
        component={ReactstrapRadioOther}
        style={style}
        {...props}
      />
    ))}
    <div>
      {submitCount > 0 && errors && errors[questionName] ? (
        <FormFeedback className="d-block">{errors[questionName]}</FormFeedback>
      ) : (
        ''
      )}
    </div>
  </React.Fragment>
);

RadioChoiceOther.propTypes = {
  questionName: PropTypes.string,
  errors: PropTypes.object,
  submitCount: PropTypes.number,
  choices: PropTypes.array
};

export { RadioChoiceOther };
