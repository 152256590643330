/**
 * @flow
 * @relayHash 87b4d0e82eda88403204ada9ec97a148
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResetPasswordInput = {|
  uid: string,
  token: string,
  newPassword: string,
  clientMutationId?: ?string,
|};
export type ResetPasswordFormMutationVariables = {|
  input: ResetPasswordInput
|};
export type ResetPasswordFormMutationResponse = {|
  +resetPassword: ?{|
    +success: ?boolean
  |}
|};
export type ResetPasswordFormMutation = {|
  variables: ResetPasswordFormMutationVariables,
  response: ResetPasswordFormMutationResponse,
|};
*/


/*
mutation ResetPasswordFormMutation(
  $input: ResetPasswordInput!
) {
  resetPassword(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ResetPasswordInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resetPassword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResetPasswordPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResetPasswordFormMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ResetPasswordFormMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ResetPasswordFormMutation",
    "id": null,
    "text": "mutation ResetPasswordFormMutation(\n  $input: ResetPasswordInput!\n) {\n  resetPassword(input: $input) {\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ed75481179acaec6cc155750e56695b';
module.exports = node;
