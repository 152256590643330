import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from 'react-relay';
import environment from '../relayEnvironment';
import TopicCard from '../components/TopicCard';
import DashboardLineChart from '../components/DashboardLineChart';
import SiteSelector from '../components/SiteSelector';
import { Container, Row, Col } from 'reactstrap';
import '../assets/css/Dashboard.css';
import VideoModal from '../components/VideoModal';
import { links } from './Help';
import { nextNotificationTime } from '../__utils__/notificationDate';
import { Plugins } from '@capacitor/core';

const { LocalNotifications } = Plugins;

const IndicatorColors = { at_risk: '#ff2200', acceptable: '#2345a2' };

const query = graphql`
  query DashboardQuery($lookback: Int!, $sitePk: Int) {
    ...DashboardLineChart_data
    ...SiteSelector_list
    dashboardWellbeing(lookback: $lookback, sitePk: $sitePk) {
      edges {
        node {
          id
          label
          status
          mean
          responses
        }
      }
    }
    nextPeriod
  }
`;

const getLevel = mean => {
  if (mean < 40) {
    return 'Low';
  }
  if (mean > 80) {
    return 'High';
  }
  return 'Moderate';
};

const DashboardInner = ({ props: propsInner, error, setSitePk }) => {
  useEffect(() => {
    if (!propsInner) return;
    const notificationTime = nextNotificationTime(propsInner.nextPeriod);

    // end early if there's no further reporting period
    if (notificationTime === null) {
      return;
    }

    LocalNotifications.schedule({
      notifications: [
        {
          title: 'Workplace Wellbeing Assist',
          body: `The latest fortnight of user summaries is now available.`,
          // schedule for in the greater of 3 days or the start of the next fortnight
          schedule: { at: notificationTime },
          sound: null,
          attachments: null,
          actionTypeId: '',
          extra: null,
          id: 2
        }
      ]
    });
  });
  useEffect(() => {
    document.title = `Wellbeing Assist - Dashboard`;
  });
  if (!propsInner) {
    return (
      <div>
        <Row>
          <Col lg="10" md="9" sm="12" xs="12" className="mt-5">
            <div className="boxitem-content no-top">
              <p>Loading...</p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col lg="10" md="9" sm="12" xs="12" className="mt-5">
          <SiteSelector list={propsInner} callback={setSitePk} />
          {/* show the 'results coming soon' page if there are no responses */}
          {propsInner.dashboardWellbeing.edges.reduce(
            (prev, curr) => prev + curr.node.responses,
            0
          ) === 0 ? (
            <div className="boxitem-content no-top">
              <p>
                Staff are now completing the Workplace Wellbeing Survey. We'll
                let you know when the results are on your dashboard.
              </p>
              <p>
                Please note, results on the dashboard are updated every 2 weeks.
              </p>
            </div>
          ) : (
            <>
              <div className="boxitem-title blue">
                <h2>Current Workplace Mental Health</h2>
              </div>
              <div className="boxitem-content text-center">
                {propsInner.dashboardWellbeing.edges
                  .slice(7, 8)
                  .map(({ node }) => (
                    <div key={node.id}>
                      <TopicCard
                        key={node.label}
                        cardTitleText={getLevel(node.mean)}
                        cardTextString="Lorem ipsum dolor silat. Crescae num pilat."
                        cardValue={node.mean}
                        indicatorColor={IndicatorColors[node.status]}
                      />
                      <p>Results based on: {node.responses} staff </p>
                    </div>
                  ))}
              </div>
              <div className="boxitem-title blue">
                <h2>Workplace Mental Health Trend</h2>
              </div>
              <div className="boxitem-content">
                <DashboardLineChart
                  data={propsInner}
                  topic="Organisational wellbeing"
                />
              </div>
              <div className="boxitem-title blue">
                <h2>Workplace Mental Health Insights</h2>
              </div>
              <div className="boxitem-content">
                <Row className="no-gutters">
                  {propsInner.dashboardWellbeing.edges
                    .slice(1, 7)
                    .map(({ node }) => (
                      <Col xs="12" sm="6" md="6" lg="4" key={node.id}>
                        <TopicCard
                          cardTitleText={node.label}
                          cardTextString="Lorem ipsum dolor silat. Crescae num pilat."
                          cardValue={node.mean}
                          indicatorColor={IndicatorColors[node.status]}
                        />
                      </Col>
                    ))}
                </Row>
              </div>
              <div className="boxitem-title blue">
                <h2>Current Staff Wellbeing</h2>
              </div>
              <div className="boxitem-content">
                <p>A snapshot of the wellbeing of your staff at the moment.</p>
                {propsInner.dashboardWellbeing.edges
                  .slice(0, 1)
                  .map(({ node }) => (
                    <TopicCard
                      key={node.label}
                      cardTitleText={getLevel(node.mean)}
                      cardTextString="Lorem ipsum dolor silat. Crescae num pilat."
                      cardValue={node.mean}
                      indicatorColor={IndicatorColors[node.status]}
                    />
                  ))}
              </div>
              <div className="boxitem-title blue">
                <h2>Staff Wellbeing Trend</h2>
              </div>
              <div className="boxitem-content">
                <p>How the wellbeing of your staff has changed over time.</p>
                <DashboardLineChart
                  data={propsInner}
                  topic="Personal wellbeing"
                />
              </div>
            </>
          )}
        </Col>
        <Col lg="2" md="3" sm="12" xs="12" className="mt-5 text-center">
          <VideoModal
            link={links.dashboard[true]}
            className="mt-5"
            color="outline-primary"
            style={{ width: '178px' }}
          >
            Understanding your dashboard
          </VideoModal>
          {process.env.REACT_APP_ORGANIZATION_NAME === 'swanhill' ? (
            <a
              href="/request-consultation"
              style={{ width: '178px' }}
              className="btn btn-outline-primary mt-3"
            >
              Request a consultation
            </a>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

DashboardInner.propTypes = {
  props: PropTypes.object,
  error: PropTypes.object,
  setSitePk: PropTypes.func
};

const Dashboard = ({ match }) => {
  const [sitePk, setSitePk] = useState(
    // parseInt(null) === NaN, so cast it back to null if that's the case
    // because the server will respond with your 'default' site if variable sitePk === null
    parseInt(sessionStorage.getItem('sitePk')) || null
  );
  return (
    <Container>
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{ lookback: match.params.lookback || 0, sitePk }}
        render={({ props, error }) => (
          <DashboardInner {...{ props, error, setSitePk }} />
        )}
      />
    </Container>
  );
};

Dashboard.propTypes = {
  match: PropTypes.object
};

export default Dashboard;
