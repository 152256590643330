import React, { useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import { PropTypes } from 'prop-types';

import { Input, Label } from 'reactstrap';

const SiteSelector = ({ list, callback }) => {
  const dispatch = newSite => {
    sessionStorage.setItem('sitePk', newSite);
    callback && callback(newSite);
  };
  const handle = newSite => {
    dispatch(parseInt(newSite));
  };

  let storedSite = sessionStorage.getItem('sitePk') || list.sites[0].pk;

  useEffect(() => {
    if (sessionStorage.getItem('sitePk') === null) {
      dispatch(list.sites[0].pk);
    }
  });

  if (list.sites.length === 1) {
    return null;
  }
  return (
    <>
      <div className="boxitem-title blue">
        <h2>Site Selector</h2>
      </div>
      <div className="boxitem-content mb-4">
        Viewing dashboard for site:
        <Label className="mx-3">
          <Input
            type="select"
            value={storedSite}
            onChange={e => handle(e.target.value)}
          >
            {list.sites.map(choice => (
              <option key={choice.pk} value={choice.pk}>
                {choice.siteName}
                {choice.newData && ' (new data available)'}
              </option>
            ))}
          </Input>
        </Label>
      </div>
    </>
  );
};

SiteSelector.propTypes = {
  list: PropTypes.object,
  callback: PropTypes.func
};

export default createFragmentContainer(withRouter(SiteSelector), {
  list: graphql`
    fragment SiteSelector_list on RelayQuery {
      sites {
        pk
        siteName
        newData
      }
    }
  `
});
