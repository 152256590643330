import React from 'react';
import { Router, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import NonPrivateRoute from './NonPrivateRoute';
import PublicRoute from './PublicRoute';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';
import Home from '../screens/Home';
import Login from '../screens/Login';
import Logout from '../screens/Logout';
import About from '../screens/About';
import CreateAccount from '../screens/CreateAccount';
import MyResults from '../screens/MyResults';
import Dashboard from '../screens/Dashboard';
import SubFocus from '../screens/SubFocus';
import WellbeingSurvey from '../screens/WellbeingSurvey';
import FollowOnQuestions from '../screens/FollowOnQuestions';
import NotFound from '../screens/NotFound';
import Resources from '../screens/Resources';
import history from '../history';
import ResourceView from '../screens/ResourceView';
import TermsOfUse from '../screens/TermsOfUse';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import AdminPage from '../screens/Admin';
import SuperAdminPage from '../screens/SuperAdmin';
import UrgentSupport from '../screens/UrgentSupport';
import SetPassword from '../screens/SetPassword';
import Help from '../screens/Help';
import RequestConsultation from '../screens/RequestConsultation';

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <PublicRoute component={Home} exact path="/" />
      <PublicRoute
        component={Login}
        path="/Login"
        header={{ title: 'Log in' }}
      />
      <PublicRoute
        component={Logout}
        path="/Logout"
        header={{ title: 'Log out' }}
      />
      <PublicRoute
        component={ResetPassword}
        path="/reset-password/:uid/:token"
        header={{ title: 'Password Reset' }}
      />
      <PublicRoute
        component={ForgotPassword}
        path="/reset-password"
        header={{ title: 'Password Reset' }}
      />
      {/* header included in component */}
      <PublicRoute
        component={CreateAccount}
        path="/create-account/:uid/:token/:site?"
      />
      <PublicRoute
        component={SetPassword}
        path="/set-password"
        header={{ title: 'Thank you for Registering' }}
      />
      <NonPrivateRoute
        component={TermsOfUse}
        path="/terms-of-use"
        header={{ title: 'Terms of Use' }}
      />
      <PrivateRoute
        component={PrivacyPolicy}
        path="/privacy-policy"
        header={{ title: 'Privacy Policy' }}
      />
      <PrivateRoute
        component={AdminPage}
        path="/admin-page"
        header={{ title: 'Admin page' }}
      />
      <PrivateRoute
        component={SuperAdminPage}
        path="/super-admin-page"
        header={{ title: 'Super Admin page' }}
      />
      <PrivateRoute
        component={UrgentSupport}
        path="/urgent-support"
        header={{ title: 'Urgent Support' }}
      />
      <PrivateRoute
        component={About}
        path="/about"
        header={{ title: 'Workplace Wellbeing Assist' }}
      />
      <PrivateRoute
        component={WellbeingSurvey}
        path="/survey"
        header={{
          title: 'Workplace Wellbeing Survey'
        }}
      />
      <PrivateRoute
        component={FollowOnQuestions}
        path="/follow-on-questions"
        header={{ title: 'Tell us more...' }}
      />
      {/* header included in component */}
      <PrivateRoute component={ResourceView} path="/resource/:slug" />
      <PrivateRoute
        component={Resources}
        path="/resources"
        header={{ title: 'Resources' }}
      />
      <PrivateRoute component={Dashboard} path="/dashboard/:lookback?" />
      {/* header included in component */}
      <PrivateRoute component={SubFocus} path="/sub-focus/:topic?" />
      <PrivateRoute component={MyResults} path="/my-results/:lookback?" />
      <PrivateRoute
        component={RequestConsultation}
        path="/request-consultation"
      />
      <PrivateRoute
        component={Help}
        path="/help"
        header={{ title: 'How to use Workplace Wellbeing Assist' }}
      />
      <PrivateRoute path="/assessment" />
      <PublicRoute component={NotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
