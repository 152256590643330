/**
 * @flow
 * @relayHash d2d122bc107ebf65510220946401c74d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MappedResourcesQueryVariables = {|
  mappingLow?: ?boolean,
  mappingMedium?: ?boolean,
  mappingHigh?: ?boolean,
|};
export type MappedResourcesQueryResponse = {|
  +resources: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +slug: string,
        +title: string,
        +subtitle: ?string,
        +liked: ?boolean,
        +id: string,
      |}
    |}>
  |}
|};
export type MappedResourcesQuery = {|
  variables: MappedResourcesQueryVariables,
  response: MappedResourcesQueryResponse,
|};
*/


/*
query MappedResourcesQuery(
  $mappingLow: Boolean
  $mappingMedium: Boolean
  $mappingHigh: Boolean
) {
  resources(mappingLow: $mappingLow, mappingMedium: $mappingMedium, mappingHigh: $mappingHigh, pageType: "GENERAL") {
    edges {
      node {
        slug
        title
        subtitle
        liked
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "mappingLow",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "mappingMedium",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "mappingHigh",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resources",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mappingHigh",
        "variableName": "mappingHigh"
      },
      {
        "kind": "Variable",
        "name": "mappingLow",
        "variableName": "mappingLow"
      },
      {
        "kind": "Variable",
        "name": "mappingMedium",
        "variableName": "mappingMedium"
      },
      {
        "kind": "Literal",
        "name": "pageType",
        "value": "GENERAL"
      }
    ],
    "concreteType": "ResourcePageNodeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ResourcePageNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ResourcePageNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subtitle",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "liked",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MappedResourcesQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MappedResourcesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "MappedResourcesQuery",
    "id": null,
    "text": "query MappedResourcesQuery(\n  $mappingLow: Boolean\n  $mappingMedium: Boolean\n  $mappingHigh: Boolean\n) {\n  resources(mappingLow: $mappingLow, mappingMedium: $mappingMedium, mappingHigh: $mappingHigh, pageType: \"GENERAL\") {\n    edges {\n      node {\n        slug\n        title\n        subtitle\n        liked\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16be87fd82f47eb4a531fc042bb74915';
module.exports = node;
