import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import bhs from '../assets/images/BHS_black_logo.svg';
import workwell from '../assets/images/WS_black_logo.svg';
import feduni from '../assets/images/fed_black_logo.svg';
import prevention from '../assets/images/pu_black_logo.svg';
import FooterLogo from './FooterLogo';
import { Link } from 'react-router-dom';

const footerStyle = {
  verticalAlign: 'middle',
  marginTop: '5em',
  marginBottom: '50px',
  textAlign: 'center'
};

const Footer = () => (
  <footer style={footerStyle}>
    <Container>
      <Row>
        {/* display urgent support link above logos on small screens */}
        <Col xs="12" className="d-block d-md-none pb-3">
          <Link className="mx-a" to="/privacy-policy">
            Data Privacy
          </Link>
          <br />
          <Link className="mx-a" to="/urgent-support">
            Need urgent support?
          </Link>
        </Col>
        <Col xs="12" md="8">
          <Row id="footer-container">
            <FooterLogo
              src={prevention}
              testid="img_prevention"
              extraClass="preventionimg"
              alt="Prevention United"
            />
            <FooterLogo
              src={feduni}
              testid="img_feduni"
              extraClass="feduniimg"
              alt="Federation University"
            />
            <FooterLogo
              src={bhs}
              testid="img_bhs"
              extraClass="bhsimg"
              alt="Ballarat Health Services"
            />
            <FooterLogo
              src={workwell}
              testid="img_workwell"
              extraClass="workwellimg"
              alt="WorkSafe WorkWell"
            />
          </Row>
        </Col>
        {/* show urgent support link on wider screens */}
        <Col xs="1" className="p-0 d-none d-md-block border-right" />
        <Col xs="12" sm="12" md="3" className="d-none d-md-block">
          <Link className="mx-a" to="/privacy-policy">
            Data Privacy
          </Link>
          <br />
          <Link className="mx-a" to="/urgent-support">
            Need urgent support?
          </Link>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
