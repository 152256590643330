/**
 * @flow
 * @relayHash c08c1c73613b9cc0958d1b88a6884de5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DataDownloadButtonQueryVariables = {|
  org: number
|};
export type DataDownloadButtonQueryResponse = {|
  +dataDumpDownload: ?{|
    +encodedData: ?string,
    +orgName: ?string,
    +timestamp: ?string,
  |}
|};
export type DataDownloadButtonQuery = {|
  variables: DataDownloadButtonQueryVariables,
  response: DataDownloadButtonQueryResponse,
|};
*/


/*
query DataDownloadButtonQuery(
  $org: Int!
) {
  dataDumpDownload(org: $org) {
    encodedData
    orgName
    timestamp
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "org",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "dataDumpDownload",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "org",
        "variableName": "org"
      }
    ],
    "concreteType": "DataDumpDownload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "encodedData",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "timestamp",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DataDownloadButtonQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DataDownloadButtonQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "DataDownloadButtonQuery",
    "id": null,
    "text": "query DataDownloadButtonQuery(\n  $org: Int!\n) {\n  dataDumpDownload(org: $org) {\n    encodedData\n    orgName\n    timestamp\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04c0817eaabfaf75b7146b140e58a391';
module.exports = node;
