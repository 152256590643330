import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Menu from './Menu';
import KTen from '../screens/KTen';
import Demographics from '../screens/Demographics';
import AustralianQualityOfLife from '../screens/AustralianQualityOfLife';
import PAWSurveyBullyingModule from '../screens/PAWSurveyBullyingModule';
import MentalHealthContinuum from '../screens/MentalHealthContinuum';
import PsychologicalClimate from '../screens/PsychologicalClimate';
import SystemUsabilityScale from '../screens/SystemUsabilityScale';
import CopenhagenPsychosocial from '../screens/CopenhagenPsychosocial';
import CopenhagenPsychosocialPt2 from '../screens/CopenhagenPsychosocialPt2';
import InterventionSatisfaction from '../screens/InterventionSatisfaction';
import Footer from './Footer';
import PageHeader from './PageHeader';

const formComponents = {
  Demographics: Demographics,
  K10: KTen,
  AustralianQualityOfLife: AustralianQualityOfLife,
  PAWSurveyBullyingModule: PAWSurveyBullyingModule,
  MentalHealthContinuumShortForm: MentalHealthContinuum,
  PsychologicalClimate: PsychologicalClimate,
  SystemUsabilityScale: SystemUsabilityScale,
  CopenhagenPsychosocial: CopenhagenPsychosocial,
  CopenhagenPsychosocialPt2: CopenhagenPsychosocialPt2,
  InterventionSatisfaction: InterventionSatisfaction
};

const PrivateRoute = ({ component: Component, header, ...rest }) => {
  // There is no real security protection in a react app. Anything client side is insecure.
  // This is easily hacked but will still provide a good user experience.
  const isAuthenticated =
    window.sessionStorage.getItem('jwt') !== null &&
    window.sessionStorage.getItem('jwt').length > 0;

  const nextForm = window.sessionStorage.getItem('nextForm');

  const renderContent = () => {
    if (isAuthenticated && nextForm !== 'null') {
      window.history.pushState({}, null, '/assessment');
      return (
        <React.Fragment>
          <Menu path={rest.path} isAuthenticated />
          {/* PageHeader is _not_ included here, as this causes duplicate of the header due to the
            way in which AssesmentForms are loaded */}
          <Route component={formComponents[nextForm]} {...rest} />
          <Footer />
        </React.Fragment>
      );
    }

    if (isAuthenticated) {
      return (
        <React.Fragment>
          <Menu path={rest.path} isAuthenticated />
          <PageHeader {...header} />
          <Route {...rest} component={Component} />
          <Footer />
        </React.Fragment>
      );
    }

    return (
      <Redirect
        {...rest}
        to={{ pathname: '/login', state: { from: rest.location } }}
      />
    );
  };

  return renderContent();
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  header: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  })
};

export default PrivateRoute;
