import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import packageJson from '../../package.json';

const Home = () => {
  useEffect(() => {
    document.title = `Wellbeing Assist`;
  });
  return (
    <Container>
      <h5>
        For information about this site, please contact beri@federation.edu.au
      </h5>
      <p className="mb-5">
        Registered users may <a href="/login">log in</a>.
      </p>
      <p className="small mt-5 text-right">
        Workplace Wellbing Assist version {packageJson.version}
      </p>
    </Container>
  );
};

export default Home;
