import React from 'react';
import { Table } from 'reactstrap';
import environment from '../../relayEnvironment';
import graphql from 'babel-plugin-relay/macro';
import { PropTypes } from 'prop-types';
import { QueryRenderer } from 'react-relay';

const query = graphql`
  query SiteTableQuery {
    sites {
      siteName
      pk
      newData
      users
      activeUsers
      managers
    }
  }
`;

const SiteTable = () => (
  <Table hover={true} striped={true}>
    <thead>
      <tr>
        <th>Site Name</th>
        <th>No. Users</th>
        <th>No. Users (not active)</th>
        <th>No. Managers</th>
      </tr>
    </thead>
    <tbody>
      <QueryRenderer
        environment={environment}
        query={query}
        render={({ props }) =>
          props
            ? props.sites.map(site => (
                <tr key={site.pk}>
                  <th scope="row">{site.siteName}</th>
                  <td>{site.users}</td>
                  <td>{site.activeUsers}</td>
                  <td>{site.managers}</td>
                </tr>
              ))
            : null
        }
      />
    </tbody>
  </Table>
);

SiteTable.propTypes = {
  sites: PropTypes.object
};

export default SiteTable;
