import React from 'react';
import UpdateAbility from '../UpdateAbility';
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const Logout = () => {
  window.sessionStorage.clear();
  UpdateAbility();
  return (
    <Container className="text-center">
      <div className="boxitem-content no-top x-padding">
        <p className="lead">
          Thank you for participating in the study and for using Workplace
          Wellbeing Assist
        </p>
        <p>Please check in with us again soon.</p>
        <Link to="/login">
          <Button size="lg" className="btn btn-primary mb-3 mt-3">
            Return to Log In
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Logout;
