import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from 'react-relay';
import environment from '../relayEnvironment';
import TopicCard from '../components/TopicCard';
import DashboardLineChart from '../components/DashboardLineChart';
import SubFocusBarChart from '../components/SubFocusBarChart';
import { Container, Row, Col } from 'reactstrap';
import PageHeader from '../components/PageHeader';
import '../assets/css/SubFocus.css';

const IndicatorColors = { at_risk: '#ff2200', acceptable: '#2345a2' };

const query = graphql`
  query SubFocusQuery($lookback: Int!, $sitePk: Int!) {
    ...DashboardLineChart_data
    ...SubFocusBarChart_data
    dashboardWellbeing(lookback: $lookback, sitePk: $sitePk) {
      surveyDatetime
      edges {
        node {
          id
          label
          status
          mean
        }
      }
    }
  }
`;

const capitalise = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const resourceLinks = {
  demand: 'job-demand',
  control: 'job-control',
  'manager support': 'management-support',
  'team support': 'team-support',
  recognition: 'workplace-recognition',
  'culture and safety': 'organisational-culture-and-safety'
};

const getLevel = mean => {
  if (mean < 40) {
    return 'low';
  }
  if (mean > 80) {
    return 'high';
  }
  if (mean >= 40 && mean <= 80) {
    return 'moderate';
  }
};

const SubFocus = ({ match }) => {
  const sitePk = parseInt(sessionStorage.getItem('sitePk'));
  return (
    <div>
      <PageHeader title={capitalise(match.params.topic)} />
      <Container>
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{ lookback: match.params.lookback || 0, sitePk }}
          render={({ props }) => {
            if (!props) {
              return <div>Loading...</div>;
            }
            return (
              <div>
                <Row>
                  <Col>
                    <div className="boxitem-title blue">
                      <h2>Current</h2>
                    </div>
                    <div className="boxitem-content">
                      {props.dashboardWellbeing.edges.map(({ node }) =>
                        node.label.toLowerCase() ===
                        match.params.topic.toLowerCase() ? (
                          <Row key={node.id}>
                            <Col xs="12" sm="6" md="6" lg="6" key={node.id}>
                              <TopicCard
                                cardTitleText=""
                                cardTextString="Lorem ipsum dolor silat. Crescae num pilat."
                                cardValue={node.mean}
                                indicatorColor={IndicatorColors[node.status]}
                              />
                            </Col>
                            <Col xs="12" sm="6" md="5" lg="4">
                              <div className="mt-5 pt-4 d-none d-xl-block d-lg-block d-sm-block" />
                              <p className="lead mt-5">
                                Your {node.label.toLowerCase()} score is
                                currently in the
                                <b className="w-range">
                                  {' '}
                                  {getLevel(node.mean)} range.{' '}
                                </b>
                              </p>
                            </Col>
                          </Row>
                        ) : null
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="boxitem-title blue">
                      <h2>Trend</h2>
                    </div>
                    <div className="boxitem-content">
                      <DashboardLineChart
                        data={props}
                        topic={capitalise(match.params.topic)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="boxitem-title blue">
                      <h2>Concerns</h2>
                    </div>
                    <div className="boxitem-content">
                      <SubFocusBarChart
                        data={props}
                        topic={capitalise(match.params.topic)}
                      />
                      <br />
                      <a
                        href={'/resource/' + resourceLinks[match.params.topic]}
                        className="float-right btn btn-primary"
                      >
                        {(match.params.topic + ' resource').toUpperCase()}
                      </a>
                      <p>
                        <b>
                          Check out the WWA {capitalise(match.params.topic)}{' '}
                          resource and take action.
                        </b>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          }}
        />
        <div className="text-center mt-3">
          <a href="/dashboard" className="btn btn-primary">
            BACK TO DASHBOARD
          </a>
        </div>
      </Container>
    </div>
  );
};

SubFocus.propTypes = {
  match: PropTypes.object,
  dashboardWellbeing: PropTypes.shape({
    surveyDatetime: PropTypes.string,
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: {
          label: PropTypes.string,
          status: PropTypes.string,
          mean: PropTypes.string
        }
      })
    )
  })
};

export default SubFocus;
