import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Button } from 'reactstrap';
import { Flipper } from 'react-flip-toolkit';
import '../assets/css/Resources.scss';
import ResourceCard from '../components/ResourceCard';
import environment from '../relayEnvironment';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

const likeableIcon = 'ion-md-star-outline';
const likedIcon = 'ion-md-star text-warning active-like';

const buttonText = showManagerResources =>
  showManagerResources ? 'RESOURCES FOR INDIVIDUALS' : 'RESOURCES FOR MANAGERS';

const ResourcesInner = ({ props: propsInner }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOnlyLiked, setShowOnlyLiked] = useState(false);
  // sessionStorage only stores strings
  const userIsManager = sessionStorage.getItem('isManager') === 'true';
  const [showManagerResources, setShowManagerResources] = useState(
    userIsManager
  );

  useEffect(() => {
    if (propsInner === null) return;
    // allow us to mutate the properties of the nodes
    const newArr = propsInner.resources.edges.slice().map(edge => ({
      node: {
        ...edge.node
      }
    }));
    setItems(newArr);
  }, [propsInner]);

  useEffect(() => {
    if (propsInner === null) return;
    const onLikeToggle = (id, newVal) => {
      const newArr = items.slice();
      newArr.find(edge => edge.node.id === id).node.liked = newVal;
      setItems(newArr);
    };
    function sortHeading(a, b) {
      const sortable = a.node.title.toLowerCase() < b.node.title.toLowerCase();
      return sortable ? -1 : 1;
    }

    const resourceArr = items;

    let filteredResources = resourceArr.sort((a, b) => sortHeading(a, b));

    if (searchTerm !== '') {
      filteredResources = filteredResources.filter(
        data =>
          data.node.title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      );
    }

    if (showOnlyLiked) {
      filteredResources = filteredResources.filter(data => data.node.liked);
    }

    if (showManagerResources) {
      filteredResources = filteredResources.filter(
        data => data.node.pageType === 'ADMIN'
      );
    } else {
      filteredResources = filteredResources.filter(
        data => data.node.pageType === 'GENERAL'
      );
    }

    setFilteredItems(
      filteredResources.map(
        ({ node: { title, subtitle, slug, liked, id } }) => (
          <ResourceCard
            key={slug}
            title={title}
            subtitle={subtitle}
            liked={liked}
            slug={slug}
            id={id}
            onLikeToggle={newVal => onLikeToggle(id, newVal)}
          />
        )
      )
    );
  }, [items, propsInner, searchTerm, showManagerResources, showOnlyLiked]);

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const managerToggle = () => {
    setShowManagerResources(!showManagerResources);
    window.scrollTo(0, 0);
  };

  if (propsInner === null) return null;
  return (
    <Container>
      <div className="boxitem-content no-top">
        <div className="input-group input-group-lg mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="search">
              <i className={'ion-md-search'} />
            </span>
          </div>
          <input
            type="text"
            name="search"
            className="form-control border-right-0"
            placeholder="Search for...."
            aria-label="Search resources"
            aria-describedby="search"
            onChange={handleChange}
          />

          <div className="input-group-prepend">
            <span
              className="input-group-text rounded-right"
              title={`Click to toggle only showing your favourites.`}
              onClick={() => setShowOnlyLiked(!showOnlyLiked)}
            >
              <i className={`${showOnlyLiked ? likedIcon : likeableIcon}`} />
            </span>
          </div>
        </div>

        {filteredItems.length > 0 ? (
          <Flipper flipKey={filteredItems}>
            <Row>{filteredItems}</Row>
          </Flipper>
        ) : (
          <Row>
            <Col>
              <Alert color="info">
                Sorry, we couldn't find anything for this search.
              </Alert>
            </Col>
          </Row>
        )}
        {userIsManager && (
          <div className="text-center">
            <Button
              size="lg"
              data-testid="submitButton"
              className="btn btn-primary mt-4 mb-4"
              onClick={managerToggle}
            >
              {buttonText(showManagerResources)}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

ResourcesInner.propTypes = {
  props: PropTypes.shape({
    resources: PropTypes.object
  })
};

const query = graphql`
  query ResourcesQuery($pageType: String) {
    resources(pageType: $pageType) {
      edges {
        node {
          slug
          title
          subtitle
          liked
          id
          pageType
        }
      }
    }
  }
`;

const Resources = () => (
  <QueryRenderer
    environment={environment}
    query={query}
    variables={{
      pageType:
        sessionStorage.getItem('isManager') === 'true' ? undefined : 'GENERAL'
    }}
    render={props => <ResourcesInner {...props} />}
  />
);

export default Resources;
