import React, { useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { fetchQuery } from 'relay-runtime';
import environment from '../relayEnvironment';

const query = graphql`
  query DataDownloadButtonQuery($org: Int!) {
    dataDumpDownload(org: $org) {
      encodedData
      orgName
      timestamp
    }
  }
`;

const DataDownloadButton = ({ organisation }) => {
  const [loading, setLoading] = useState(false);
  const [b64Data, setB64Data] = useState(null);
  const [filename, setFilename] = useState(null);
  const hiddenButton = useRef();
  const onClick = () => {
    setLoading(true);
    fetchQuery(environment, query, { org: organisation })
      .then(result => {
        setB64Data(
          `data:application/zip;base64,${result.dataDumpDownload.encodedData}`
        );
        setFilename(`${result.dataDumpDownload.orgName} data.zip`);
        hiddenButton.current.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <button className="btn btn-primary btn-lg" onClick={onClick}>
        {loading && <i className="spinner-border mr-3" />}
        Data Download
      </button>
      <a
        href={b64Data}
        download={filename}
        style={{ display: 'none' }}
        ref={hiddenButton}
      >
        content to prevent the linter complaining that's never actually shown to
        users
      </a>
    </>
  );
};

DataDownloadButton.propTypes = {
  organisation: PropTypes.number
};

export default DataDownloadButton;
