import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../relayEnvironment';
import { commitMutation } from 'react-relay';
import AssessmentForm from '../components/AssessmentForm';
import psychologicalClimate from '../surveys/psychologicalClimate';
import { nextPage } from '../__utils__/nextPage';

const mutation = graphql`
  mutation PsychologicalClimateMutation($input: PrePostMutationInput!) {
    prepostMutation(input: $input) {
      nextForm
    }
  }
`;

const PsychologicalClimate = () => {
  const [formResponse, setFormResponse] = useState();

  const onSubmit = (values, { setSubmitting }) => {
    const responses = Object.entries(values).map(([key, value]) => ({
      question: key,
      answer: value
    }));

    const variables = {
      input: { formName: 'PsychologicalClimateForm', responses: responses }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: response => {
        setSubmitting(false);
        sessionStorage.setItem('nextForm', response.prepostMutation.nextForm);
        nextPage();
      },
      onError: ({ error }) => {
        setFormResponse({ error });
      }
    });
  };

  return (
    <AssessmentForm
      onSubmit={onSubmit}
      formResponse={formResponse}
      heading={psychologicalClimate.heading}
      instruction={psychologicalClimate.instruction}
      questions={psychologicalClimate.questions}
    />
  );
};

export default PsychologicalClimate;
