import React from 'react';
import { Container, Row } from 'reactstrap';
import Eap from '../components/Eap';
import ExternalLink from '../components/ExternalLink';

const UrgentSupport = () => {
  window.scrollTo(0, 0);
  return (
    <section>
      <Container>
        <Row>
          <div className="boxitem-container no-top">
            <div className="boxitem-content no-top">
              Workplace Wellbeing Assist does not provide mental health
              treatment or support.
            </div>
            <div className="boxitem-title blue">
              <h2>For urgent support</h2>
            </div>
            <div className="boxitem-content">
              <p>Lifeline 13 11 14</p>
              <p>Beyond Blue 1300 224 636</p>
              <p>
                If someone's life is in danger, dial 000 to contact emergency
                services.
              </p>
            </div>

            <div className="boxitem-title blue">
              <h2>For mental health support</h2>
            </div>
            <div className="boxitem-content">
              <p>A General Practitioner at your local medical centre</p>
              <p>
                <ExternalLink href="https://headtohealth.gov.au">
                  HeadtoHealth
                </ExternalLink>
                : Australian Government website providing mental health
                information and online program.
              </p>
              <p>
                <ExternalLink href="https://www.psychology.org.au/Find-a-Psychologist">
                  Find a Psychologist
                </ExternalLink>
                : the Australian Psychological Society's service to assist you
                to find a psychologist in your area
              </p>
              <Eap organisation={process.env.REACT_APP_ORGANIZATION_NAME} />
            </div>
            <div className="boxitem-title blue">
              <h2>General enquiries</h2>
            </div>
            <div className="boxitem-content">
              <p>
                For general enquiries about Workplace Wellbeing Assist or
                technical support, please use our{' '}
                <a href="/help#contact">contact form</a> or email:{' '}
                <a href="mailto:beri@federation.edu.au">
                  beri@federation.edu.au
                </a>
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default UrgentSupport;
