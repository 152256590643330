import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const FooterLogo = ({ src, extraClass, alt, testid }) => (
  <Col xs="3">
    <img
      data-testid={testid}
      className={`footer-logo ${extraClass}`}
      src={src}
      alt={alt}
    />
  </Col>
);

FooterLogo.defaultProps = {
  extraClass: ''
};

FooterLogo.propTypes = {
  src: PropTypes.string.isRequired,
  testid: PropTypes.string,
  extraClass: PropTypes.string,
  alt: PropTypes.string
};

export default FooterLogo;
