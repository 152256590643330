import React from 'react';
import { PropTypes } from 'prop-types';
import { LineChart } from 'react-chartkick';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import 'chart.js';

const options = {
  showLines: true,
  steppedLine: true,
  animation: { duration: 500, easing: 'easeOutQuad' },
  legend: false,
  showTooltips: false,
  tooltips: { enabled: false },
  hover: { mode: null },
  scales: {
    yAxes: [
      {
        ticks: { min: 1, max: 120, stepSize: 20, display: false },
        scaleLabel: {
          display: true,
          labelString: '  LOW     MEDIUM     HIGH  '
        },
        display: true
      }
    ],

    xAxes: [{ ticks: { beginAtZero: true, min: 0, max: 12 } }]
  }
};

const getData = ({ dashboardOverview: { edges } }, topic) => [
  {
    name: 'Timeline',
    data: [
      [1, -1],
      [2, -12],
      [3, -12],
      [4, -12],
      [5, -12],
      [6, -12],
      [7, -12],
      [8, -12],
      [9, -12],
      [10, -12],
      [11, -12],
      [12, -12],
      [13, -12]
    ],
    library: { pointRadius: 10, pointHoverRadius: 20, borderWidth: 3 }
  },
  {
    name: topic,
    data: edges
      .filter(edge => edge.node.topic === topic)
      .reduce(
        (object, { node }) => ({
          [node.fortnight]: node.score,
          ...object
        }),
        {}
      ),
    library: {
      pointRadius: 9,
      pointHoverRadius: 12,
      pointBorderWidth: 0,
      borderWidth: 5,
      lineTension: 0,
      borderColor: '#222',
      pointBorderColor: 'rgba(0,0,0,0)'
    }
  }
];

const DashboardLineChart = ({ data, topic }) => (
  <div>
    <LineChart
      height="230px"
      data={getData(data, topic)}
      library={options}
      colors={['#2345a2', '#ffc215']}
    />
  </div>
);

DashboardLineChart.propTypes = {
  data: PropTypes.object,
  topic: PropTypes.string
};

export default createFragmentContainer(withRouter(DashboardLineChart), {
  data: graphql`
    fragment DashboardLineChart_data on RelayQuery {
      dashboardOverview(sitePk: $sitePk) {
        edges {
          node {
            id
            topic
            fortnight
            score
          }
        }
      }
    }
  `
});
