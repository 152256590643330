import React from 'react';
import PropTypes from 'prop-types';
import ResetPasswordForm from '../components/ResetPasswordForm';
import { Container } from 'reactstrap';

const ResetPassword = ({ match }) => (
  <Container>
    <ResetPasswordForm match={match} />
  </Container>
);

ResetPassword.propTypes = {
  match: PropTypes.object
};

export default ResetPassword;
