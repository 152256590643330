import React from 'react';
import { Button, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import AppleBadge from '../assets/images/app_store.svg';
import GoogleBadge from '../assets/images/google_play.svg';
/*
  Notes on the app-store badges
  1) Neither of these go to the correct links, as the correct links don't exist
  2) Once the ios app has been added, it will be possible to use a CDN link. This would be 
    preferable to using something from src/assets
*/
const SetPassword = () => (
  <Container>
    <div className="text-center boxitem-content no-top x-padding">
      <p>Click the button below to be redirected to the Log In page</p>
      <Link to="/login">
        <Button className="btn btn-primary">LOG IN</Button>
      </Link>
      <p>
        If you'd prefer to access the program using a mobile or tablet device,
        you can also download the Workplace Wellbeing Assist app from the Apple
        App Store, or the Google Play Store.
      </p>
      <p>
        <a href="https://play.google.com/store/apps/details?id=edu.au.federation.berihub.wellbeingassist&hl=en_AU&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            className="store-link"
            alt="Get it on Google Play"
            src={GoogleBadge}
          />
        </a>
        <a href="https://www.apple.com/ios/app-store/">
          <img
            className="store-link"
            alt="Download on the App Store"
            src={AppleBadge}
          />
        </a>
      </p>
    </div>
  </Container>
);

export default SetPassword;
