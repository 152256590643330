/**
 * @flow
 * @relayHash abe61545c4717ac6689b17330ff18e09
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ToggleResourceLikedMutationInput = {|
  resources?: ?$ReadOnlyArray<?likeable>,
  clientMutationId?: ?string,
|};
export type likeable = {|
  resource: string
|};
export type LikeIconButtonMutationVariables = {|
  input: ToggleResourceLikedMutationInput
|};
export type LikeIconButtonMutationResponse = {|
  +toggleResourceLiked: ?{|
    +removed: ?$ReadOnlyArray<?string>,
    +added: ?$ReadOnlyArray<?string>,
  |}
|};
export type LikeIconButtonMutation = {|
  variables: LikeIconButtonMutationVariables,
  response: LikeIconButtonMutationResponse,
|};
*/


/*
mutation LikeIconButtonMutation(
  $input: ToggleResourceLikedMutationInput!
) {
  toggleResourceLiked(input: $input) {
    removed
    added
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ToggleResourceLikedMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "toggleResourceLiked",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleResourceLikedMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removed",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "added",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LikeIconButtonMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LikeIconButtonMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "LikeIconButtonMutation",
    "id": null,
    "text": "mutation LikeIconButtonMutation(\n  $input: ToggleResourceLikedMutationInput!\n) {\n  toggleResourceLiked(input: $input) {\n    removed\n    added\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '27108b40bfd802ff7d78c209cf1746ab';
module.exports = node;
