import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../relayEnvironment';
import { commitMutation, QueryRenderer } from 'react-relay';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object as yup } from 'yup';
import {
  Button,
  FormGroup,
  Container,
  Spinner,
  Alert,
  FormFeedback
} from 'reactstrap';
import Smiley from '../components/form/Smiley';
import { transformAll } from '@overgear/yup-ast';
import ErrorScroll from '../components/form/ErrorScroll';
import '../assets/css/WellbeingSurvey.css';
import { nextNotificationTime } from '../__utils__/notificationDate';
import { Plugins } from '@capacitor/core';

const { LocalNotifications } = Plugins;

const mutation = graphql`
  mutation WellbeingSurveyMutation($input: RelayWellbeingSurveyInput!) {
    relayWellbeingSurvey(input: $input) {
      successMessage
      moreQuestions
    }
  }
`;

const query = graphql`
  query WellbeingSurveyQuery {
    nextPeriod
    questions {
      edges {
        node {
          id
          displayType
          name
          text
          validation
        }
      }
    }
  }
`;

// Wrapper for array.splice which returns the array itself rather than the new length of the array
// used to wrap the questions.map, allowing us to insert the 'YOUR WELLBEING' header
function insertAtPosition(array, element, position) {
  array.splice(position, 0, element);
  return array;
}

function errorDisplay(errors, submitCount) {
  if (Object.keys(errors).length === 0 || submitCount === 0) return null;
  return (
    <FormFeedback className="d-block">
      Please review your answers above, and answer all the questions.
    </FormFeedback>
  );
}

const WellbeingSurveyInner = ({ questions, nextPeriod }) => {
  const [formSubmitResponse, setFormSubmitResponse] = useState(false);
  const [schema, setSchema] = useState({});

  // These are the components available for question display
  const components = { Smiley };

  useEffect(() => {
    const parseQuestion = question => {
      const parseableString = question.replace(/'/g, '"');
      const astValidation = JSON.parse(parseableString);
      return transformAll(astValidation);
    };

    if (questions.length > 0) {
      let schemaItems = questions
        .filter(question => question.node.validation) // Only parse fields with validation
        .reduce(
          (object, question) => ({
            [question.node.name]: parseQuestion(question.node.validation),
            ...object
          }),
          {}
        );

      setSchema(yup().shape(schemaItems));
    }
  }, [questions]);

  const onSubmit = (values, { setSubmitting }) => {
    const responses = questions.map(question => ({
      questionText: question.node.text,
      questionName: question.node.name,
      answerText: values[question.node.name]
    }));
    const variables = {
      input: { responses }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: async response => {
        setSubmitting(false);
        const userReplyMessage =
          'Thank you for todays response! Please click here to continue.';
        const userReplyLink = response.relayWellbeingSurvey.moreQuestions
          ? '/follow-on-questions'
          : '/my-results';

        setFormSubmitResponse({
          color: 'success',
          msg: userReplyMessage,
          link: userReplyLink
        });

        const notificationTime = nextNotificationTime(nextPeriod);

        // end early if there's no further reporting period
        if (notificationTime === null) {
          return;
        }

        /*
        this won't trigger on a desktop because we're never asking for permission, so it will silently fail,
        but on a mobile app it will as the permission is implicit

        We also don't need to remove previous notifications as only one notification with 
        `id: 1` can exist at a time 
        TODO: Check this holds true on iOS as well as android
        */
        LocalNotifications.schedule({
          notifications: [
            {
              title: 'Workplace Wellbeing Assist',
              body: `Hi, It's been a bit since you have checked in. Do you have a moment to fill out your Workplace Wellbeing check-in?`,
              // schedule for in the greater of 3 days or the start of the next fortnight
              schedule: { at: notificationTime },
              sound: null,
              attachments: null,
              actionTypeId: '',
              extra: null,
              id: 1
            }
          ]
        });
      },
      onError: error => {
        setSubmitting(false);
        setFormSubmitResponse({
          color: 'warning',
          msg: `Something went wrong, are you logged in?`,
          link: '#'
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Container>
        <div
          className="boxitem-content no-top x-padding text-center"
          id="survey-container"
        >
          <div className="survey-section-header">
            <h3>YOUR WORKPLACE </h3>
            <h4>Over the last week...</h4>
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={(values, { setSubmitting, setStatus }, errors) =>
              onSubmit(values, { setSubmitting, setStatus }, errors)
            }
          >
            {({ isSubmitting, errors, submitCount }) => (
              <Form>
                {/* Add the header in the middle */}
                {insertAtPosition(
                  questions.map(question => (
                    <ErrorScroll
                      {...{
                        errors,
                        isSubmitting,
                        questionName: question.node.name
                      }}
                      key={question.node.name}
                    >
                      <FormGroup className="questionWrapper white">
                        <h3>
                          <span className="questionText">
                            {question.node.text}
                          </span>
                        </h3>
                        {React.createElement(
                          components[question.node.displayType],
                          {
                            questionName: question.node.name,
                            errors,
                            submitCount
                          }
                        )}
                      </FormGroup>
                    </ErrorScroll>
                  )),
                  <div key="OverTheWeek" className="survey-section-header rule">
                    <h3>YOUR WELLBEING </h3>
                    <h4>Over the last week...</h4>
                  </div>,
                  6
                )}
                <div className="text-center">
                  {errorDisplay(errors, submitCount)}
                  <Button
                    size="lg"
                    type="submit"
                    data-testid="submitButton"
                    className="btn btn-primary mt-4 mb-4"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'SUBMITTING SURVEY' : 'SUBMIT SURVEY'}
                    {isSubmitting && (
                      <Spinner size="lg" className={'ml-3'} color="success" />
                    )}
                  </Button>
                  {formSubmitResponse && formSubmitResponse.msg && (
                    <Link to={formSubmitResponse.link}>
                      <Alert
                        className={'mt-3'}
                        color={formSubmitResponse.color}
                      >
                        {formSubmitResponse.msg}
                      </Alert>
                    </Link>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
};

WellbeingSurveyInner.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  nextPeriod: PropTypes.string
};

export const WellbeingSurvey = () => (
  <QueryRenderer
    environment={environment}
    query={query}
    render={({ props }) => {
      if (!props) {
        return (
          <div
            style={{
              minHeight: '100vh', // It looks pretty bad when the footer pops out during loading
              color: 'grey',
              textAlign: 'center',
              padding: '1em'
            }}
          >
            Please wait, loading your questions...{' '}
          </div>
        );
      } else {
        return (
          <WellbeingSurveyInner
            questions={props.questions.edges}
            nextPeriod={props.nextPeriod}
          />
        );
      }
    }}
  />
);

WellbeingSurvey.propTypes = {
  // prop-types rule thinks _any_ variable with name 'props' is a react prop of some sort
  // even when it's not the props object passed to the component (in this case - it's a subcomponent)
  questions: PropTypes.arrayOf(PropTypes.object),
  nextPeriod: PropTypes.string
};
export default WellbeingSurvey;
