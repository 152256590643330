/**
 * @flow
 * @relayHash b5ba531d5d2d24a2f97510c8e0c3f59e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BlockHeadingColor = "BLUE" | "GRAY" | "GREEN" | "%future added value";
export type BlockIcon = "EXCLAMATION" | "FURTHER_INFO" | "GRAPH" | "LINK" | "NONE" | "QUESTION" | "QUESTION_GREEN" | "%future added value";
export type ResourcePagePageType = "ADMIN" | "GENERAL" | "%future added value";
export type ResourceViewQueryVariables = {|
  slug: string
|};
export type ResourceViewQueryResponse = {|
  +resourcePageBySlug: ?{|
    +title: string,
    +subtitle: ?string,
    +pageType: ResourcePagePageType,
    +videoUrl: ?string,
    +liked: ?boolean,
    +id: string,
    +blocks: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +heading: ?string,
          +headingColor: BlockHeadingColor,
          +icon: BlockIcon,
          +alwaysOpen: boolean,
          +paragraph: ?string,
        |}
      |}>
    |},
  |}
|};
export type ResourceViewQuery = {|
  variables: ResourceViewQueryVariables,
  response: ResourceViewQueryResponse,
|};
*/


/*
query ResourceViewQuery(
  $slug: String!
) {
  resourcePageBySlug(slug: $slug) {
    title
    subtitle
    pageType
    videoUrl
    liked
    id
    blocks {
      edges {
        node {
          id
          heading
          headingColor
          icon
          alwaysOpen
          paragraph
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resourcePageBySlug",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "ResourcePageNode",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "subtitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "pageType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "videoUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "liked",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocks",
        "storageKey": null,
        "args": null,
        "concreteType": "BlockNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "BlockNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "BlockNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "heading",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "headingColor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "icon",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "alwaysOpen",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "paragraph",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResourceViewQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ResourceViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ResourceViewQuery",
    "id": null,
    "text": "query ResourceViewQuery(\n  $slug: String!\n) {\n  resourcePageBySlug(slug: $slug) {\n    title\n    subtitle\n    pageType\n    videoUrl\n    liked\n    id\n    blocks {\n      edges {\n        node {\n          id\n          heading\n          headingColor\n          icon\n          alwaysOpen\n          paragraph\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6bb8abd77fee838b0bd9bdcf74c1493';
module.exports = node;
