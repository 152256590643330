/**
 * @flow
 * @relayHash 685e28dc4c2c77badf6c36953fc85a7b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DashboardLineChart_data$ref = any;
type SiteSelector_list$ref = any;
export type DashboardQueryVariables = {|
  lookback: number,
  sitePk?: ?number,
|};
export type DashboardQueryResponse = {|
  +dashboardWellbeing: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +label: ?string,
        +status: ?string,
        +mean: ?number,
        +responses: ?number,
      |}
    |}>
  |},
  +nextPeriod: ?any,
  +$fragmentRefs: DashboardLineChart_data$ref & SiteSelector_list$ref,
|};
export type DashboardQuery = {|
  variables: DashboardQueryVariables,
  response: DashboardQueryResponse,
|};
*/


/*
query DashboardQuery(
  $lookback: Int!
  $sitePk: Int
) {
  ...DashboardLineChart_data
  ...SiteSelector_list
  dashboardWellbeing(lookback: $lookback, sitePk: $sitePk) {
    edges {
      node {
        id
        label
        status
        mean
        responses
      }
    }
  }
  nextPeriod
}

fragment DashboardLineChart_data on RelayQuery {
  dashboardOverview(sitePk: $sitePk) {
    edges {
      node {
        id
        topic
        fortnight
        score
      }
    }
  }
}

fragment SiteSelector_list on RelayQuery {
  sites {
    pk
    siteName
    newData
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "lookback",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sitePk",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "sitePk",
  "variableName": "sitePk"
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dashboardWellbeing",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "lookback",
      "variableName": "lookback"
    },
    (v1/*: any*/)
  ],
  "concreteType": "WellbeingScoreConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "WellbeingScoreEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "WellbeingScore",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "label",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "mean",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "responses",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "nextPeriod",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "kind": "FragmentSpread",
        "name": "DashboardLineChart_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "SiteSelector_list",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dashboardOverview",
        "storageKey": null,
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "DashGraphPointConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DashGraphPointEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DashGraphPoint",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "topic",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fortnight",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "score",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sites",
        "storageKey": null,
        "args": null,
        "concreteType": "SiteDetails",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pk",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "siteName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "newData",
            "args": null,
            "storageKey": null
          }
        ]
      },
      (v3/*: any*/),
      (v4/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardQuery",
    "id": null,
    "text": "query DashboardQuery(\n  $lookback: Int!\n  $sitePk: Int\n) {\n  ...DashboardLineChart_data\n  ...SiteSelector_list\n  dashboardWellbeing(lookback: $lookback, sitePk: $sitePk) {\n    edges {\n      node {\n        id\n        label\n        status\n        mean\n        responses\n      }\n    }\n  }\n  nextPeriod\n}\n\nfragment DashboardLineChart_data on RelayQuery {\n  dashboardOverview(sitePk: $sitePk) {\n    edges {\n      node {\n        id\n        topic\n        fortnight\n        score\n      }\n    }\n  }\n}\n\nfragment SiteSelector_list on RelayQuery {\n  sites {\n    pk\n    siteName\n    newData\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4883171c73665e672d42a3671c84229';
module.exports = node;
