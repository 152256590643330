import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

const style = {
  paddingLeft: '0px'
};

const ReacstrapSelect = ({
  field,
  form: { setFieldValue, values, errors, submitCount },
  disabled = false,
  choices,
  ...props
}) => (
  <FormGroup style={style}>
    <Label for={props.id}>
      <Input
        {...props}
        type="select"
        name={field.name}
        checked={values[field.name] === field.value}
        value={field.value}
        onChange={e => setFieldValue(field.name, e.target.value)}
      >
        {choices.map(choice => (
          <option key={choice.value} value={choice.value}>
            {choice.label}
          </option>
        ))}
      </Input>
    </Label>
  </FormGroup>
);

ReacstrapSelect.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  choices: PropTypes.array
};

export default ReacstrapSelect;
