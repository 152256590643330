const MIN_NOTIFICATION_DAYS = 3;
/**
 * nextNotificationTime
 *
 * Determines the time at which a user should be notified of something.
 *
 * @param {string|null} nextPeriod: String representation of the start of the next fortnightly period.
 *    Should be ISO 8601 formatted: 2020-01-01 00:00:00+10:00
 */
export const nextNotificationTime = nextPeriod => {
  /* calculate when to trigger the notification
        constraints: 
          - must be in the next fortnightly period
          - must be at least 3 days away

        */
  if (nextPeriod === null) {
    return null;
  }
  const fortnightStart = new Date(nextPeriod);
  fortnightStart.setHours(9, 0, 0, 0);
  const fortnightUnix = fortnightStart.getTime();

  const threeDays = new Date(
    Date.now() + MIN_NOTIFICATION_DAYS * 24 * 3600 * 1000
  );
  threeDays.setHours(9, 0, 0, 0);
  const threeDaysUnix = threeDays.getTime();

  const notificationTime = new Date(Math.max(fortnightUnix, threeDaysUnix));

  return notificationTime;
};
