import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

const PageHeader = ({ title, subtitle, children }) => {
  useEffect(() => {
    if (!title) return;
    document.title = `Wellbeing Assist - ${title}`;
  });
  if (!title) return null;
  return (
    <section className="title-section">
      <Container className="title-container">
        {children && <div className="float-right">{children}</div>}
        {subtitle && <h2>{subtitle}</h2>}
        <h1>{title}</h1>
      </Container>
    </section>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};
export default PageHeader;
