import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

const Handle = Slider.Handle; // eslint-disable-line no-unused-vars

const LikertHandle = ({ value, index, dragging, ...restProps }) => (
  <Tooltip
    key={index}
    overlay={value}
    placement="top"
    prefixCls="rc-slider-tooltip"
    visible={true}
  >
    <Handle value={value} {...restProps} />
  </Tooltip>
);

LikertHandle.propTypes = {
  value: PropTypes.string,
  index: PropTypes.string,
  dragging: PropTypes.bool
};

export default LikertHandle;
