import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';

const labelStyle = {
  // paddingLeft: '1em'
  // paddingRight: '1em'
  // paddingTop: '.5em',
  // lineHeight: '1.5em'
};

const ReactstrapCheckbox = ({
  field,
  label,
  elementKey,
  form: { values },
  arrayHelper,
  disabled = false,
  ...props
}) => (
  <div style={labelStyle} className="checkbox-item">
    <Label className="checkbox-item" for={elementKey}>
      <Input
        {...props}
        type="checkbox"
        id={elementKey}
        name={field.name}
        checked={values[field.name] && values[field.name].includes(label)}
        value={field.value}
        onChange={e => {
          // setFieldValue(field.name, field.value);
          if (e.target.checked) {
            arrayHelper.push(label);
          } else {
            // Not working yet
            const idx = values[field.name]?.indexOf(label);
            arrayHelper.remove(idx);
          }
        }}
      />
      {label}
    </Label>
  </div>
);

ReactstrapCheckbox.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  arrayHelper: PropTypes.object,
  push: PropTypes.object,
  remove: PropTypes.object,
  elementKey: PropTypes.string
};
export default ReactstrapCheckbox;
