import React from 'react';
import ColacLogo from '../../assets/images/Colac_colour_logo.svg';

export const Colac = () => (
  <div>
    <div className="text-center">
      <img src={ColacLogo} alt="Colac Area Health Logo" />
    </div>
    <h2 className="pis-heading">Plain Language Information Statement</h2>
    <h4 className="pis-heading">DVC-R&amp;I Portfolio</h4>

    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>PROJECT TITLE:</td>
          <td>
            Wellbeing Track &amp; Change: Using digital monitoring to improve
            the mental wellbeing of residential aged care workers.
          </td>
        </tr>
        <tr>
          <td>
            PRINCIPAL RESEAR<span className="d-xs-inline d-sm-none">-</span>
            CHER:
          </td>
          <td>Professor Britt Klein</td>
        </tr>
        <tr>
          <td>
            OTHER/ STUDENT RESEAR
            <span className="d-xs-inline d-sm-none">-</span>
            CHERS:
          </td>
          <td>
            Dr Steven Carbone (Prevention United); Associate Professor Lisa
            Clinnick (Ballarat Health Services); Dr Luke Martin (Prevention
            United); Dr Lachlan Kent (Prevention United); Mr Asher Leslie
            (Federation University); Dr Wendi Kruger (Federation University); Dr
            Kerrie Shandley (Federation University); Mr Brett James (Federation
            University); Mr Duncan Macneil (Federation University).
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      Thank you for your interest in this evaluation. We invite direct care
      workers (RNs, ENs, PCAs &amp; RSAs) who are aged over 18 years, and
      permanently (full-time or part-time) employed by a Residential Aged Care
      (RAC) facility to participate in this evaluation.
    </p>

    <h4 className="pis-heading">What is the purpose of this research?</h4>

    <p>
      This project is a collaboration between Federation University Australia,
      Prevention United, and Ballarat Health Services and is supported by the
      WorkSafe WorkWell Mental Health Improvement Fund.
    </p>

    <p>
      The purpose of the research is to determine whether a digital tool, known
      as Workplace Wellbeing Assist, can: 1) support the mental wellbeing of RAC
      direct-care staff; and 2) and improve the workplace culture of RAC
      facilities.
    </p>

    <h4 className="pis-heading">What does this project involve?</h4>

    <p>
      This project involves collecting various pieces of information from you
      and about your usage of Workplace Wellbeing Assist. It also involves you
      logging into Workplace Wellbeing Assist on a regular basis across a
      24-week evaluation period.
    </p>
    <p>
      After reading though this Plain Language Information Statement, if you
      would like to participate, click the ‘
      <i>I agree to the above conditions</i>’ below. Once you agree to
      participate, you will be asked to do the following:
    </p>

    <ul>
      <li>
        Firstly, you will be asked to complete a pre-intervention questionnaire
        through Workplace Wellbeing Assist. The questionnaire will ask you
        questions about yourself, including demographic details (e.g., age,
        gender, how long you have worked in this sector), perceptions about your
        workplace culture, and your mental wellbeing. This questionnaire will
        take approximately 20-30 minutes to complete.
        <br />
        <b>Please note:</b>
        <i>
          Please note: You will have until the 31st August, 2020 to sign-up to
          this study. This means you must complete all of the questionnaires
          within these 2-weeks to participate in the study.
        </i>
      </li>

      <li>
        After you complete the pre-intervention questionnaire, you will
        immediately have access to Workplace Wellbeing Assist. Each time you
        login Workplace Wellbeing Assist, you will be able to complete a short
        (10-item) survey, this includes 6 workplace-related items, and 4-items
        about your personal wellbeing. Your responses to each workplace-related
        item will be combined and averaged with other participants’ responses
        from the same facility and provided to management (nurse unit managers
        or other senior management participating in the study) via Workplace
        Wellbeing Assist. Your responses to the 4 personal wellbeing items will
        also be combined and averaged with the responses from other participants
        in your facility and provided to management via the digital tool. This
        information will help management to monitor and respond to problems that
        may be occurring in the RAC facility. It will also help management to
        understand what is working well within the RAC facility. You will have
        access to a personal dashboard in Workplace Wellbeing Assist, this
        dashboard will display the combined scores for each of the workplace
        items and you will be able to monitor your personal mental wellbeing
        over the evaluation. The 10-item survey will take approximately
        2-minutes to complete. We recommend that you complete it on a weekly
        basis, however you are free to do so more or less often.
      </li>

      <li>
        Based on your personal wellbeing score, three resources will be
        recommended to you. Each resource will be accessible through Workplace
        Wellbeing Assist and focuses on a topic designed to support and improve
        mental wellbeing. The combination of recommended resources will change
        each time you complete the 10-item survey. However, you will have access
        to all resources through Workplace Wellbeing Assist. Whether you choose
        to access the resources and which resources you access will have no
        impact on your use of Workplace Wellbeing Assist.
      </li>

      <li>
        After 2-weeks of using Workplace Wellbeing Assist, you will be asked to
        complete a 5-minute questionnaire about how easy you are finding the
        digital tool to use.
      </li>

      <li>
        You will be asked to complete two further sets of questionnaires during
        the evaluation, at the start of Week 12 and Week 24. As with the
        pre-intervention questionnaires, you will be asked questions about your
        perceptions of your workplace culture, and your mental wellbeing. These
        questionnaires will take approximately 20-30 minutes to complete. In
        addition, at Week 24 the questionnaires will include an intervention
        satisfaction questionnaire that will ask for your opinions of Workplace
        Wellbeing Assist.
      </li>

      <li>
        At Week 12, you will be invited to take part in an interview (over the
        phone, Skype or Zoom). This interview will focus on your experience of
        using Workplace Wellbeing Assist, in particular, how acceptable, usable,
        and useful the digital tool has been. The interview will take up to 60
        minutes and will be audio-recorded. You will be reimbursed with a $10
        Woolworths gift card for your time.
      </li>
    </ul>

    <p>
      Workplace Wellbeing Assist will also record how many times you logged in,
      the amount of time you spent on the dashboard, how many times you
      completed the 10-item survey, which resources you accessed and how often
      you accessed them, and the amount of time you spent using Workplace
      Wellbeing Assist. This information will not affect your use of the
      program.
    </p>

    <h4 className="pis-heading">Can I withdraw from the project?</h4>

    <p>
      Participation in this project is completely voluntary. If you decide you
      do not want to continue with the project, you can withdraw at any stage,
      without having to provide any explanation. Your withdrawal will have no
      impact on your employment, or your current or future relationships with
      Colac Area Health, Federation University Australia, Prevention United, or
      Ballarat Health Services.
    </p>

    <h4 className="pis-heading">What will happen to my results?</h4>

    <p>
      All information will be kept strictly confidential, yet subject to legal
      limitations (e.g., legal subpoena) and only be accessible by the
      investigators listed at the start of this Plain Language Information
      Statement. We employ professional and industry standards for the security
      and protection of all information collected. When we analyse the data, any
      identifying information is completely removed and therefore cannot be
      linked back to you in any way. Should you choose to withdraw from the
      project, no further information will be collected from you, however, your
      existing data will be combined and analysed with the other participant
      data.
    </p>
    <p>
      Your de-identified data may be used for research project into the future
      such as being re-analysed or added to other residential aged care or
      digital health data to answer other research questions. Any other person,
      apart from current researchers, gaining access to the data, will only be
      supplied with non-identified data for a legitimate research purpose (e.g.,
      data use for a further thesis). Data will be stored securely for a minimum
      of five years post any publications. After this time the data will be
      destroyed safely. As the all data are analysed with any identifiable
      information, and as publications will report findings based on group data,
      individual participants will not be identifiable in any way.
    </p>
    <h4 className="pis-heading">
      What are the possible benefits of taking part?
    </h4>

    <p>
      Workplace Wellbeing Assist is a digital tool designed to support the
      mental wellbeing of individual direct-care staff and improve the workplace
      culture of RAC facilities. Workplace Wellbeing Assist will provide you
      will a way to confidentially monitor your personal mental wellbeing, and
      access to resources to provide you with knowledge and skills around
      improving mental wellbeing. In addition, Workplace Wellbeing Assist will
      provide you with an opportunity to give anonymous feedback to management
      about your perceptions of the workplace culture of the RAC facility.
    </p>
    <h4 className="pis-heading">Are there any risks from participating?</h4>

    <p>
      We do not anticipate there will be any serious risks or disadvantages to
      you from participating in this evaluation of Workplace Wellbeing Assist.
      However, we recognise that you may find some of the questions asked during
      the questionnaires, 10-item survey, or interviews upsetting. However, you
      will not be asked to disclose any personal health issues. Also, please
      note that you may opt not to do the 10-item survey, Week 12 questionnaire,
      Week 24 questionnaire, or mid-trial interview. You may choose to answer or
      not answer any question if you so choose.
    </p>
    <p>
      It is possible that this research study may be discontinued for a variety
      of reasons. For example, the evaluation trial may be discontinued due to
      limited direct-care staff involvement. In the event that this were to
      occur, an explanatory email will be sent to any existing staff members who
      have signed up for the evaluation trial and any data already provided will
      be destroyed.
    </p>

    <p>
      Should you find any questions of issues raised during or following the
      evaluation, please access one or more of the following options:
    </p>

    <table className="table table-bordered">
      <tbody>
        <tr>
          <td colSpan="2" className="text-center">
            <b>Options for accessing support in the event of distress</b>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            Make an appointment directly with your GP, or other trusted health
            professional.
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            Make an appointment directly with the Employee Assistance Program
            (“EAP”). EAP is provided as a free and confidential service as part
            of your employment. Staff members are entitled to 3 paid sessions
            per year. Please see the Fact Sheet on EAP provided by Colac Area
            Health for further information. The details for your EAP provider
            are as follows:
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            The EAP contact details for Colac Area Health Staff are:
          </td>
        </tr>
        <tr>
          <td>
            Mel Oborne – 1300 847 849 <br />
            Geelong <br />
            Clinical Social Worker
          </td>
          <td>
            Richard Bennett – 0407 304 845 <br />
            Jan Juc <br />
            Psychologist
          </td>
        </tr>
        <tr>
          <td>
            Julie Cochrane – 0490 953 231 <br />
            Colac <br />
            Psychologist
          </td>
          <td>
            Alan Woodward – 0409 167 101 <br />
            Camperdown <br />
            Psychologist
          </td>
        </tr>
        <tr>
          <td>
            Kim Parry – 0438 287 746 <br />
            Colac <br />
            Psychologist
          </td>
          <td />
        </tr>
        <tr>
          <td colSpan="2">
            Contact Lifeline or Beyond Blue crisis support lines, details as
            follows:
            <ul>
              <li>
                Lifeline ‘Chat online’ (
                <a href="https://www.lifeline.org.au/about-lifeline/contact-us">
                  https://www.lifeline.org.au
                  <span className="d-xs-inline d-sm-none">
                    <br />
                  </span>
                  /about-lifeline/contact-us
                </a>
                ) or call 131114.
              </li>

              <li>
                Beyond Blue ‘Chat online’ (
                <a href="https://www.beyondblue.org.au">
                  https://www.
                  <span className="d-xs-inline d-sm-none">
                    <br />
                  </span>
                  beyondblue.org.au
                </a>
                ) or call 1300 22 4636.
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <h4 className="pis-heading">How do I participate?</h4>
    <p>
      Having read this Plain Language Information Statement and decided that you
      would like to participate in this project, please click the '
      <i>I agree to the above conditions</i>' button below on your screen. Once
      you consent, the project will commence as outlined above. However, if you
      have further questions about the project, please email the project team
      beri@federation.edu.au and we will respond to your questions within two
      business days. Should you not wish to participate in this project, please
      just exit.
    </p>

    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>
            If you have any questions, or you would like further information
            regarding the project titled Wellbeing Track &amp; Change: Using
            digital monitoring to improve the mental wellbeing of residential
            aged care workers, please contact the Principal Researcher,
            Professor Britt Klein of the DVC R&amp;I Portfolio. <br />
            <b>EMAIL:</b> beri@federation.edu.au or b.klein@federation.edu.au
            <br />
            <b>PH:</b>
            03 5327 6716
          </td>
        </tr>
        <tr>
          <td className="text-center small">
            Should you (i.e. the participant) have any concerns about the
            ethical conduct of this research project, please contact the
            Federation University Coordinator Research Ethics, Research
            Services, Federation University Australia, <br />P O Box 663 Mt
            Helen Vic 3353 <br />
            Telephone: (03) 5327 9765 <br />
            Email: research.ethics@federation.edu.au <br />
            <br />
            CRICOS Provider Number 00103D
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
