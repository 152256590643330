import { graphql } from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import environment from './relayEnvironment';
import ability from './PublicAbility';

const mutation = graphql`
  mutation UpdateAbilityMutation {
    caslAbilities(input: {}) {
      abilities
    }
  }
`;

const variables = {};

const UpdateAbility = () => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: response => {
      ability.update(
        JSON.parse(response.caslAbilities.abilities.replace(/'/g, '"'))
      );
    },
    onError: error => {
      if (error.toString().includes('TypeError: Failed to fetch')) {
        ability.update([]);
      }
    }
  });
};

export default UpdateAbility;
