/**
 * @flow
 * @relayHash 03b1c7a97f8e5dec497a907b45e9acf0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RelayWellbeingSurveyInput = {|
  responses?: ?$ReadOnlyArray<?UserResponse>,
  clientMutationId?: ?string,
|};
export type UserResponse = {|
  questionText: string,
  answerText: string,
  questionName: string,
|};
export type WellbeingSurveyMutationVariables = {|
  input: RelayWellbeingSurveyInput
|};
export type WellbeingSurveyMutationResponse = {|
  +relayWellbeingSurvey: ?{|
    +successMessage: ?boolean,
    +moreQuestions: ?boolean,
  |}
|};
export type WellbeingSurveyMutation = {|
  variables: WellbeingSurveyMutationVariables,
  response: WellbeingSurveyMutationResponse,
|};
*/


/*
mutation WellbeingSurveyMutation(
  $input: RelayWellbeingSurveyInput!
) {
  relayWellbeingSurvey(input: $input) {
    successMessage
    moreQuestions
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RelayWellbeingSurveyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "relayWellbeingSurvey",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RelayWellbeingSurveyPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "successMessage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "moreQuestions",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WellbeingSurveyMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "WellbeingSurveyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "WellbeingSurveyMutation",
    "id": null,
    "text": "mutation WellbeingSurveyMutation(\n  $input: RelayWellbeingSurveyInput!\n) {\n  relayWellbeingSurvey(input: $input) {\n    successMessage\n    moreQuestions\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc3a0dbb292b64c8d8d9202275a4043c';
module.exports = node;
