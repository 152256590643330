/**
 * @flow
 * @relayHash e00c9ab537262aebb46d7e77c95a17b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAbilityMutationVariables = {||};
export type UpdateAbilityMutationResponse = {|
  +caslAbilities: ?{|
    +abilities: ?string
  |}
|};
export type UpdateAbilityMutation = {|
  variables: UpdateAbilityMutationVariables,
  response: UpdateAbilityMutationResponse,
|};
*/


/*
mutation UpdateAbilityMutation {
  caslAbilities(input: {}) {
    abilities
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "caslAbilities",
    "storageKey": "caslAbilities(input:{})",
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "PermissionsMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "abilities",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateAbilityMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateAbilityMutation",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateAbilityMutation",
    "id": null,
    "text": "mutation UpdateAbilityMutation {\n  caslAbilities(input: {}) {\n    abilities\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9868d1ca449694bfbcccb545284afc88';
module.exports = node;
