import React, { useState } from 'react';
import { Form } from 'reactstrap';
import environment from '../../relayEnvironment';
import graphql from 'babel-plugin-relay/macro';
import { PropTypes } from 'prop-types';
import { QueryRenderer, commitMutation } from 'react-relay';

const query = graphql`
  query AddUserQuery {
    sites {
      siteName
      pk
    }
  }
`;

const mutation = graphql`
  mutation AddUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      created
    }
  }
`;

const AddUser = () => {
  const submitHandler = function(email, site) {
    setError(false);
    setSuccessMsg(false);
    const variables = {
      input: { email: email, site: parseInt(site), isManager: false, sendEmail }
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: msg => {
        setSuccessMsg(msg.createUser.created);
      },
      onError: msg => {
        setError('An unknown error occured.');
        if (msg.toString().includes('Invalid email address')) {
          setError(
            'Invalid email address. You must use an address that ' +
              'ends with the correct domain, and one that has not already been added.'
          );
        }
      }
    });
  };

  const [participantEmail, setParticpantEmail] = useState('');
  const [participantSite, setParticpantSite] = useState('');
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  return (
    <div>
      <h3>Add a participant</h3>
      <Form
        role="form"
        onSubmit={evt => {
          submitHandler(participantEmail, participantSite);
          evt.preventDefault();
        }}
      >
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            required="required"
            className="form-control"
            value={participantEmail}
            onChange={e => {
              setParticpantEmail(e.target.value);
            }}
            id="exampleInputEmail1"
            placeholder="Enter email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Site</label>
          <select
            data-testid="selectSite"
            required="required"
            className="form-control"
            value={participantSite}
            onChange={e => {
              setParticpantSite(e.target.value);
            }}
          >
            <option value="">Please select</option>
            <QueryRenderer
              environment={environment}
              query={query}
              render={({ props }) =>
                props
                  ? props.sites.map(site => (
                      <option key={site.pk} value={site.pk}>
                        {site.siteName}
                      </option>
                    ))
                  : null
              }
            />
          </select>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="sendEmailCheckbox"
            value={sendEmail}
            onChange={e => setSendEmail(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="sendEmailCheckbox">
            Send activation email
          </label>
        </div>
        {error ? (
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
        ) : null}
        {successMsg ? (
          <div className="alert alert-success mt-3" role="alert">
            {successMsg} was added to the site.
          </div>
        ) : null}
        <div>
          <button
            type="submit"
            className="btn btn-primary mt-3"
            data-testid="addButton"
          >
            Add
          </button>
        </div>
      </Form>
    </div>
  );
};

AddUser.propTypes = {
  sites: PropTypes.object
};

export default AddUser;
