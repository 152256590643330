/**
 * @flow
 * @relayHash d197d6cf47feee7dbf90a68385793e7e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LikeIconButtonQueryVariables = {|
  slug: string
|};
export type LikeIconButtonQueryResponse = {|
  +resourcePageBySlug: ?{|
    +liked: ?boolean,
    +slug: string,
    +id: string,
  |}
|};
export type LikeIconButtonQuery = {|
  variables: LikeIconButtonQueryVariables,
  response: LikeIconButtonQueryResponse,
|};
*/


/*
query LikeIconButtonQuery(
  $slug: String!
) {
  resourcePageBySlug(slug: $slug) {
    liked
    slug
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resourcePageBySlug",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "ResourcePageNode",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "liked",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "slug",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LikeIconButtonQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LikeIconButtonQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "LikeIconButtonQuery",
    "id": null,
    "text": "query LikeIconButtonQuery(\n  $slug: String!\n) {\n  resourcePageBySlug(slug: $slug) {\n    liked\n    slug\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '590eeb43cf0ca400da2e125197d94bc5';
module.exports = node;
