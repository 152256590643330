import { Likert } from '../components/form/Likert';
import { string } from 'yup';

const marks = {
  1: 'None of the time',
  2: 'A little of the time',
  3: 'Some of the time',
  4: 'Most of the time',
  5: 'All of the time'
};

const k10LikertOptions = {
  validation: string().required('Please fill in the question above'),
  Component: Likert,
  marks: marks,
  max: 5,
  tooltip: false
};

const k10 = {
  heading: 'K-10',
  instruction: 'In the past 4 weeks...',
  questions: [
    {
      questionText:
        'About how often did you feel tired out for no good reason?',
      questionName: 'k10_1',
      ...k10LikertOptions
    },
    {
      questionText: 'About how often did you feel nervous?',
      questionName: 'k10_2',
      ...k10LikertOptions
    },
    {
      questionText:
        'About how often did you feel so nervous that nothing could calm you down?',
      questionName: 'k10_3',
      ...k10LikertOptions
    },
    {
      questionText: 'About how often did you feel hopeless?',
      questionName: 'k10_4',
      ...k10LikertOptions
    },
    {
      questionText: 'About how often did you feel restless or fidgety?',
      questionName: 'k10_5',
      ...k10LikertOptions
    },
    {
      questionText:
        'About how often did you feel so restless you could not sit still?',
      questionName: 'k10_6',
      ...k10LikertOptions
    },
    {
      questionText: 'About how often did you feel depressed?',
      questionName: 'k10_7',
      ...k10LikertOptions
    },
    {
      questionText:
        'About how often did you feel that everything was an effort?',
      questionName: 'k10_8',
      ...k10LikertOptions
    },
    {
      questionText:
        'About how often did you feel so sad that nothing could cheer you up?',
      questionName: 'k10_9',
      ...k10LikertOptions
    },
    {
      questionText: 'About how often did you feel worthless?',
      questionName: 'k10_10',
      ...k10LikertOptions
    }
  ]
};

export default k10;
