import { Likert } from '../components/form/Likert';
import { string } from 'yup';

const marks = {
  1: 'Always',
  2: 'Often',
  3: 'Sometimes',
  4: 'Seldom',
  5: 'Never/hardly ever'
};

const copenhagenPsychosocialPt2LikertOptions = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks,
  max: 5,
  tooltip: false
};

const copenhagenPsychosocialPt2 = {
  heading: 'Copenhagen Psychosocial Questionnaire',
  questions: [
    {
      questionText:
        'How often is your nearest superior willing to listen to your problems at work?',
      questionName: 'copenhagen_psychosocial_pt2_1',
      ...copenhagenPsychosocialPt2LikertOptions
    },
    {
      questionText:
        'How often do you get help and support from your nearest superior?',
      questionName: 'copenhagen_psychosocial_pt2_2',
      ...copenhagenPsychosocialPt2LikertOptions
    }
  ]
};

export default copenhagenPsychosocialPt2;
