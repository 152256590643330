import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const About = () => (
  <div id="about">
    <section>
      <Container>
        <Row>
          <h2>About the intitiative</h2>
        </Row>
        <Row>
          <p className="lead">
            Workplace Wellbeing Assist is a digital tool that helps BHS know
            what’s impacting on staff wellbeing at work. It lets BHS take early
            action to create a more mentally healthy workplace.
          </p>
          <p className="lead">
            Workplace Wellbeing Assist also helps BHS staff privately monitor
            their own wellbeing and access information and support to stay well.
          </p>
        </Row>
        <Row>
          <h1>A better state of wellbeing</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            venenatis purus ut est blandit tempus. Suspendisse semper tortor
            nunc, in malesuada quam scelerisque et. Aliquam porta felis a arcu
            interdum, at molestie nisi laoreet. Nullam et ullamcorper neque.
            Maecenas elementum, urna sit amet ultrices sollicitudin, erat erat
            viverra nunc, nec malesuada leo turpis eget purus. Proin tincidunt,
            erat at efficitur suscipit, tellus urna porttitor tellus, a
            vestibulum nunc mi vitae lorem. Suspendisse sed interdum libero.
            Fusce cursus nisi justo, eu iaculis enim condimentum id. Mauris
            tempor et arcu id semper. Curabitur dignissim erat vel nunc
            fermentum laoreet.
          </p>

          <p>
            Suspendisse ex dui, varius et commodo quis, mattis in augue. Sed
            pharetra ut nisi sed tristique. Pellentesque sagittis a neque ut
            sollicitudin. Phasellus semper mauris nec metus blandit, id
            porttitor dolor congue. Proin nec ullamcorper metus. Nunc mattis
            tristique leo quis euismod. Nam eget volutpat nulla, eget
            scelerisque nunc. Proin mollis porttitor lorem, sit amet pulvinar
            neque dapibus non. Sed condimentum pellentesque lacus. Vivamus
            fringilla velit eu euismod rutrum.
          </p>
        </Row>
      </Container>
    </section>
    <section id="about2">
      <Container>
        <h3 className="text-right">Learn more</h3>
      </Container>
    </section>
    <section className="steps">
      <Container>
        <Row>
          <Col md="4" sm="6" xs="6" className="text-center">
            <img src="/steps.svg" className="img-fluid" alt="steps" />
          </Col>
          <Col md="8" sm="6" xs="6">
            <br />
            <h2>Design</h2>
            <p className="lead">Program Design with BHS staff input</p>
            <h2>Develop</h2>
            <p className="lead">
              Program development and testing with BHS staff.
            </p>
            <h2>Trial</h2>
            <p className="lead">
              Program Trial across BHS residential aged care services
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default About;
