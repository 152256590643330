import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import { object as yup, string } from 'yup';
import { Formik, Form } from 'formik';
import { Button, Alert, FormText, Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Input, PasswordInput } from '../components/FormInputs';
import environment from '../relayEnvironment';
import UpdateAbility from '../UpdateAbility';
import history from '../history';

const mutation = graphql`
  mutation LoginMutation($input: ObtainJSONWebTokenInput!) {
    tokenAuth(input: $input) {
      token
      nextForm
      isManager
      isSuperuser
    }
  }
`;

const schema = yup().shape({
  username: string()
    .email()
    .required(),
  password: string().required()
});

const Login = ({ location }) => {
  const [error, setError] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const handleSuccess = ({
    tokenAuth: { token, nextForm, isManager, isSuperuser }
  }) => {
    sessionStorage.setItem('jwt', token);
    sessionStorage.setItem('nextForm', nextForm);
    sessionStorage.setItem('isManager', isManager);
    sessionStorage.setItem('isSuperuser', isSuperuser);
    UpdateAbility();

    const next = queryString.parse(location.search).next;

    if (isSuperuser) {
      history.push('/super-admin-page');
    } else if (next) {
      history.push(next);
    } else {
      history.push(isManager ? '/dashboard' : '/survey');
    }
  };

  const submit = values => {
    //when logging in, it is import to logout first!
    window.sessionStorage.setItem('jwt', '');

    const variables = {
      input: {
        username: values.username.toLowerCase(),
        password: values.password
      }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => handleSuccess(response, errors),
      onError: error => {
        if (error.message === 'Failed to fetch') {
          setError(
            `Sorry, this site is currently down for maintenance. Error Details: ${error.message}.`
          );
        } else if (error.res && error.res.errors && error.res.errors[0]) {
          // You have a server status of 200 and the server is up, show it as is (login is wrong error)
          setError(error.res.errors[0].message);
        } else {
          setError('Technical difficulties talking to the server');
        }
        setAlertOpen(true);
      }
    });
  };

  return (
    <Container>
      <div className="boxitem-content x-padding no-top">
        <Alert
          color="danger"
          isOpen={alertOpen}
          data-testid="errorAlert"
          toggle={() => setAlertOpen(false)}
        >
          {error}
        </Alert>
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={schema}
          onSubmit={(values, actions) => submit(values, actions)}
          render={() => (
            <Form id="login-form">
              <Input
                aria-label="Email Address"
                placeholder="Email Address"
                name="username"
                id="username"
                autoComplete="off"
                type="text"
                icon="ion-ios-mail"
              />
              <PasswordInput />
              <FormText>
                <Link to="/reset-password">Forgotten password?</Link>
              </FormText>
              <br />
              <div className="text-center">
                <Button type="submit" className="btn btn-primary center">
                  LOG IN
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
      <Row>
        <Col className="mt-3 text-center">
          This project is supported by the WorkSafe WorkWell Mental Health
          Improvement Fund
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = { location: PropTypes.object };

export default Login;
