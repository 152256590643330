import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

const radioStyle = {
  width: '100%',
  maxWidth: '100%',
  paddingLeft: '0px'
};

// whether the <field>_other error should be displayed
function shouldShowOtherError(fieldNameOther, submitCount, errors) {
  return submitCount > 0 && errors && errors[fieldNameOther];
}

// display a list of radio buttons. If 'Other' is selected, it will display an additional text
// box to allow unrestricted user input
const ReactstrapRadioOther = ({
  field,
  form: { setFieldValue, values, errors, submitCount },
  disabled = false,
  ...props
}) => {
  // console.log('ReactStrapRadioOther props');
  // console.log('<FormikReactStrapRadioOther:25> field: ', field);
  // console.log('<FormikReactStrapRadioOther:26>  values, errors, submitCount: ',  values, errors, submitCount);
  // console.log('<FormikReactStrapRadioOther:27> disabled: ', disabled);
  // console.log('<FormikReactStrapRadioOther:28> props: ', props);
  const fieldNameOther = `${field.name}_other`;
  const wrappedSetFieldValue = (name, value) => {
    // if setting to 'O', remove the <>_other value
    if (value !== 'O') {
      delete values[fieldNameOther];
    }
    setFieldValue(name, value);
  };
  return (
    <FormGroup check style={radioStyle}>
      <Label for={props.id} className="radio-item">
        <Input
          {...props}
          type="radio"
          name={field.name}
          checked={values[field.name] === field.value}
          value={field.value}
          onChange={() => wrappedSetFieldValue(field.name, field.value)}
        />
        {props.label}

        {values[field.name] === 'O' && field.value === 'O' && (
          <>
            <Input
              {...props}
              type="text"
              name={fieldNameOther}
              value={values[fieldNameOther]}
              onChange={e => setFieldValue(fieldNameOther, e.target.value)}
            />
            <div>
              {shouldShowOtherError(fieldNameOther, submitCount, errors) ? (
                <FormFeedback className="d-block">
                  {errors[fieldNameOther]}
                </FormFeedback>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </Label>
    </FormGroup>
  );
};

ReactstrapRadioOther.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string
};

export default ReactstrapRadioOther;
