/**
 * @flow
 * @relayHash ab5e096c34215726d68a418a52a70f60
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RequestConsultationMutationInput = {|
  name: string,
  email: string,
  message: string,
  clientMutationId?: ?string,
|};
export type RequestConsultationMutationVariables = {|
  input: RequestConsultationMutationInput
|};
export type RequestConsultationMutationResponse = {|
  +requestConsultationMutation: ?{|
    +id: ?string
  |}
|};
export type RequestConsultationMutation = {|
  variables: RequestConsultationMutationVariables,
  response: RequestConsultationMutationResponse,
|};
*/


/*
mutation RequestConsultationMutation(
  $input: RequestConsultationMutationInput!
) {
  requestConsultationMutation(input: $input) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestConsultationMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "requestConsultationMutation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestConsultationMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RequestConsultationMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RequestConsultationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RequestConsultationMutation",
    "id": null,
    "text": "mutation RequestConsultationMutation(\n  $input: RequestConsultationMutationInput!\n) {\n  requestConsultationMutation(input: $input) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9ce799f2c17593634f7a9bbe8ac996d';
module.exports = node;
