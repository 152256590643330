import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormikReactStrapInput } from './FormikReactStrapInput';

const Input = ({ label, questionName, id, type, initialValue, ...rest }) => (
  <Field
    label={label}
    name={questionName}
    id={id}
    component={FormikReactStrapInput}
    type={type}
    initialvalue={initialValue}
    {...rest}
  />
);

Input.propTypes = {
  label: PropTypes.string,
  questionName: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  initialValue: PropTypes.string
};

export { Input };
