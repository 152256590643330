import React from 'react';
import { PropTypes } from 'prop-types';
import { BarChart } from 'react-chartkick';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import 'chart.js';

const options = {
  showLines: false,
  animation: { duration: 500, easing: 'easeOutQuad' },
  legend: false,
  showTooltips: false,
  tooltips: { enabled: false },
  hover: { mode: null },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        },
        visible: false,
        gridLines: { display: false }
      }
    ],
    xAxes: [
      {
        ticks: { beginAtZero: true, display: false },
        display: false,
        gridLines: { display: false }
      }
    ]
  }
};

const getData = ({ subfocusOverview: { edges } }, topic) => [
  {
    name: topic,
    data: edges
      .filter(edge => edge.node.topic === topic)
      .reduce(
        (object, { node }) => ({
          [node.questionText]: node.rawCount,
          ...object
        }),
        {}
      ),
    library: { pointRadius: 6, pointHoverRadius: 12, borderWidth: 0 }
  }
];

const getPercent = ({ subfocusOverview: { edges } }, topic) => [
  {
    name: topic,
    data: edges
      .filter(edge => edge.node.topic === topic)
      .reduce(
        (object, { node }) => ({
          [node.questionText]: node.percentage,
          ...object
        }),
        {}
      ),
    library: { pointRadius: 6, pointHoverRadius: 12, borderWidth: 0 }
  }
];

const SubFocusBarChart = ({ data, topic }) =>
  getPercent(data, topic)[0].data[
    Object.keys(getPercent(data, topic)[0].data)[0]
  ] ? (
    <div>
      <h5>
        {
          getPercent(data, topic)[0].data[
            Object.keys(getPercent(data, topic)[0].data)[0]
          ]
        }
        % of staff had concerns about {topic}. Here's why:
      </h5>
      <div style={{ border: '1px solid #222', padding: '20px' }}>
        <BarChart
          height="230px"
          data={getData(data, topic)}
          library={options}
          colors={['#c1e0b2', '#999']}
        />
      </div>
    </div>
  ) : (
    <div>
      <p>
        {
          getPercent(data, topic)[0].data[
            Object.keys(getPercent(data, topic)[0].data)[0]
          ]
        }
        No staff reported concerns about {topic}.
      </p>
    </div>
  );

SubFocusBarChart.propTypes = {
  data: PropTypes.object,
  topic: PropTypes.string
};

export default createFragmentContainer(withRouter(SubFocusBarChart), {
  data: graphql`
    fragment SubFocusBarChart_data on RelayQuery {
      subfocusOverview(sitePk: $sitePk) {
        edges {
          node {
            topic
            questionText
            rawCount
            percentage
          }
        }
      }
    }
  `
});
