import React, { useEffect, useState } from 'react';
import environment from '../relayEnvironment';
import { commitMutation, QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const mutation = graphql`
  mutation LikeIconButtonMutation($input: ToggleResourceLikedMutationInput!) {
    toggleResourceLiked(input: $input) {
      removed
      added
    }
  }
`;

const query = graphql`
  query LikeIconButtonQuery($slug: String!) {
    resourcePageBySlug(slug: $slug) {
      liked
      slug
      id
    }
  }
`;

// A button (rendered as an icon or button) to toggle whether a user has favourited a resource
// ESlint screams about anything named 'props', even if its passed as {props}, as not being defined
// in propTypes. So we rename it here.
const LikeIconButtonInner = ({ props: propsInner, button, onLikeToggle }) => {
  const [internalLiked, setInternalLiked] = useState(false);
  const [serverResponded, setServerResponded] = useState(false);

  useEffect(() => {
    // External like changes are treated as display updates, and do not send a state change to update on the backend.
    if (propsInner) {
      setInternalLiked(propsInner.resourcePageBySlug.liked);
    }
  }, [propsInner]);

  if (!propsInner) return null;
  const { id } = propsInner.resourcePageBySlug;

  const toggleLike = e => {
    setServerResponded(false);
    const variables = { input: { resources: [{ resource: id }] } };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: () => {
        setServerResponded('server-reply');
      }
    });
    onLikeToggle && onLikeToggle(!internalLiked);
    setInternalLiked(!internalLiked);
    // don't propagate click (useful for when we're inside a ResourceCard)
    e.preventDefault();
  };

  const likeableIcon = 'ion-md-star-outline';
  const likedIcon = 'ion-md-star text-warning active-like';
  const component = (
    <i
      className={`like-icon ${serverResponded} ${
        internalLiked ? likedIcon : likeableIcon
      }`}
      title={`Click to ${internalLiked ? 'un' : ''}like this resource`}
      onClick={e => toggleLike(e)}
    />
  );

  return button ? (
    <Button className="btn btn-primary" style={{ padding: '0px' }}>
      {component}
    </Button>
  ) : (
    component
  );
};

LikeIconButtonInner.propTypes = {
  props: PropTypes.shape({
    resourcePageBySlug: PropTypes.shape({
      id: PropTypes.string,
      liked: PropTypes.bool,
      slug: PropTypes.string
    })
  }),
  button: PropTypes.bool,
  onLikeToggle: PropTypes.func
};

export const LikeIconButton = ({ slug, button, liked, id, onLikeToggle }) => {
  if (typeof liked !== undefined && typeof id === 'string') {
    return (
      <LikeIconButtonInner
        props={{
          resourcePageBySlug: {
            liked,
            id
          }
        }}
        button={button}
        onLikeToggle={onLikeToggle}
      />
    );
  }
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{ slug: slug }}
      render={props => (
        <LikeIconButtonInner
          button={button}
          onLikeToggle={onLikeToggle}
          {...props}
        />
      )}
    />
  );
};

LikeIconButton.propTypes = {
  slug: PropTypes.string,
  button: PropTypes.bool,
  liked: PropTypes.bool,
  id: PropTypes.string,
  onLikeToggle: PropTypes.func
};
