import React, { useState } from 'react';
import { Container } from 'reactstrap';
import graphql from 'babel-plugin-relay/macro';
import { PropTypes } from 'prop-types';
import { QueryRenderer } from 'react-relay';
import environment from '../relayEnvironment';
import OrganisationSelector from '../components/OrganisationSelector';
import DataDownloadButton from '../components/DataDownloadButton';
import ActivitySummary from '../components/ActivitySummary';

const dataQuery = graphql`
  query SuperAdminQuery {
    ...OrganisationSelector_list
  }
`;
const SuperAdminPage = () => {
  const [selectedOrg, setSelectedOrg] = useState(0);
  return (
    <QueryRenderer
      environment={environment}
      query={dataQuery}
      render={({ props, error }) => (
        <section>
          {error ? (
            <Container>
              <div className="alert alert-danger mt-5" role="alert">
                {error.toString()}
              </div>
            </Container>
          ) : (
            <Container>
              <div className="boxitem-title blue">
                <h3>Data Download</h3>
              </div>
              <div className="boxitem-content">
                Select organisation to download data for.
                {props ? (
                  <div>
                    <OrganisationSelector
                      list={props}
                      onChange={setSelectedOrg}
                    />
                    <DataDownloadButton organisation={selectedOrg} />
                  </div>
                ) : null}
              </div>
              <ActivitySummary />
            </Container>
          )}
        </section>
      )}
    />
  );
};

SuperAdminPage.propTypes = {
  dataDumpDownload: PropTypes.object
};

export default SuperAdminPage;
