/**
 * @flow
 * @relayHash 22ebc81a908c1ddeddec915e9d04b3aa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ActivitySummaryList_list$ref = any;
export type ActivitySummaryQueryVariables = {|
  first?: ?number,
  user?: ?number,
  activityType_In?: ?string,
|};
export type ActivitySummaryQueryResponse = {|
  +$fragmentRefs: ActivitySummaryList_list$ref
|};
export type ActivitySummaryQuery = {|
  variables: ActivitySummaryQueryVariables,
  response: ActivitySummaryQueryResponse,
|};
*/


/*
query ActivitySummaryQuery(
  $first: Int
  $user: Float
  $activityType_In: String
) {
  ...ActivitySummaryList_list
}

fragment ActivitySummaryList_list on RelayQuery {
  activitySummary(first: $first, user: $user, activityType_In: $activityType_In) {
    edges {
      node {
        user
        activityType
        data
        activityName
        activityDesc
        dataDesc
        time
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "user",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "activityType_In",
    "type": "String",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ActivitySummaryQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ActivitySummaryList_list",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ActivitySummaryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "activitySummary",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "activityType_In",
            "variableName": "activityType_In"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "user",
            "variableName": "user"
          }
        ],
        "concreteType": "ActivitySummaryNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ActivitySummaryNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ActivitySummaryNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "user",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "activityType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "data",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "activityName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "activityDesc",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dataDesc",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "time",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ActivitySummaryQuery",
    "id": null,
    "text": "query ActivitySummaryQuery(\n  $first: Int\n  $user: Float\n  $activityType_In: String\n) {\n  ...ActivitySummaryList_list\n}\n\nfragment ActivitySummaryList_list on RelayQuery {\n  activitySummary(first: $first, user: $user, activityType_In: $activityType_In) {\n    edges {\n      node {\n        user\n        activityType\n        data\n        activityName\n        activityDesc\n        dataDesc\n        time\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2676208f38fc17719b24c6379df2cda';
module.exports = node;
