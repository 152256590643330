import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import Slider from 'rc-slider';
import LikertHandle from './LikertHandle';
import { Field } from 'formik';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

// This line is required to initialise tooltips.
const createSliderWithTooltip = Slider.createSliderWithTooltip; // eslint-disable-line no-unused-vars

const Likert = ({
  marks,
  max,
  min,
  questionName,
  submitCount,
  tooltip,
  ...props
}) => {
  const [value, setValue] = useState(null);
  const likertHandle = tooltip && value ? LikertHandle : undefined;
  return (
    <Field
      component={({ field, form: { setFieldValue, errors } }) => (
        <React.Fragment>
          <Slider
            className="likert"
            dots
            handle={likertHandle}
            invalid
            marks={marks}
            max={max}
            min={min}
            onChange={value => {
              setValue(value);
              // Formik/controlled form states require you to set an underlying field name value
              setFieldValue(field.name, value);
            }}
            step={1}
            tabIndex={props.tabIndex}
            value={value}
          />
          {submitCount > 0 && errors && errors[questionName] ? (
            <FormFeedback className="d-block">
              {errors[questionName]}
            </FormFeedback>
          ) : (
            ''
          )}
        </React.Fragment>
      )}
      name={questionName}
      value={value}
    />
  );
};

Likert.defaultProps = {
  marks: { 1: 'Strongly Disagree', 7: 'Strongly Agree' },
  min: 1,
  max: 7,
  tooltip: true
};

Likert.propTypes = {
  marks: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  questionName: PropTypes.string,
  submitCount: PropTypes.number,
  tooltip: PropTypes.bool,
  tabIndex: PropTypes.number
};

export { Likert };
