/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardLineChart_data$ref: FragmentReference;
declare export opaque type DashboardLineChart_data$fragmentType: DashboardLineChart_data$ref;
export type DashboardLineChart_data = {|
  +dashboardOverview: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +topic: string,
        +fortnight: number,
        +score: number,
      |}
    |}>
  |},
  +$refType: DashboardLineChart_data$ref,
|};
export type DashboardLineChart_data$data = DashboardLineChart_data;
export type DashboardLineChart_data$key = {
  +$data?: DashboardLineChart_data$data,
  +$fragmentRefs: DashboardLineChart_data$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DashboardLineChart_data",
  "type": "RelayQuery",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "sitePk",
      "type": "Int"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dashboardOverview",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "sitePk",
          "variableName": "sitePk"
        }
      ],
      "concreteType": "DashGraphPointConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DashGraphPointEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "DashGraphPoint",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "topic",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fortnight",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "score",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6500dca0ca70304f4debe3ff8c663604';
module.exports = node;
