/**
 * @flow
 * @relayHash 4759ba2ffe82af652ab82624909a77a4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResourcePagePageType = "ADMIN" | "GENERAL" | "%future added value";
export type ResourcesQueryVariables = {|
  pageType?: ?string
|};
export type ResourcesQueryResponse = {|
  +resources: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +slug: string,
        +title: string,
        +subtitle: ?string,
        +liked: ?boolean,
        +id: string,
        +pageType: ResourcePagePageType,
      |}
    |}>
  |}
|};
export type ResourcesQuery = {|
  variables: ResourcesQueryVariables,
  response: ResourcesQueryResponse,
|};
*/


/*
query ResourcesQuery(
  $pageType: String
) {
  resources(pageType: $pageType) {
    edges {
      node {
        slug
        title
        subtitle
        liked
        id
        pageType
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pageType",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resources",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pageType",
        "variableName": "pageType"
      }
    ],
    "concreteType": "ResourcePageNodeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ResourcePageNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ResourcePageNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subtitle",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "liked",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "pageType",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResourcesQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ResourcesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ResourcesQuery",
    "id": null,
    "text": "query ResourcesQuery(\n  $pageType: String\n) {\n  resources(pageType: $pageType) {\n    edges {\n      node {\n        slug\n        title\n        subtitle\n        liked\n        id\n        pageType\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9d5a746771f18144fb7560c867b4afe';
module.exports = node;
