import {
  RadioChoice,
  RadioChoiceOther,
  SelectInput
} from '../components/FormInputs';

import { string } from 'yup';

function generateYearRanges(lower, upper) {
  const out = [];
  for (let i = lower; i <= upper; i++) {
    out.push({ value: `${i}`, label: `${i} years` });
  }
  return out;
}

const demographics = {
  heading: 'Demographic details',
  questions: [
    {
      questionText: 'What is your age?',
      questionName: 'age',
      questionNumber: '1',
      Component: SelectInput,
      validation: string()
        .matches(/\d{2}|N/, 'Please fill in the question above.')
        .required('Please fill in the question above.'),
      choices: [
        { value: '_', label: 'Select an age' },
        ...generateYearRanges(18, 74)
      ]
    },
    {
      questionText: 'What is your gender?',
      questionName: 'gender',
      questionNumber: '2',
      Component: RadioChoiceOther,
      validation: string().required('Please fill in the question above.'),
      choices: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
        { label: 'Other', value: 'O' },
        { label: 'Prefer not to answer', value: 'N' }
      ]
    },
    {
      questionName: 'gender_other',
      Component: false,
      validation: string().when('gender', {
        is: 'O',
        then: string().required('Please fill in the question above.'),
        otherwise: string()
      })
    },
    {
      questionText: 'What is the your highest level of education completed?',
      questionName: 'education_level',
      questionNumber: '3',
      Component: RadioChoiceOther,
      validation: string().required('Please fill in the question above.'),
      choices: [
        { value: 'POSTGRAD', label: 'University Postgraduate Degree' },
        { value: 'UGRAD', label: 'University Undergraduate Degree' },
        { value: 'TAFE', label: 'TAFE' },
        { value: 'DIP', label: 'Professional Diploma' },
        { value: 'CERT', label: 'Trade Certificate' },
        { value: 'YEAR 12', label: 'Year 12' },
        { value: 'YEAR 11', label: 'Year 11' },
        { value: 'YEAR 10', label: 'Year 10' },
        { value: 'PRIMARY', label: 'Primary School' },
        { value: 'O', label: 'Other' }
      ]
    },
    {
      questionName: 'education_level_other',
      Component: false,
      validation: string().when('education_level', {
        is: 'O',
        then: string().required('Please fill in the question above.'),
        otherwise: string()
      })
    },
    {
      questionText: 'What is your role at work?',
      questionName: 'work_role',
      questionNumber: '4',
      Component: RadioChoiceOther,
      validation: string().required('Please fill in the question above.'),
      choices: [
        { value: 'NUM', label: 'Nurse Unit Manager' },
        { value: 'RN', label: 'Registered Nurse' },
        { value: 'EN', label: 'Enrolled Nurse' },
        { value: 'PCA', label: 'Personal Care Attendant' },
        { value: 'RSA', label: 'Residential Support Assistant' },
        { value: 'O', label: 'Other' }
      ]
    },
    {
      questionName: 'work_role_other',
      Component: false,
      validation: string().when('work_role', {
        is: 'O',
        then: string().required('Please fill in the question above.'),
        otherwise: string()
      })
    },
    {
      questionText:
        'How long have you worked in the Residential Aged Care Sector?',
      questionName: 'time_employed',
      questionNumber: '5',
      Component: SelectInput,
      validation: string()
        .matches(/<?>?\d{1,2}|N/, 'Please fill in the question above.')
        .required('Please fill in the question above.'),
      choices: [
        { value: '_', label: 'Select the number of years' },
        { value: '<1', label: 'Less than a year' },
        ...generateYearRanges(1, 50),
        { value: '>50', label: 'More than 50 years' }
      ]
    },
    {
      questionText:
        'Are you employed full-time or part-time in the Residential Aged Care Sector?',
      questionName: 'employment_type',
      questionNumber: '6',
      Component: RadioChoice,
      validation: string().required('Please fill in the question above.'),
      choices: [
        { value: 'FT', label: 'Full-time' },
        { value: 'PT', label: 'Part-time' }
      ]
    },
    {
      questionText: 'Is English your first language?',
      questionName: 'english_first_language',
      questionNumber: '7',
      Component: RadioChoiceOther,
      validation: string().required('Please fill in the question above.'),
      choices: [
        { value: 'Y', label: 'Yes' },
        { value: 'O', label: 'No (please specify)' }
      ]
    },
    {
      questionName: 'english_first_language_other',
      Component: false,
      validation: string().when('english_first_language', {
        is: 'O',
        then: string().required('Please fill in the question above.'),
        otherwise: string()
      })
    }
  ]
};

if (process.env.REACT_APP_ORGANIZATION_NAME === 'bhs') {
  const preferNotToAnswer = { value: 'N', label: 'Prefer not to answer' };
  demographics.questions.forEach(question => {
    if (!question.choices) return;
    if (question.choices.find(choice => choice.value === 'N')) return;
    question.choices.push(preferNotToAnswer);
  });
}
export default demographics;
