/**
 * @flow
 * @relayHash 3074cc40362c85b38bfbee8718d805ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SiteTableQueryVariables = {||};
export type SiteTableQueryResponse = {|
  +sites: ?$ReadOnlyArray<?{|
    +siteName: ?string,
    +pk: ?number,
    +newData: ?boolean,
    +users: ?number,
    +activeUsers: ?number,
    +managers: ?number,
  |}>
|};
export type SiteTableQuery = {|
  variables: SiteTableQueryVariables,
  response: SiteTableQueryResponse,
|};
*/


/*
query SiteTableQuery {
  sites {
    siteName
    pk
    newData
    users
    activeUsers
    managers
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sites",
    "storageKey": null,
    "args": null,
    "concreteType": "SiteDetails",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "siteName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "pk",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "newData",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "users",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "activeUsers",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "managers",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SiteTableQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SiteTableQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SiteTableQuery",
    "id": null,
    "text": "query SiteTableQuery {\n  sites {\n    siteName\n    pk\n    newData\n    users\n    activeUsers\n    managers\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3fb6c02bcac0a0d8c8c778052a50050f';
module.exports = node;
