import React, { useState, useEffect } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../relayEnvironment';
import { PropTypes } from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';
import { fetchQuery } from 'relay-runtime';
import ActivitySummaryList from './ActivitySummaryList';
import { QueryRenderer } from 'react-relay';

const typeQuery = graphql`
  query ActivitySummaryTypeQuery {
    activityTypes {
      value
      name
    }
  }
`;

const query = graphql`
  # $user is defined as a float, due to the fact that django_filter.NumberFilter treats everything as float
  # even though user refers to a pk which is obviously an int
  query ActivitySummaryQuery(
    $first: Int
    $user: Float
    $activityType_In: String
  ) {
    ...ActivitySummaryList_list
  }
`;

const ActivitySummary = () => {
  const [activityTypeFilter, setActivityTypeFilter] = useState([]);
  const [userFilter, setUserFilter] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  // imperatively fetch the list of available suggestions the first time you visit the page
  // better than having two separate QueryRenderer components
  useEffect(() => {
    fetchQuery(environment, typeQuery).then(result => {
      setSuggestions(
        result.activityTypes.map(e => ({ value: e.value, label: e.name }))
      );
    });
  }, []);

  const suggestionsMapping = suggestions.reduce(
    (previous, current) => ({ [current.value]: current, ...previous }),
    {}
  );

  const getUserIDOrNull = userID => {
    const userIDInt = parseInt(userID);
    if (Number.isNaN(userIDInt)) {
      return null;
    }
    return userIDInt;
  };

  return (
    <>
      <div className="boxitem-title blue">
        <h3>Activity Summary</h3>
      </div>
      <div className="boxitem-content">
        <div>
          <label>
            User{' '}
            <input
              className="form-control"
              type="number"
              value={userFilter}
              onChange={e => setUserFilter(e.target.value)}
            />
          </label>
        </div>
        <div>
          {/* for some reason, having this in a <label> causes all kinds of bullshit to happen */}
          <span>
            Activity Types
            <Typeahead
              multiple
              onChange={newValue => {
                setActivityTypeFilter(newValue.map(e => e.value));
              }}
              options={suggestions}
              selected={activityTypeFilter.map(e => suggestionsMapping[e])}
              id="activity_type_filter"
            />
          </span>
        </div>
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{
            user: getUserIDOrNull(userFilter),
            // activityType_In: (()=> { console.log(activityTypeFilter); return activityTypeFilter.join(',');})(),
            activityType_In: activityTypeFilter.join(','),
            // if any filter is applied, show everything, otherwise latest 30
            first: userFilter || activityTypeFilter.length >= 1 ? null : 30
          }}
          render={({ props, error }) => (
            <ActivitySummaryList
              setUserFilter={setUserFilter}
              setActivityTypeFilter={setActivityTypeFilter}
              list={props}
            />
          )}
        />
      </div>
    </>
  );
};

ActivitySummary.propTypes = {
  activitySummary: PropTypes.object
};

export default ActivitySummary;
