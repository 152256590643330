import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormikReactStrapInput } from './FormikReactStrapInput';

const PasswordInput = ({ label, name, id }) => (
  <Field
    aria-label={label}
    placeholder={label}
    name={name}
    id={id}
    component={FormikReactStrapInput}
    icon="ion-ios-lock"
    type="password"
  />
);

PasswordInput.defaultProps = {
  label: 'Password',
  name: 'password',
  id: 'password'
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string
};

export { PasswordInput };
