import React, { useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import { PropTypes } from 'prop-types';

import { Input, Label } from 'reactstrap';

const OrganisationSelector = ({ list, onChange }) => {
  useEffect(() => {
    onChange && onChange(list.organisations[0].pk);
  });
  return (
    <Label className="mx-3">
      <Input
        type="select"
        onChange={e => onChange && onChange(parseInt(e.target.value))}
      >
        {list.organisations.map(choice => (
          <option key={choice.pk} value={choice.pk}>
            {choice.orgName}
          </option>
        ))}
      </Input>
    </Label>
  );
};

OrganisationSelector.propTypes = {
  list: PropTypes.object,
  onChange: PropTypes.func
};

export default createFragmentContainer(withRouter(OrganisationSelector), {
  list: graphql`
    fragment OrganisationSelector_list on RelayQuery {
      organisations {
        orgName
        pk
        sites
      }
    }
  `
});
