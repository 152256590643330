/**
 * @flow
 * @relayHash cd6474b69fd452f4a82ca239280d5e12
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganisationSelector_list$ref = any;
export type SuperAdminQueryVariables = {||};
export type SuperAdminQueryResponse = {|
  +$fragmentRefs: OrganisationSelector_list$ref
|};
export type SuperAdminQuery = {|
  variables: SuperAdminQueryVariables,
  response: SuperAdminQueryResponse,
|};
*/


/*
query SuperAdminQuery {
  ...OrganisationSelector_list
}

fragment OrganisationSelector_list on RelayQuery {
  organisations {
    orgName
    pk
    sites
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SuperAdminQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OrganisationSelector_list",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SuperAdminQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organisations",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganisationDetails",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orgName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pk",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sites",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SuperAdminQuery",
    "id": null,
    "text": "query SuperAdminQuery {\n  ...OrganisationSelector_list\n}\n\nfragment OrganisationSelector_list on RelayQuery {\n  organisations {\n    orgName\n    pk\n    sites\n  }\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '87ff078dbf3f972762602d96d97a906c';
module.exports = node;
