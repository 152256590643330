import React, { useState } from 'react';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import {
  FormFeedback,
  FormText,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Progress,
  Button
} from 'reactstrap';

const style = {
  marginTop: '1em'
};

function getIcon(show) {
  return show ? 'ion-ios-eye' : 'ion-ios-eye-off';
}

function getInputType(show) {
  return show ? 'text' : 'password';
}

const FormikReactStrapPasswordInput = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordLabels = ['', 'Weak', 'Ok', 'Good', 'Strong'];
  const passwordColors = ['danger', 'danger', 'warning', 'info', 'success'];
  const tested = zxcvbn(rest.values.password);
  const feedback = [...tested.feedback.suggestions, tested.feedback.warning];
  return (
    <FormGroup>
      <Label for={props.id} className={'label-color'}>
        {props.label}
      </Label>
      <InputGroup>
        <Input
          {...props}
          {...fields}
          data-testid="passwordInput"
          type={getInputType(showPassword)}
          invalid={Boolean(touched[fields.name] && errors[fields.name])}
        />
        <div className="input-group-append">
          <Button
            className="btn btn-secondary btn-outline"
            onClick={() => setShowPassword(!showPassword)}
          >
            <span className={getIcon(showPassword)} />
          </Button>
        </div>
      </InputGroup>
      <Progress
        color={passwordColors[tested.score]}
        value={tested.score}
        max={4}
        style={style}
      >
        {passwordLabels[tested.score]}
      </Progress>
      {touched[fields.name]
        ? feedback.map(feedback => (
            <FormText key={feedback}>{feedback}</FormText>
          ))
        : ''}
      {touched[fields.name] && errors[fields.name] ? (
        <FormFeedback>{errors[fields.name]}</FormFeedback>
      ) : (
        ''
      )}
    </FormGroup>
  );
};

FormikReactStrapPasswordInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string
};
export { FormikReactStrapPasswordInput };
