import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../relayEnvironment';
import { commitMutation } from 'react-relay';
import AssessmentForm from '../components/AssessmentForm';
import mentalHealthContinuum from '../surveys/mentalHealthContinuum';
import { nextPage } from '../__utils__/nextPage';

const mutation = graphql`
  mutation MentalHealthContinuumMutation($input: PrePostMutationInput!) {
    prepostMutation(input: $input) {
      nextForm
    }
  }
`;

const MentalHealthContinuum = () => {
  const [formResponse, setFormResponse] = useState({});

  const onSubmit = (values, { setSubmitting }) => {
    const responses = Object.entries(values).map(([key, value]) => ({
      question: key,
      answer: value
    }));

    const variables = {
      input: {
        formName: 'MentalHealthContinuumShortFormForm',
        responses: responses
      }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: response => {
        setSubmitting(false);
        sessionStorage.setItem('nextForm', response.prepostMutation.nextForm);
        nextPage();
      },
      onError: ({ error }) => {
        setFormResponse({ error });
      }
    });
  };

  return (
    <AssessmentForm
      onSubmit={onSubmit}
      formResponse={formResponse}
      heading={mentalHealthContinuum.heading}
      questions={mentalHealthContinuum.questions}
    />
  );
};

export default MentalHealthContinuum;
