import { Likert } from '../components/FormInputs';
import { string } from 'yup';

const marks = {
  1: 'Strongly disagree',
  5: 'Strongly agree'
};

const likertOptions = {
  Component: Likert,
  marks: marks,
  tooltip: false,
  max: 5,
  validation: string().required('Please fill in the question above.')
};

const systemUsabilityScale = {
  heading: 'System Usability Scale',
  questions: [
    {
      questionText: 'I think that I would like to use this system.',
      questionName: 'sus_1',
      id: 'sus_1',
      name: 'sus_1',
      ...likertOptions
    },
    {
      questionText: 'I found the system unnecessarily complex.',
      questionName: 'sus_2',
      id: 'sus_2',
      name: 'sus_2',
      ...likertOptions
    },
    {
      questionText: 'I thought the system was easy to use.',
      questionName: 'sus_3',
      id: 'sus_3',
      name: 'sus_3',
      ...likertOptions
    },
    {
      questionText:
        'I think that I would need the support of a technical person to be able to use this system.',
      questionName: 'sus_4',
      id: 'sus_4',
      name: 'sus_4',
      ...likertOptions
    },
    {
      questionText:
        'I found the various functions in the system were well integrated.',
      questionName: 'sus_5',
      id: 'sus_5',
      name: 'sus_5',
      ...likertOptions
    },
    {
      questionText:
        'I thought there was too much inconsistency in this system.',
      questionName: 'sus_6',
      id: 'sus_6',
      name: 'sus_6',
      ...likertOptions
    },
    {
      questionText:
        'I would imagine that most people would learn to use this system very quickly.',
      questionName: 'sus_7',
      id: 'sus_7',
      name: 'sus_7',
      ...likertOptions
    },
    {
      questionText: 'I found the system very cumbersome to use.',
      questionName: 'sus_8',
      id: 'sus_8',
      name: 'sus_8',
      ...likertOptions
    },
    {
      questionText: 'I felt very confident using the system.',
      questionName: 'sus_9',
      id: 'sus_9',
      name: 'sus_9',
      ...likertOptions
    },
    {
      questionText:
        'I needed to learn a lot of things before I could get going with this system.',
      questionName: 'sus_10',
      id: 'sus_10',
      name: 'sus_10',
      ...likertOptions
    }
  ]
};

export default systemUsabilityScale;
