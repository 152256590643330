import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkDown from 'react-markdown';
import { Button } from 'reactstrap';
import ExternalLink from '../ExternalLink';

// Display a single block of a resource
// note - headingColor and icon are capitalized because django wants it to be so
const Block = ({ heading, paragraph, headingColor, icon, alwaysOpen }) => {
  const [open, setOpen] = useState(alwaysOpen ? true : false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHref, setModalHref] = useState('');
  const [modalContent, setModalContent] = useState('');
  const container = useRef();

  // ensure our external links trigger a popup
  useEffect(() => {
    if (container.current) {
      container.current.querySelectorAll('a[href^="https://"]').forEach(ele => {
        ele.onclick = e => {
          setModalHref(ele.href);
          setModalContent(ele.textContent);
          setModalOpen(true);
          e.preventDefault();
        };
      });
    }
    // rerun this whenever the content (including any links) are re-rendered
  }, [open]);

  const toggle = newVal => {
    if (alwaysOpen) return;
    setOpen(newVal);
  };

  // header extracted for simplicty (i.e., complexity check)
  function header() {
    return heading ? (
      <div
        className={`boxitem-title ${headingColor.toLowerCase()}`}
        onClick={() => toggle(!open)}
      >
        {!alwaysOpen && (
          <span className="float-right">
            <Button
              alt="Toggle Content"
              className={`btn-square resource-collapse-header-button-${headingColor.toLowerCase()}`}
            >
              {open ? '–' : '+'}
            </Button>
          </span>
        )}
        <h2 style={{ display: 'inline' }}>
          {icon !== 'NONE' && (
            <img
              className="resource-block-icon"
              src={`/icons/${icon.toLowerCase()}.svg`}
              alt={icon}
            />
          )}
          {heading}
        </h2>
      </div>
    ) : null;
  }

  function showBody(open, heading) {
    return open || !heading;
  }
  return (
    <>
      {header()}
      {showBody(open, heading) && (
        <div
          className={`boxitem-content ${!heading ? 'no-top top-margin' : ''}`}
          ref={container}
        >
          {heading ? (
            <ReactMarkDown source={paragraph} />
          ) : (
            <div className="text-center">
              <ReactMarkDown source={paragraph} />
            </div>
          )}
        </div>
      )}
      {/* hide the bogus external link */}
      <div className="d-none">
        <ExternalLink
          href={modalHref}
          open={modalOpen}
          toggle={val => setModalOpen(val)}
        >
          {modalContent}
        </ExternalLink>
      </div>
    </>
  );
};

Block.propTypes = {
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  headingColor: PropTypes.string,
  icon: PropTypes.string,
  alwaysOpen: PropTypes.bool
};

export { Block };
