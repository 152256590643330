import React from 'react';
import BHSLogo from '../../assets/images/BHS_colour_logo.svg';
import SJOGLogo from '../../assets/images/bhs-sjog.png';

export const BHS = () => (
  <div>
    <div className="text-center">
      <img src={BHSLogo} alt="Ballarat Health Services Logo" />
      <img
        src={SJOGLogo}
        style={{ maxHeight: '120px', maxWidth: '80%' }}
        alt="Ballarat Health Services Logo"
      />
    </div>

    <table className="table table-borderless mt-5">
      <tbody>
        <tr>
          <td>
            <b>Research Title:</b>
          </td>
          <td>
            Wellbeing Track and Change: Using digital monitoring to improve the
            mental wellbeing of residential aged care workers.
          </td>
        </tr>
        <tr>
          <td>
            <b>Short Title:</b>
          </td>
          <td>
            <b>Workplace Wellbeing Assist Evaluation Trial</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>ERM Project</b>
          </td>
          <td>60926</td>
        </tr>
        <tr>
          <td>
            <b>Project Sponsor</b>
          </td>
          <td>Prevention United</td>
        </tr>
        <tr>
          <td>
            <b>
              Lead Resear<span className="d-xs-inline d-sm-none">-</span>chers:
            </b>
          </td>
          <td>
            Professor Britt Klein - Federation University
            <br />
            Dr. Stephen Carbone - Prevention United <br />
            Associate Professor Lisa Clinnick - Ballarat Health Services
          </td>
        </tr>
        <tr>
          <td>
            <b>
              Associate Resear<span className="d-xs-inline d-sm-none">-</span>
              chers:
            </b>
          </td>
          <td>
            Associate Professor Susan Stacpoole; Dr. Wendi Kruger; Dr. Luke
            Martin; Mr. Asher Leslie; Mr. Owen Cole; Dr. Kerrie Shandley; Dr.
            Lachlan Kent; Mr. Brett James; Mr. Duncan Macneil
          </td>
        </tr>
        <tr>
          <td>
            <b>Location:</b>
          </td>
          <td>Federation University Australia</td>
        </tr>
      </tbody>
    </table>

    <hr />
    <h3 className="pis-heading">Part 1 What does my participation involve?</h3>
    <h4 className="pis-heading">1 Introduction</h4>

    <p>
      Thank you for your interest in this research study. You are invited to
      take part in the Wellbeing Track and Change evaluation trial, to evaluate
      a digital tool called 'Workplace Wellbeing Assist'.
    </p>

    <p>
      We invite staff (RNs, ENs, PCAs, RSAs, Ward Clerks, &amp; Lifestyle) who
      are aged 18 years and over, and permanently (full-time or part-time)
      employed by a Residential Aged Care (RAC) facility to participate in this
      evaluation trial.
    </p>

    <p>
      This Participant Information Sheet tells you about the evaluation trial.
      It explains what is involved. Understanding what the evaluation trial
      involves will help you decide if you want to take part.
    </p>

    <p>
      Please read this information carefully and ask questions about anything
      that you don't understand or want to know more about. Before deciding
      whether or not to take part, you might want to talk about it with a
      relative, friend or your doctor.
    </p>

    <p>
      Participation has been endorsed by Ballarat Health Services Residential
      Aged Care Services senior management for Ballarat Health Services
      Residential Aged Care Services staff participation, but participation in
      this evaluation trial is voluntary and is not a requirement of your job at
      BHS. If you do not wish to take part, you do not have to.
    </p>

    <p>
      If you decide you would like to take part in the evaluation trial, you
      will be asked to provide consent by clicking on the 'I agree to the above
      conditions' button below. By providing your consent you are telling us
      that you:
    </p>
    <ul>
      <li>Understand what you have read in this document</li>
      <li>Consent to take part in the evaluation trial</li>
      <li>
        Consent to the use of your personal and health information as described
        below.
      </li>
    </ul>
    <p>
      You can download an electronic copy of this Participant Information Sheet
      to keep.
    </p>

    <h4 className="pis-heading">2 What is the purpose of this research?</h4>

    <p>
      The purpose of the research is to promote the mental wellbeing of staff at
      BHS RACS facilities through the use of 'Workplace Wellbeing Assist' (WWA).
      WWA is a digital tool that is expected to help identify problems in the
      workplace and allow management to work proactively to address these for
      the benefit of all staff.
    </p>

    <p>
      WWA aims to complement existing efforts of staff and managers at BHS to
      create a positive workplace climate and staff and managers should continue
      to adhere to the existing OHS policies and procedures at BHS throughout
      the course of the trial.
    </p>

    <h4 className="pis-heading">3 What does participation involve?</h4>

    <p>
      This project involves collecting various pieces of information from you
      and you using WWA for up to 20-weeks.
    </p>

    <p>
      <i>About Workplace Wellbeing Assist</i>
    </p>

    <p>
      WWA is a digital tool that has four features: a login screen; a check-in
      survey; a private dashboard only visible to you; and a management
      dashboard visible to managers. Each time you login to WWA you will be
      encouraged to complete a short (10-item) check-in survey. This check-in
      survey includes 4 questions about your personal wellbeing and 6 questions
      about your workplace climate.
    </p>

    <p>
      The trends in your scores on the 4 personal wellbeing questions will be
      displayed on your own private dashboard in WWA. Based on your personal
      wellbeing scores, WWA will then recommend a set of self-care resources to
      support and improve your mental wellbeing. It is important to note that
      while the WWA tool will allow you to track your personal mental wellbeing,
      it is not a screening tool and cannot be used to diagnose whether or not
      you have depression, anxiety or other mental health condition.
    </p>

    <p>
      Your responses to the 10-question check-in survey will also be combined
      with the scores from all other staff in your residential aged care
      facility participating in this evaluation trial. These average scores will
      then be displayed on a manager's dashboard which can be accessed by nurse
      unit managers or other senior management participating in the study.
      Managers will not be able to see your individual personal wellbeing or
      workplace item scores, only the aggregate (collective) and non-identified
      data from all participants combined. Depending on the aggregate scores,
      WWA will recommend and give managers access to a set of management
      resources that are designed to help them rectify any problems highlighted
      by staff through their answers to the check-in survey. Over time this will
      help to create a better workplace climate for all staff. If participant
      numbers are low, data from individual RACFs may be amalgamated with other
      sites to create aggregated (collective) data across BHS RACS. Nurse unit
      managers and other senior managers participating in the study will have
      access to the amalgamated, aggregated data. This will not affect your
      privacy or confidentiality in any way.
    </p>

    <p>
      <i>Using Workplace Wellbeing Assist</i>
    </p>

    <p>If you agree to participate, you will be asked to do the following:</p>
    <ul>
      <li>
        Complete several pre-trial questionnaires through WWA. The
        questionnaires will ask you questions about yourself, including
        demographic details (e.g., age, gender), perceptions about your
        workplace climate, and your mental wellbeing. The questionnaires will
        take approximately 20-30 minutes to complete. Please note: You will have
        1-week to sign-up to this study (until 9th December 2020). This means
        you must complete all the questionnaires within this 1-week to
        participate in the study. After you complete the pre-trial
        questionnaires, you will be able to access WWA.
      </li>

      <li>
        Each time you login to WWA, you will be presented with a short 10-item
        check-in survey that includes 4-items about your personal wellbeing and
        6 workplace-related items.
      </li>

      <li>
        Depending on your answers to the 6 workplace-related items you may be
        presented with further sub-questions that allow you to provide more
        detailed information about any issues or concerns. There is a maximum of
        27 further optional sub-questions, but these sub-questions will only
        display depending on your answers to the 6 core workplace questions.
      </li>

      <li>
        The 10-item check-in survey will take approximately 2-minutes to
        complete. We recommend that you complete it on a fortnightly basis,
        however you are free to do so more or less often. If you complete the
        check-in survey more than once in a day, only one score will be retained
        by WWA. Your results will be updated every fortnight on WWA. Based on
        your personal wellbeing score, WWA will recommend and give you access to
        various self-care resources to support and improve your mental
        wellbeing. Whether you choose to access the resources, which resources
        you access and how often is up to you.
      </li>

      <li>
        After approximately 2-weeks of using WWA, you will be asked to complete
        a 5-minute questionnaire (System Useability Scale (SUS)) about how easy
        you are finding the digital tool to use.
      </li>

      <li>
        At the start of Week 10 and Week 20, you will be asked to complete
        questionnaires to monitor your progress using WWA. These questionnaires
        will take approximately 20-30 minutes to complete.
      </li>
    </ul>

    <p>
      WWA will automatically record information such as how many times you
      logged in, the amount of time you spent on the dashboard, how many times
      you completed the check-in survey, which resources you accessed and how
      often you accessed them, and the amount of time you spent using WWA
      overall.
    </p>

    <p>
      Around the mid-point of the trial, you will be invited to take part in a
      one-to-one interview over the phone, by video-link or in-person (subject
      to public health restrictions) about your experience of using WWA (limited
      interviews will be conducted in Week 10 on a first in basis). The
      interview is optional and participation in the interview is not a
      requirement for trialling WWA.
    </p>

    <h4 className="pis-heading">
      4 Who can take part and participate in the evaluation trial?
    </h4>

    <p>
      Participation in the evaluation trial is open to permanent staff aged 18
      years and over who are in a direct-care role (part-time or full-time) in
      any of the 10 BHS RACS facilities where at least one manager has also
      agreed to participate in the evaluation trial. WWA requires active
      participation from RACS managers.
    </p>

    <h4 className="pis-heading">
      5 Do I have to take part in this research study?
    </h4>

    <p>
      Participation in this research study is voluntary and is not a requirement
      of your job at BHS. If you do not wish to take part, you do not have to.
    </p>

    <p>
      Your decision to take part or not to take part, or to take part and then
      withdraw, will not affect your employment with BHS, or any present or
      future relationship you may have with Federation University or Prevention
      United.
    </p>

    <p>
      Staff are reminded that you are able to freely choose to be involved or
      not be involved in the research project.
    </p>

    <h4 className="pis-heading">
      6 What are the possible benefits of taking part?
    </h4>

    <p>
      We cannot guarantee or promise that you will receive any personal benefits
      from participating in this research study, however, Workplace Wellbeing
      Assist is a digital tool that is designed to help improve the workplace
      climate of RAC facilities and thereby improve the mental wellbeing of
      direct-care staff working in the facility.
    </p>

    <h4 className="pis-heading">
      7 What are the possible risks and disadvantages of taking part?
    </h4>

    <p>
      We do not anticipate there will be any serious risks or disadvantages to
      you from participating in this evaluation of WWA. However, we recognise
      that participation in the trial will take up some of your time. You may
      also feel some upset when completing the 10-item check-in survey or
      answering some of the questions asked during the questionnaires, or
      interviews but please note that the 10-item check-in survey questionnaires
      and interview questions are not mandatory.
    </p>

    <p>
      Should you become upset while using WWA, or if you are concerned you may
      have a mental health condition, we encourage you to access one or more of
      the following options:
    </p>

    <table className="table table-bordered">
      <tbody>
        <tr>
          <td colSpan="2" className="text-center">
            <b>Options for accessing support in the event of distress</b>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            Make an appointment directly with your GP, or other trusted health
            professional.
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <p>
              Make an appointment directly with the Employee Assistance Program
              ("EAP"). EAP is provided as a free and confidential service as
              part of your employment with BHS. BHS uses Converge International
              for the EAP.
            </p>

            <p>The contact details for Converge International are:</p>
            <ul>
              <li>Phone: 1300 687 327 or</li>
              <li>
                Email:{' '}
                <a href="mailto:eap@convergeintl.com.au">
                  eap@convergeintl.com.au
                </a>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            Contact Lifeline or Beyond Blue crisis support lines, details as
            follows:
            <ul>
              <li>
                Lifeline 'Chat online' (
                <a href="https://www.lifeline.org.au/about-lifeline/contact-us">
                  https://www.lifeline.org.au
                  <span className="d-xs-inline d-sm-none">
                    <br />
                  </span>
                  /about-lifeline/contact-us
                </a>
                ) or call 131114.
              </li>

              <li>
                Beyond Blue 'Chat online' (
                <a href="https://www.beyondblue.org.au">
                  https://www.
                  <span className="d-xs-inline d-sm-none">
                    <br />
                  </span>
                  beyondblue.org.au
                </a>
                ) or call 1300 22 4636.
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>

    <h4 className="pis-heading">
      8 What if I withdraw from this research study?
    </h4>

    <p>
      Participation in the WWA evaluation trial is completely voluntary and you
      may withdraw at any time without having to provide an explanation. If you
      decide to stop taking part in the trial, the researchers will not collect
      any more information from you.
    </p>

    <p>
      However, you should be aware that your contribution up to the time you
      withdraw will form part of the research study results where the data has
      been aggregated (i.e. unable to be individually identified). At this time,
      it will not be possible to withdraw personal information/data, although
      you may still withdraw your consent to participate.
    </p>

    <p>
      If you decide to withdraw from the trial, please email a member of the
      research team by email at{' '}
      <a href="mailto:beri@federation.edu.au">beri@federation.edu.au</a>.
    </p>

    <p>
      Your decision to withdraw will not affect your relationship with other
      staff, your employment with BHS or any current or future relationship you
      may have with Federation University or Prevention United.
    </p>

    <h4 className="pis-heading">
      9 Could this research study be stopped unexpectedly?
    </h4>

    <p>
      This research study may be stopped unexpectedly for a variety of reasons
      such as limited BHS staff involvement in the evaluation trial of WWA.
    </p>

    <h4 className="pis-heading">
      10 What happens when the research study ends?
    </h4>

    <p>
      At the end of the evaluation trial all the data collected from
      participants will be analysed to determine whether WWA is effective in
      improving the workplace climate in BHS RACS and supporting workers' mental
      wellbeing. At the end of this research study, a summary of the results
      will be made available to BHS to share with staff who participated in the
      evaluation trial via the BHS intranet. Only the aggregated (collective)
      results will be reported which means that no personally identifying
      information will be included.
    </p>
    <hr />
    <h3 className="pis-heading">
      Part 2 How is the research study being conducted?
    </h3>

    <h4 className="pis-heading">
      11 What will happen to information about me?
    </h4>

    <p>
      We employ professional and industry standards for the security and
      protection of information collected. All the data you provide is private
      and confidential although subject to legal limitations (e.g., legal
      subpoena, disclosure of harm). All identifiable data (e.g. questionnaire
      responses) will be de-identified using a Participant ID number. Your data
      will only be accessible to Federation University and Prevention United
      researchers. No-one at BHS will have access to any identifiable data that
      you provide. Only you can access your private, personal dashboard.
      Management will only receive non-identifying, aggregate data on all staff
      and not from any individual staff member.
    </p>

    <p>
      All digital information resulting from your use of WWA (including your
      answers to the WWA check-in questions) will be stored, accessed, and
      destroyed in the following ways:
    </p>
    <ul>
      <li>
        Stored: Electronic data will be stored securely, at a minimum, for seven
        years following any publications.
      </li>

      <li>
        Accessed: When analysing any data, any identifying information will be
        removed so cannot be linked back to you. As the data are analysed
        without any identifiable information, and as publications will report
        findings based on collective data, with the exception of possible
        non-identifiable quotes, individual participants will not be
        identifiable in any way.
      </li>

      <li>
        Destroyed: After seven years, following any publications, all digitised
        data will be safely destroyed using secure ethical standards.
      </li>
    </ul>
    <p>
      It is anticipated that the results of this research study will be
      published and/or presented in a variety of places. In any publication
      and/or presentation, information will be provided in such a way that you
      cannot be identified (i.e., aggregate data).
    </p>

    <p>
      Study data may also be used for other studies into the future, such as
      being re-analysed or added to other digital health data to answer other
      research questions. Data mining activities (exploring data sets) often
      require large amounts of data. Data from small research projects can be
      combined to allow for more robust data mining procedures to be applied
      that can be used to answer new and novel questions not possible from one
      small data set. In addition, new discoveries or theories formulated into
      the future may inform the re-analysis of data in new ways. As it cannot be
      known ahead of time, there will always be the possibility that the data
      can be re-analysed to discover new knowledge or understandings into the
      future.
    </p>

    <p>
      Any person outside the research team requesting access to the data will
      only be supplied with non-identifiable data and can only receive it for a
      legitimate research purpose (e.g., data use for a future PhD research
      thesis project) after obtaining the appropriate ethical clearance. Upon
      conclusion, the 'other' researcher will destroy the password protected,
      non-identifiable data file provided to them.
    </p>

    <h4 className="pis-heading">
      13 Who is organising and funding the research?
    </h4>

    <p>
      The research study is supported by the WorkSafe WorkWell Mental Health
      Improvement Fund. WorkSafe have no involvement in the conduct of the
      research study.
    </p>

    <p>
      You will not benefit financially from your involvement in this research
      study even if, for example, knowledge acquired through this research leads
      to discoveries that are of commercial value to WorkSafe, the researchers
      or their institutions.
    </p>

    <p>
      No member of the research team will receive a personal financial benefit
      from your involvement in this research study (other than their salary).
    </p>

    <h4 className="pis-heading">14 Who has reviewed the research study?</h4>

    <p>
      All research in Australia involving humans is reviewed by an independent
      group of people called a Human Research Ethics Committee (HREC). This
      research study has been approved by the HREC of Ballarat Health Services
      and St John of God Hospital Ballarat and the HREC of Federation University
      Australia, Ballarat.
    </p>

    <p>
      This study will be carried out according to the National Statement on
      Ethical Conduct in Human Research (2012). This statement has been
      developed to protect the interests of people who agree to participate in
      human research studies.
    </p>

    <p>
      If you have any questions about the approval of the study, the way it is
      being conducted or any questions about being a research participant in
      general, then you may contact:
    </p>

    <p>
      <b>HREC approving this research and HREC Manager details</b>
    </p>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>HREC name</td>
          <td>
            Ballarat Health Services and St John of God Hospital Ballarat Human
            Research Ethics Committee
          </td>
        </tr>
        <tr>
          <td>HREC Manager</td>
          <td> Diane Clingin</td>
        </tr>
        <tr>
          <td>Telephone</td>
          <td> 03 53208661</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>
            diane.clingin@
            <span className="d-xs-inline d-sm-none">
              <br />
            </span>
            bhs.org.au or ResearchEthics@
            <span className="d-xs-inline d-sm-none">
              <br />
            </span>
            bhs.org.au
          </td>
        </tr>
      </tbody>
    </table>

    <p>
      If you have a complaint about the research study or complaint about the
      conduct of the study you can contact the following person:
    </p>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>Name</td>
          <td> Diane Clingin</td>
        </tr>
        <tr>
          <td>Position</td>
          <td>
            Manager, Research Ethics and Governance, Ballarat Health Services
          </td>
        </tr>
        <tr>
          <td>Address</td>
          <td> P.O. BOX 577, Drummond Street Nth, Ballarat, Victoria, 3350</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>
            diane.clingin@bhs.org.au or ResearchEthics@
            <span className="d-xs-inline d-sm-none">
              <br />
            </span>
            bhs.org.au
          </td>
        </tr>
        <tr>
          <td>Telephone</td>
          <td> 03 53208661</td>
        </tr>
      </tbody>
    </table>
    <h4 className="pis-heading">15 Further information and who to contact</h4>

    <p>
      If you want any further information about this research study or if you
      have any problems which may be related to your involvement in the study,
      you can contact the following person:
    </p>

    <p>
      <b>Research contact person</b>
    </p>

    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>Name</td>
          <td>Wendi Kruger</td>
        </tr>
        <tr>
          <td>Position</td>
          <td>Research Fellow</td>
        </tr>
        <tr>
          <td>Telephone</td>
          <td>0407 948 501</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>beri@federation.edu.au</td>
        </tr>
      </tbody>
    </table>
    <p>
      If you would like to participate and have read and understood this
      Participant Information Sheet and the participant declaration below, click
      the 'I agree to the above conditions' button below.
    </p>

    <h4 className="pis-heading" style={{ pageBreakBefore: 'always' }}>
      <u>Declaration by Participant</u>
    </h4>

    <p>I have read the Participant Information Sheet.</p>

    <p>
      The research program in which I am being asked to participate has been
      explained fully to me, verbally where required and in writing, and any
      matters on which I have sought information have been answered to my
      satisfaction.
    </p>

    <p>
      I freely agree to participate in this research study as described and
      understand that I am free to withdraw at any time during the study without
      affecting my employment or care.
    </p>

    <p>I understand that:</p>

    <ul>
      <li>
        All information I provide will be treated with the strictest confidence
        and data will be stored separately from any listing that includes my
        name and email address.
      </li>
      <li>
        I understand that if I withdraw from the WWA evaluation trial that
        information I have provided will have been aggregated (i.e. unable to be
        individually identified) and it will not be possible to withdraw my
        personal information/data, although I may still withdraw my consent to
        participate.
      </li>
      <li>
        Aggregated results (i.e. unable to be individually identified) will be
        used for research purposes and may be reported in scientific and
        academic journals.
      </li>
    </ul>

    <p>
      I understand the purposes, procedures and risks associated with
      participating in this research study.
    </p>

    <p>
      I have had an opportunity to ask questions and I am satisfied with the
      answers I have received.
    </p>

    <p>I understand that I can download a copy of this document to keep.</p>
  </div>
);
