import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../relayEnvironment';
import { commitMutation, QueryRenderer } from 'react-relay';
import { Formik, FieldArray, Form } from 'formik';
import { Button, FormGroup, Container, Spinner, Alert } from 'reactstrap';
import FormikCheckbox from '../components/form/FormikCheckbox';

import '../assets/css/Forms.scss';
import { Link } from 'react-router-dom';

const mutation = graphql`
  mutation FollowOnQuestionsMutation($input: FollowOnQuestionsInput!) {
    relayFollowOnQuestions(input: $input) {
      successMessage
    }
  }
`;

const query = graphql`
  query FollowOnQuestionsQuery {
    followOnQuestions {
      edges {
        node {
          questionName
          linkedQuestion {
            followOnTitle
            followOnText
          }
          choices {
            edges {
              node {
                text
              }
            }
          }
        }
      }
    }
  }
`;

const FollowOnQuestionsInner = ({ questions }) => {
  const [formSubmitResponse, setFormSubmitResponse] = useState(false);

  // scroll to top, but only the first time this component renders
  // if not within a hook, this will re-run when the user completes the followon questions
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (values, { setSubmitting }) => {
    const answers = Object.entries(values);
    const responses = answers.map(answer => ({
      questionName: answer[0],
      answerText: answer[1]
    }));

    const variables = {
      input: { responses }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: () => {
        setSubmitting(false);
        setFormSubmitResponse({
          color: 'success',
          msg: 'Thank you for your feedback! Click here to continue.'
        });
      },
      onError: error => {
        setSubmitting(false);
        setFormSubmitResponse({
          color: 'warning',
          msg: `Something went wrong, are you logged in?`
        });
      }
    });
  };

  return (
    <Container>
      <div className="boxitem-container no-top">
        <div className="boxitem-content no-top instruction-text text-left">
          <p>
            Please provide more information about your responses to the
            workplace questions you just completed.
          </p>
          <p>
            This information will help your managers to create a more mentally
            healthy workplace. Please select all options that apply. Answering
            these questions is optional.
          </p>
        </div>
        <Formik
          initialValues={
            questions &&
            questions.reduce(
              (object, question) => ({
                [question.node.questionName]: [],
                ...object
              }),
              {}
            )
          }
          onSubmit={(values, { setSubmitting, setStatus }, errors) =>
            onSubmit(values, { setSubmitting, setStatus }, errors)
          }
        >
          {({ isSubmitting, errors, submitCount }) => (
            <Form>
              {questions.map((question, index) => (
                <FormGroup key={question.node.questionName}>
                  <div className="boxitem-title blue">
                    <span className="questionText">
                      {question.node.linkedQuestion.followOnTitle}
                    </span>
                  </div>
                  <div className="boxitem-content boxitem-form">
                    <div className="boxitem-form-text">
                      {question.node.linkedQuestion.followOnText}
                    </div>
                    <FieldArray
                      name={question.node.questionName}
                      // component={MyDynamicForm}     -> This is so we can get arrayhelpers!
                      render={arrayHelpers => (
                        <React.Fragment>
                          {question.node.choices.edges.map((choice, index) => (
                            <FormikCheckbox
                              questionName={question.node.questionName}
                              submitCount={submitCount}
                              questionNumber={index}
                              label={choice.node.text}
                              elementKey={`${question.node.questionName}-${index}`}
                              key={choice.node.text}
                              arrayHelper={arrayHelpers}
                            />
                          ))}
                        </React.Fragment>
                      )}
                    />
                  </div>
                </FormGroup>
              ))}

              <div className="text-center boxitem-content">
                <Button
                  size="lg"
                  type="submit"
                  data-testid="submitButton"
                  disabled={isSubmitting}
                  className="btn btn-primary mb-3"
                >
                  {isSubmitting ? 'SUBMITTING SURVEY' : 'SUBMIT SURVEY'}
                  {isSubmitting && (
                    <Spinner size="lg" className={'ml-3'} color="success" />
                  )}
                </Button>
                {formSubmitResponse && formSubmitResponse.msg && (
                  <Link to="/my-results">
                    <Alert color={formSubmitResponse.color}>
                      {formSubmitResponse.msg}
                    </Alert>
                  </Link>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

FollowOnQuestionsInner.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object)
};

export const FollowOnQuestions = () => (
  <QueryRenderer
    environment={environment}
    query={query}
    render={({ props }) => {
      if (!props) {
        return (
          <div
            style={{
              minHeight: '100vh', // It looks pretty bad when the footer pops out during loading
              color: 'grey',
              textAlign: 'center',
              padding: '1em'
            }}
          >
            Please wait, loading your questions...{' '}
          </div>
        );
      } else {
        return (
          <FollowOnQuestionsInner questions={props.followOnQuestions.edges} />
        );
      }
    }}
  />
);

FollowOnQuestions.propTypes = {
  // prop-types rule thinks _any_ variable with name 'props' is a react prop of some sort
  // even when it's not the props object passed to the component (in this case - it's a subcomponent)
  followOnQuestions: PropTypes.arrayOf(PropTypes.object)
};

export default FollowOnQuestions;
