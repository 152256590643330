/**
 * @flow
 * @relayHash 151294a6cf152b3e6fbb1d4969130a89
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ScheduleQueryVariables = {||};
export type ScheduleQueryResponse = {|
  +prepostSchedule: ?$ReadOnlyArray<?{|
    +name: ?string,
    +start: ?string,
    +expires: ?string,
    +frontlineWorker: ?boolean,
    +manager: ?boolean,
    +forms: ?$ReadOnlyArray<?string>,
  |}>
|};
export type ScheduleQuery = {|
  variables: ScheduleQueryVariables,
  response: ScheduleQueryResponse,
|};
*/


/*
query ScheduleQuery {
  prepostSchedule {
    name
    start
    expires
    frontlineWorker
    manager
    forms
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "prepostSchedule",
    "storageKey": null,
    "args": null,
    "concreteType": "OrganisationPrepostScheduleObject",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "start",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expires",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "frontlineWorker",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "manager",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "forms",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ScheduleQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ScheduleQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ScheduleQuery",
    "id": null,
    "text": "query ScheduleQuery {\n  prepostSchedule {\n    name\n    start\n    expires\n    frontlineWorker\n    manager\n    forms\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21fc20ede7542f1b252f5fa30a31545f';
module.exports = node;
