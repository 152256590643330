import { Likert } from '../components/form/Likert';
import { string } from 'yup';

const marks1 = {
  1: 'To a very large extent',
  2: 'To a large extent',
  3: 'Somewhat',
  4: 'To a small extent',
  5: 'To a very small extent'
};

const marks2 = {
  1: 'Always',
  2: 'Often',
  3: 'Sometimes',
  4: 'Seldom',
  5: 'Never/hardly ever'
};

const marks3 = {
  1: 'All the time',
  2: 'A large part of the time',
  3: 'Part of the time',
  4: 'A small part of the time',
  5: 'Not at all'
};

const copenhagenPsychosocialLikertOptions1 = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks1,
  max: 5,
  tooltip: false
};

const copenhagenPsychosocialLikertOptions2 = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks2,
  max: 5,
  tooltip: false
};

const copenhagenPsychosocialLikertOptions3 = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks3,
  max: 5,
  tooltip: false
};

const copenhagenPsychosocial = {
  heading: 'Copenhagen Psychosocial Questionnaire',
  questions: [
    {
      questionText: 'Do you work at a high pace throughout the day?',
      questionName: 'copenhagen_psychosocial_1',
      ...copenhagenPsychosocialLikertOptions1
    },
    {
      questionText: 'Is it necessary to keep working at a high pace?',
      questionName: 'copenhagen_psychosocial_2',
      ...copenhagenPsychosocialLikertOptions1
    },
    {
      questionText:
        'Does your work put you in emotionally disturbing situations?',
      questionName: 'copenhagen_psychosocial_3',
      ...copenhagenPsychosocialLikertOptions2
    },
    {
      questionText:
        "Do you have to relate to other people's personal problems as part of your work?",
      questionName: 'copenhagen_psychosocial_4',
      ...copenhagenPsychosocialLikertOptions2
    },
    {
      questionText:
        'Do you have a large degree of influence concerning your work?',
      questionName: 'copenhagen_psychosocial_5',
      ...copenhagenPsychosocialLikertOptions2
    },
    {
      questionText: 'Can you influence the amount of work assigned to you?',
      questionName: 'copenhagen_psychosocial_6',
      ...copenhagenPsychosocialLikertOptions2
    },
    {
      questionText:
        'Is your work recognised and appreciated by the management?',
      questionName: 'copenhagen_psychosocial_7',
      ...copenhagenPsychosocialLikertOptions1
    },
    {
      questionText: 'Are you treated fairly at your workplace?',
      questionName: 'copenhagen_psychosocial_8',
      ...copenhagenPsychosocialLikertOptions1
    },
    {
      questionText: 'Are conflicts resolved in a fair way?',
      questionName: 'copenhagen_psychosocial_9',
      ...copenhagenPsychosocialLikertOptions1
    },
    {
      questionText: 'Is the work distributed fairly?',
      questionName: 'copenhagen_psychosocial_10',
      ...copenhagenPsychosocialLikertOptions1
    },
    {
      questionText:
        'Regarding your work in general, how pleased are you with your job as a whole, with' +
        ' everything taken into consideration?',
      questionName: 'copenhagen_psychosocial_11',
      validation: string().required('Please fill in the question above.'),
      Component: Likert,
      marks: {
        1: 'Very satisfied',
        2: 'Satisfied',
        3: 'Unsatisfied',
        4: 'Very unsatisfied',
        5: 'Not relevant'
      },
      max: 5,
      tooltip: false
    },
    {
      questionText:
        'During the last 4 weeks, in general, would you say your health is:',
      questionName: 'copenhagen_psychosocial_12',
      validation: string().required('Please fill in the question above.'),
      Component: Likert,
      marks: {
        1: 'Excellent',
        2: 'Very good',
        3: 'Good',
        4: 'Fair',
        5: 'Poor'
      },
      max: 5,
      tooltip: false
    },
    {
      questionText:
        'During the last 4 weeks, how often have you felt worn out?',
      questionName: 'copenhagen_psychosocial_13',
      ...copenhagenPsychosocialLikertOptions3
    },
    {
      questionText:
        'During the last 4 weeks, how often have you been emotionally exhausted?',
      questionName: 'copenhagen_psychosocial_14',
      ...copenhagenPsychosocialLikertOptions3
    }
  ]
};

export default copenhagenPsychosocial;
