/**
 * @flow
 * @relayHash 3bff91857916617027d235c9d24b0510
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PrePostMutationInput = {|
  formName: string,
  responses: $ReadOnlyArray<?Response>,
  clientMutationId?: ?string,
|};
export type Response = {|
  question: string,
  answer: string,
|};
export type MentalHealthContinuumMutationVariables = {|
  input: PrePostMutationInput
|};
export type MentalHealthContinuumMutationResponse = {|
  +prepostMutation: ?{|
    +nextForm: ?string
  |}
|};
export type MentalHealthContinuumMutation = {|
  variables: MentalHealthContinuumMutationVariables,
  response: MentalHealthContinuumMutationResponse,
|};
*/


/*
mutation MentalHealthContinuumMutation(
  $input: PrePostMutationInput!
) {
  prepostMutation(input: $input) {
    nextForm
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PrePostMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "prepostMutation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PrePostMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "nextForm",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MentalHealthContinuumMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MentalHealthContinuumMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MentalHealthContinuumMutation",
    "id": null,
    "text": "mutation MentalHealthContinuumMutation(\n  $input: PrePostMutationInput!\n) {\n  prepostMutation(input: $input) {\n    nextForm\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '33bcc9047fa6e03d82a26a79029bfdbe';
module.exports = node;
