import React from 'react';
import { Button, Container } from 'reactstrap';
import ContactUs from './ContactUs';
import { Link } from 'react-router-dom';
import VideoModal from '../components/VideoModal';
import Eap from '../components/Eap';

export const links = {
  dashboard: {
    true: 'https://vimeo.com/433465494',
    false: 'https://vimeo.com/433465552'
  },
  wellbeing: {
    true: 'https://vimeo.com/433465611',
    false: 'https://vimeo.com/433465653'
  }
};

const ManagerHelp = () => {
  const userIsManager = sessionStorage.getItem('isManager') === 'true';
  return (
    <Container>
      <div className="boxitem-content no-top">
        <p>
          If you need some help understanding Workplace Wellbeing Assist, you
          can press the buttons below to watch a video or access a resource.
        </p>
        <div className="text-center">
          <VideoModal
            size="lg"
            className="btn btn-primary mt-5 mb-3"
            block
            link={links.dashboard[userIsManager]}
          >
            How to interpret your dashboard
          </VideoModal>
          <VideoModal
            size="lg"
            className="btn btn-primary mt-3 mb-3"
            block
            link={links.wellbeing[userIsManager]}
          >
            Interpreting your wellbeing score
          </VideoModal>
          <Link
            to="/resource/mentally-healthy-workplace"
            className="btn-block mt-3 mb-3"
          >
            <Button size="lg" className="btn btn-primary" block>
              What is a mentally healthy workplace?
            </Button>
          </Link>
          <Link
            to="/resource/mental-health-continuum"
            className="btn-block mt-3 mb-3"
          >
            <Button size="lg" color="primary" block>
              Understanding the mental health continuum
            </Button>
          </Link>
          <Link to={userIsManager ? '/dashboard' : '/my-results'}>
            <Button size="lg" className="btn btn-primary mt-3 mb-3">
              Back to Dashboard
            </Button>
          </Link>
        </div>
        <p>
          If you encounter any problems using Workplace Wellbeing Assist or
          would like to provide any feedback, please use the Contact Us form
          below.
        </p>
        <Eap organisation={process.env.REACT_APP_ORGANIZATION_NAME} />
      </div>

      <ContactUs />
    </Container>
  );
};

export default ManagerHelp;
