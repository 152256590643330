import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

const labels = ['', 'Not at all', 'A little', 'Neutral', 'A lot', 'Completely'];
const ReactstrapSmileyRadio = ({
  field,
  form: { setFieldValue, values },
  disabled = false,
  ...props
}) => (
  <FormGroup
    check
    className={values[field.name] === field.value ? 'active' : ''}
  >
    <Label className="smiley-question" for={props.id}>
      <Input
        {...props}
        type="radio"
        name={field.name}
        checked={values[field.name] === field.value}
        value={field.value}
        onChange={() => setFieldValue(field.name, field.value)}
      />
    </Label>
    <div
      className="smiley-label d-none d-md-block"
      onClick={() => setFieldValue(field.name, field.value)}
    >
      {labels[field.value]}
    </div>
  </FormGroup>
);

ReactstrapSmileyRadio.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string
};
export default ReactstrapSmileyRadio;
