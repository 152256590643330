import { Likert } from '../components/FormInputs';
import { string } from 'yup';

const marks = {
  1: 'Never',
  2: 'Once or twice',
  3: 'About once a week',
  4: 'About 2 or 3 times a week',
  5: 'Almost every day',
  6: 'Every day'
};

const likertOptions = {
  Component: Likert,
  marks: marks,
  tooltip: false,
  max: 6,
  validation: string().required('Please fill in the question above.')
};

const mentalHealthContinuum = {
  heading: 'Mental Health Continuum',
  questions: [
    {
      questionText: 'During the past month how often did you feel happy?',
      questionName: 'mhcsf_1',
      id: 'mhcsf_1',
      name: 'mhcsf_1',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel interested in life?',
      questionName: 'mhcsf_2',
      id: 'mhcsf_2',
      name: 'mhcsf_2',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel satisfied with life?',
      questionName: 'mhcsf_3',
      id: 'mhcsf_3',
      name: 'mhcsf_3',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that you had something important to contribute to society?',
      questionName: 'mhcsf_4',
      id: 'mhcsf_4',
      name: 'mhcsf_4',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that you belonged to a community' +
        ' (like a social group or your neighborhood)?',
      questionName: 'mhcsf_5',
      id: 'mhcsf_5',
      name: 'mhcsf_5',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that our society is a good place or is becoming a better' +
        ' place for all people?',
      questionName: 'mhcsf_6',
      id: 'mhcsf_6',
      name: 'mhcsf_6',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that people are basically good?',
      questionName: 'mhcsf_7',
      id: 'mhcsf_7',
      name: 'mhcsf_7',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that the way our society works makes sense to you?',
      questionName: 'mhcsf_8',
      id: 'mhcsf_8',
      name: 'mhcsf_8',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that you liked most parts of your personality?',
      questionName: 'mhcsf_9',
      id: 'mhcsf_9',
      name: 'mhcsf_9',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel good at managing the responsibilities of your daily life?',
      questionName: 'mhcsf_10',
      id: 'mhcsf_10',
      name: 'mhcsf_10',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that you had warm and trusting relationships with others?',
      questionName: 'mhcsf_11',
      id: 'mhcsf_11',
      name: 'mhcsf_11',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that you had experiences that challenged you' +
        ' to grow and become a better person?',
      questionName: 'mhcsf_12',
      id: 'mhcsf_12',
      name: 'mhcsf_12',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel confident to think or express your own ideas and opinions?',
      questionName: 'mhcsf_13',
      id: 'mhcsf_13',
      name: 'mhcsf_13',
      ...likertOptions
    },
    {
      questionText:
        'During the past month how often did you feel that your life has a sense of direction or meaning to it?',
      questionName: 'mhcsf_14',
      id: 'mhcsf_14',
      name: 'mhcsf_14',
      ...likertOptions
    }
  ]
};

export default mentalHealthContinuum;
