import React from 'react';
import { Field } from 'formik';
import { FormikReactStrapInput } from './FormikReactStrapInput';

const EmailInput = () => (
  <Field
    aria-label="Email"
    name="email"
    id="email"
    type="email"
    placeholder="Email Address"
    icon="ion-ios-mail"
    component={FormikReactStrapInput}
  />
);

export { EmailInput };
