/**
 * @flow
 * @relayHash e66ac84c970082b0ae0a624d8377f80f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DataLineChart_data$ref = any;
export type MyResultsQueryVariables = {||};
export type MyResultsQueryResponse = {|
  +wellbeing: ?number,
  +$fragmentRefs: DataLineChart_data$ref,
|};
export type MyResultsQuery = {|
  variables: MyResultsQueryVariables,
  response: MyResultsQueryResponse,
|};
*/


/*
query MyResultsQuery {
  ...DataLineChart_data
  wellbeing
}

fragment DataLineChart_data on RelayQuery {
  wellbeingChart {
    mean
    date
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wellbeing",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MyResultsQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      (v0/*: any*/),
      {
        "kind": "FragmentSpread",
        "name": "DataLineChart_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyResultsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "wellbeingChart",
        "storageKey": null,
        "args": null,
        "concreteType": "ChartPoint",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "mean",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          }
        ]
      },
      (v0/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MyResultsQuery",
    "id": null,
    "text": "query MyResultsQuery {\n  ...DataLineChart_data\n  wellbeing\n}\n\nfragment DataLineChart_data on RelayQuery {\n  wellbeingChart {\n    mean\n    date\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c32af3208aa679bc0f283dc4c53b0a6';
module.exports = node;
