/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubFocusBarChart_data$ref: FragmentReference;
declare export opaque type SubFocusBarChart_data$fragmentType: SubFocusBarChart_data$ref;
export type SubFocusBarChart_data = {|
  +subfocusOverview: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +topic: string,
        +questionText: string,
        +rawCount: number,
        +percentage: ?number,
      |}
    |}>
  |},
  +$refType: SubFocusBarChart_data$ref,
|};
export type SubFocusBarChart_data$data = SubFocusBarChart_data;
export type SubFocusBarChart_data$key = {
  +$data?: SubFocusBarChart_data$data,
  +$fragmentRefs: SubFocusBarChart_data$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SubFocusBarChart_data",
  "type": "RelayQuery",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "sitePk",
      "type": "Int"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subfocusOverview",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "sitePk",
          "variableName": "sitePk"
        }
      ],
      "concreteType": "FocusBarPointConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FocusBarPointEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FocusBarPoint",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "topic",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "questionText",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "rawCount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "percentage",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '35927d0fca73bc9a78a954022680a0fd';
module.exports = node;
