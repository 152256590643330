/**
 * @flow
 * @relayHash c7a4da10ace3929fe810546519bfeb80
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ForgotPasswordInput = {|
  email: string,
  clientMutationId?: ?string,
|};
export type ForgotPasswordMutationVariables = {|
  input: ForgotPasswordInput
|};
export type ForgotPasswordMutationResponse = {|
  +forgotPassword: ?{|
    +success: ?boolean
  |}
|};
export type ForgotPasswordMutation = {|
  variables: ForgotPasswordMutationVariables,
  response: ForgotPasswordMutationResponse,
|};
*/


/*
mutation ForgotPasswordMutation(
  $input: ForgotPasswordInput!
) {
  forgotPassword(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ForgotPasswordInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "forgotPassword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ForgotPasswordPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ForgotPasswordMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ForgotPasswordMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ForgotPasswordMutation",
    "id": null,
    "text": "mutation ForgotPasswordMutation(\n  $input: ForgotPasswordInput!\n) {\n  forgotPassword(input: $input) {\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c6218d4d521832099aae6cb28b8e6234';
module.exports = node;
