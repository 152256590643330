import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import { object as yup, string } from 'yup';
import { Formik, Form } from 'formik';
import environment from '../relayEnvironment';
import { FormGroup, Button, Alert } from 'reactstrap';
import { CreatePasswordInput } from './FormInputs';
import zxcvbn from 'zxcvbn';

const mutation = graphql`
  mutation ResetPasswordFormMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`;

const schema = yup().shape({
  password: string()
    .min(5, 'Password must be at least 5 characters.')
    .test(
      'password strength',
      'Password not strong enough',
      value => value && zxcvbn(value).score > 2
    )
    .required()
});

const ResetPasswordForm = ({ match }) => {
  const [success, setSuccess] = useState(false);
  const { token, uid } = match.params;
  const [error, setError] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const submit = (values, actions) => {
    const variables = {
      input: {
        newPassword: values.password,
        token: token,
        uid: uid
      }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: () => {
        setSuccess(true);
        actions.setSubmitting(false);
      },
      onError: error => {
        if (error.res && error.res.errors && error.res.errors[0]) {
          // You have a server status of 200 and the server is up, show it as is (login is wrong error)
          setError(error.res.errors[0].message);
        } else {
          setError('Technical difficulties talking to the server');
        }
        setAlertOpen(true);
      }
    });
  };

  return (
    <React.Fragment>
      <Alert
        color="danger"
        isOpen={alertOpen}
        data-testid="errorAlert"
        toggle={() => setAlertOpen(false)}
      >
        {error}
      </Alert>
      {success ? (
        <div>
          <h2>Your password was successfully set</h2>
          <p>Click the button below to be redirected to the Log In page</p>

          <a href="/login" className="btn btn-primary">
            LOG IN
          </a>
        </div>
      ) : (
        <Formik
          initialValues={{ email: '', password: '', confirmPassword: '' }}
          validationSchema={schema}
          onSubmit={(values, actions) => submit(values, actions)}
          render={() => (
            <Form>
              <FormGroup>
                <CreatePasswordInput />
              </FormGroup>
              <Button type="submit">Submit</Button>
            </Form>
          )}
        />
      )}
    </React.Fragment>
  );
};

ResetPasswordForm.propTypes = {
  match: PropTypes.object
};

export default ResetPasswordForm;
