import React, { useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import PropTypes from 'prop-types';
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

// Special definitions for highlighting the correct menu tab while on a sub-page
// see AppRouter.js
const URLs = {
  resource: ['/resources', '/resource/:slug'],
  survey: ['/survey', '/follow-on-questions'],
  dashboard: [
    '/dashboard/:lookback?',
    '/my-results/:lookback?',
    '/sub-focus/:topic?'
  ]
};

function matchesPath(urls, path) {
  return urls.indexOf(path) !== -1;
}

const Menu = ({ isAuthenticated, path }) => {
  const [isOpen, setIsOpen] = useState(false);
  // sessionStorage only stores strings
  const userIsManager = sessionStorage.getItem('isManager') === 'true';

  return (
    <Navbar color="white" light expand="lg">
      <Container>
        <NavbarBrand tag={RRNavLink} to="/login">
          <img className="logo" src={logo} alt="Workplace Wellbeing Assist" />
        </NavbarBrand>
        <NavbarToggler
          data-testid="navtoggle"
          onClick={() => setIsOpen(!isOpen)}
        />
        <Collapse isOpen={isOpen} navbar className="flex-row-reverse">
          <Nav tabs className="flex-column flex-md-row">
            {isAuthenticated ? (
              <>
                {userIsManager ? (
                  <NavItem>
                    <NavLink
                      tag={RRNavLink}
                      to="/dashboard"
                      active={matchesPath(URLs.dashboard, path)}
                    >
                      DASHBOARD
                    </NavLink>
                  </NavItem>
                ) : (
                  <>
                    <NavItem>
                      <NavLink
                        tag={RRNavLink}
                        to="/survey"
                        active={matchesPath(URLs.survey, path)}
                      >
                        SURVEY
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={RRNavLink}
                        to="/my-results"
                        active={matchesPath(URLs.dashboard, path)}
                      >
                        DASHBOARD
                      </NavLink>
                    </NavItem>
                  </>
                )}
                <NavItem>
                  <NavLink
                    tag={RRNavLink}
                    to="/resources"
                    active={matchesPath(URLs.resource, path)}
                  >
                    RESOURCE LIBRARY
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} to="/help">
                    HELP
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} to="/logout">
                    LOG OUT
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <NavItem>
                <NavLink tag={RRNavLink} to="/login">
                  LOG IN
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Menu.propTypes = {
  isAuthenticated: PropTypes.bool,
  path: PropTypes.string
};

Menu.defaultProps = {
  isAuthenticated: false
};

export default Menu;
