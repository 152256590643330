import React, { useState } from 'react';
import { Button, Alert, Container } from 'reactstrap';
import { object as yup, string } from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '../components/FormInputs';
import { graphql } from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import environment from '../relayEnvironment';

const schema = yup().shape({
  name: string().required(),
  email: string()
    .email()
    .required(),
  message: string().required()
});

const mutation = graphql`
  mutation RequestConsultationMutation(
    $input: RequestConsultationMutationInput!
  ) {
    requestConsultationMutation(input: $input) {
      id
    }
  }
`;

const RequestConsultation = () => {
  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);
  const [error, setError] = useState(false);
  const submit = values => {
    const variables = {
      input: values
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: () => setConfirmMessageOpen(true),
      onError: () => setError(true)
    });
  };

  return (
    <Container>
      <div className="boxitem-title blue" id="contact">
        <h2>Request a consultation</h2>
      </div>
      <div className="boxitem-content">
        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          validationSchema={schema}
          onSubmit={(values, actions) => submit(values, actions)}
          render={() => (
            <Form id="form" align="center">
              <Input
                placeholder="Your name"
                name="name"
                id="name"
                autoComplete="off"
                type="text"
              />
              <Input
                placeholder="Your email address"
                name="email"
                id="email"
                type="email"
              />
              <Input
                placeholder="Your message"
                name="message"
                id="message"
                style={{
                  height: '16em',
                  resize: 'none'
                }}
                type="textarea"
              />
              <Button type="submit" className=" btn btn-primary">
                SUBMIT
              </Button>
            </Form>
          )}
        />
      </div>
      <Alert
        color="success"
        isOpen={confirmMessageOpen}
        toggle={() => setConfirmMessageOpen(false)}
      >
        Thank you for your request
      </Alert>
      <Alert color="danger" isOpen={error} toggle={() => setError(false)}>
        Unable to submit.
      </Alert>
    </Container>
  );
};

export default RequestConsultation;
