import history from './history';

const handleSignatureExpired = () => {
  sessionStorage.clear();
  history.push('login');
};

const errorsMiddleware = () => next => async request => {
  const response = await next(request);
  if (response.errors) {
    const { message: errorMessage } = response.errors[0];

    if (errorMessage === 'Signature has expired') {
      handleSignatureExpired();
    }
  }
  return response;
};

export default errorsMiddleware;
