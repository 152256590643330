/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SiteSelector_list$ref: FragmentReference;
declare export opaque type SiteSelector_list$fragmentType: SiteSelector_list$ref;
export type SiteSelector_list = {|
  +sites: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +siteName: ?string,
    +newData: ?boolean,
  |}>,
  +$refType: SiteSelector_list$ref,
|};
export type SiteSelector_list$data = SiteSelector_list;
export type SiteSelector_list$key = {
  +$data?: SiteSelector_list$data,
  +$fragmentRefs: SiteSelector_list$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SiteSelector_list",
  "type": "RelayQuery",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sites",
      "storageKey": null,
      "args": null,
      "concreteType": "SiteDetails",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pk",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "siteName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "newData",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3181bda6e3fd11818589921a91e6cba6';
module.exports = node;
