import React from 'react';
import { PropTypes } from 'prop-types';
import { LineChart } from 'react-chartkick';
import 'chart.js';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';

const MILLISECONDS_IN_A_FORTNIGHT = 1000 * 3600 * 24 * 14;

const getPointColor = ({ dataIndex, dataset: { data } }) => {
  const val = data[dataIndex];
  if (val <= 40) {
    return '#e63e35';
  }
  if (val >= 80) {
    return '#1a9b5f';
  }
  return '#ffc215 ';
};

const getData = ({ wellbeingChart }) => {
  const data = wellbeingChart.slice();
  // fix for empty data sets not rendering - chart.js requires at least one data point in a set
  // otherwise it errors out
  if (data.length === 0) {
    data.push({ mean: 0, date: new Date(0) });
  }
  return [
    {
      name: 'Wellbeing',
      data: data.reduce(
        (object, { mean, date }) => ({
          [new Date(date)]: mean,
          ...object
        }),
        {}
      ),
      library: {
        pointRadius: 6,
        pointHoverRadius: 8,
        pointBorderWidth: 0,
        borderWidth: 2,
        lineTension: 0,
        borderColor: '#222',
        pointBorderColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: getPointColor
      }
    }
  ];
};
const options = () => ({
  showLines: true,
  legend: { display: false },
  showTooltips: false,
  tooltips: { enabled: false },
  hover: { mode: null },
  animation: { duration: 500, easing: 'easeOutQuad' },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 120,
          stepSize: 40,
          display: true,
          // krudge to position labels 'between' ticks
          labelOffset: 20,
          minRotation: 90,
          callback: (value, index, values) => {
            switch (value) {
              case 40:
                return 'LOW   ';
              case 80:
                return 'MEDIUM';
              case 120:
                return 'HIGH   ';
              default:
                return '';
            }
          }
        },
        visible: true,
        display: true
      }
    ],
    xAxes: [
      {
        type: 'time',
        time: {
          unit: 'month'
        },
        ticks: {
          min: new Date(Date.now() - 13 * MILLISECONDS_IN_A_FORTNIGHT),
          max: Date.now()
        }
      }
    ]
  },
  layout: { padding: { left: 15, top: 0, right: 15, bottom: 0 } }
});

const DataLineChart = ({ data }) => (
  <div>
    {!data.wellbeingChart.length && (
      <div className="text-center chart-overlay">
        <h3>No data available. Please check back later.</h3>
      </div>
    )}
    <LineChart data={getData(data)} library={options()} />
  </div>
);

DataLineChart.propTypes = {
  data: PropTypes.object
};

export default createFragmentContainer(withRouter(DataLineChart), {
  data: graphql`
    fragment DataLineChart_data on RelayQuery {
      wellbeingChart {
        mean
        date
      }
    }
  `
});
