import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import { object as yup, string } from 'yup';
import { Formik, Form } from 'formik';
import environment from '../relayEnvironment';
import { Container, FormGroup, Button } from 'reactstrap';
import { EmailInput } from '../components/FormInputs';

const mutation = graphql`
  mutation ForgotPasswordMutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
    }
  }
`;

const schema = yup().shape({
  email: string()
    .email('Please enter a valid email.')
    .required()
});

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);

  const submit = (values, actions) => {
    const variables = {
      input: {
        email: values.email.toLowerCase()
      }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: () => {
        setSuccess(true);
        actions.setSubmitting(false);
      }
    });
  };

  return (
    <Container>
      <div className="boxitem-content no-top x-padding text-center">
        {success ? (
          <p>Please check your email.</p>
        ) : (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={schema}
            onSubmit={(values, actions) => submit(values, actions)}
            render={() => (
              <Form>
                <p>
                  Please enter your email address, and if there is an account
                  associated with that address a link will be sent to you to
                  reset your password.
                </p>
                <FormGroup>
                  <EmailInput />
                </FormGroup>
                <Button type="submit" className="btn btn-primary">
                  RESET PASSWORD
                </Button>
              </Form>
            )}
          />
        )}
      </div>
    </Container>
  );
};

export default ForgotPassword;
