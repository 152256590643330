import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from 'react-relay';
import environment from '../relayEnvironment';
import DataLineChart from '../components/DataLineChart.js';
import TopicCard from '../components/TopicCard';
import '../assets/css/MyResults.css';
import { MappedResources } from '../components/MappedResources';
import VideoModal from '../components/VideoModal';
import { links } from './Help';

const query = graphql`
  query MyResultsQuery {
    ...DataLineChart_data
    wellbeing
  }
`;

const MyResultsInner = ({ props: propsInner, error }) => {
  const getRecommendation = mean => {
    if (mean === 0) {
      return (
        <p>
          We don't have enough information to accurately classify your personal
          wellbeing. Please check back later.
        </p>
      );
    }
    if (mean < 40) {
      return (
        <p>
          Your personal wellbeing is in the <b className="w-range">low range</b>
        </p>
      );
    }
    if (mean > 80) {
      return (
        <p>
          Your personal wellbeing is in the{' '}
          <b className="w-range">high range</b>
        </p>
      );
    }
    return (
      <p>
        Your personal wellbeing is in the{' '}
        <b className="w-range">moderate range</b>
      </p>
    );
  };

  const getFurtherRecommendations = mean => {
    if (mean === 0) {
      return null;
    }
    if (mean < 40) {
      return (
        <div>
          <p>
            <b>You’re doing it tough at the moment. Hang in there.</b>
          </p>
          <p>
            <b>Start small</b> – check out the resources and try a few new
            wellbeing strategies. Small, positive changes build up over time to
            get you back on track.
          </p>
          <p>
            <b>Be kind to yourself</b> – talk to yourself like you would a
            friend who is going through a hard time.
          </p>
          <p>
            <b>Seek support</b> – talk to someone you trust about how you’re
            feeling. Extra support from colleagues, friends, or family can
            really help. Sometimes low wellbeing can indicate a mental health
            condition. It can help to discuss this with a health professional.
            Read our <a href="/resource/help-seeking">Getting Help</a> resource
            or <a href="/urgent-support">Urgent Support</a> options.
          </p>
        </div>
      );
    }
    if (mean > 80) {
      return (
        <div>
          <p>
            <b>You’re doing well at the moment.</b>
          </p>
          <p>
            <b>Keep doing what you’re doing</b> – maintain the positive habits
            that got you to this point.
          </p>
          <p>
            <b>Build your resilience</b> – keep strengthening your mental
            wellbeing so you’re prepared for future challenges. Try one or two
            new strategies to provide an extra boost
          </p>
          <p>
            <b>Stay connected</b> – keep spending time with people who are
            important to you. Consider assisting others who may be doing it
            tough at the moment.
          </p>
        </div>
      );
    }
    return (
      <div>
        <p>
          <b>You’re going OK.</b>
        </p>
        <p>
          <b>Don’t settle for just OK</b> – take your mental wellbeing to the
          next level. Small actions can make a big difference.
        </p>
        <p>
          <b>Take charge of stress</b> – reduce what’s causing you stress or use
          healthy coping strategies to deal with it, or perhaps find what makes
          you feel good and try to enhance it.
        </p>
        <p>
          <b>Talk it over</b> – there may be one or two things on your mind.
          Talk it out with someone you trust.
        </p>
      </div>
    );
  };
  useEffect(() => {
    document.title = `Wellbeing Assist - My Dashboard`;
  });
  if (!propsInner) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Row>
        <Col lg="10" md="9" sm="12" xs="12" className="mt-5">
          <div className="boxitem-title blue">
            <h2>Current Wellbeing</h2>
          </div>
          <div className="boxitem-content">
            <Row>
              <Col xs="12" sm="12" md="12" lg="2" />
              <Col xs="12" sm="12" md="6" lg="4" className="text-right">
                <TopicCard
                  cardTitleText=""
                  cardTextString="Lorem ipsum dolor silat. Crescae num pilat."
                  cardValue={propsInner.wellbeing}
                />
              </Col>

              <Col xs="12" sm="12" md="6" lg="4">
                <div className="lead text-left mt-5 mb-5">
                  {getRecommendation(propsInner.wellbeing)}
                </div>
              </Col>
              <Col xs="12" sm="12" md="12" lg="2" />
            </Row>
            <Row>
              <Col>{getFurtherRecommendations(propsInner.wellbeing)}</Col>
            </Row>
          </div>
          <MappedResources mean={propsInner.wellbeing} />
          <div className="boxitem-title blue">
            <h2>Wellbeing Trends</h2>
          </div>
          <div className="boxitem-content">
            <Col sm="12">
              <p>
                Here’s how your wellbeing has changed over time. You can use
                this to track your progress and identify what strategies have
                been working best for you.
              </p>
            </Col>

            <Col sm="12">
              <DataLineChart data={propsInner} />{' '}
            </Col>
          </div>
        </Col>
        <Col lg="2" md="3" sm="12" xs="12" className="mt-5 text-center">
          <VideoModal
            link={links.dashboard[false]}
            className="mt-5"
            color="outline-primary"
            style={{ width: '178px' }}
          >
            Understanding your dashboard
          </VideoModal>
        </Col>
      </Row>
    </div>
  );
};

MyResultsInner.propTypes = {
  props: PropTypes.object,
  error: PropTypes.object
};

const MyResults = ({ match }) => (
  <Container>
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{ lookback: match.params.lookback || 0 }}
      render={({ props, error }) => <MyResultsInner {...{ props, error }} />}
    />
  </Container>
);

MyResults.propTypes = {
  match: PropTypes.object,
  wellbeing: PropTypes.object
};

export default MyResults;
