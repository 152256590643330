/**
 * @flow
 * @relayHash 998bf39da3bb055c6b9e1d7ee656ae69
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ActivitySummaryTypeQueryVariables = {||};
export type ActivitySummaryTypeQueryResponse = {|
  +activityTypes: ?$ReadOnlyArray<?{|
    +value: ?string,
    +name: ?string,
  |}>
|};
export type ActivitySummaryTypeQuery = {|
  variables: ActivitySummaryTypeQueryVariables,
  response: ActivitySummaryTypeQueryResponse,
|};
*/


/*
query ActivitySummaryTypeQuery {
  activityTypes {
    value
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "activityTypes",
    "storageKey": null,
    "args": null,
    "concreteType": "ActivityType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ActivitySummaryTypeQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ActivitySummaryTypeQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ActivitySummaryTypeQuery",
    "id": null,
    "text": "query ActivitySummaryTypeQuery {\n  activityTypes {\n    value\n    name\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b14bce7b076f48e6260843bb54d87ab';
module.exports = node;
