/**
 * @flow
 * @relayHash e77b9abe73e6e56f0608a5f592ed52f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUserInput = {|
  email: string,
  isManager: boolean,
  site: number,
  clientMutationId?: ?string,
|};
export type AddUserMutationVariables = {|
  input: CreateUserInput
|};
export type AddUserMutationResponse = {|
  +createUser: ?{|
    +created: ?string
  |}
|};
export type AddUserMutation = {|
  variables: AddUserMutationVariables,
  response: AddUserMutationResponse,
|};
*/


/*
mutation AddUserMutation(
  $input: CreateUserInput!
) {
  createUser(input: $input) {
    created
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "created",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddUserMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddUserMutation",
    "id": null,
    "text": "mutation AddUserMutation(\n  $input: CreateUserInput!\n) {\n  createUser(input: $input) {\n    created\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '34af6fed454fa6b8954f4a5e53608ca4';
module.exports = node;
