import { RadioChoice } from '../components/FormInputs';
import { string } from 'yup';

const sharedOptions = {
  Component: RadioChoice,
  validation: string().required('Please fill in the question above.')
};

const australianQualityOfLife = {
  heading: 'Australian Quality of Life',
  instruction:
    'Tick the box that best describes your situation as it has been over the past week',
  questions: [
    {
      questionText:
        'Do you need any help looking after yourself? (For example: dressing, bathing, eating)',
      questionName: 'aqol_1',
      choices: [
        { label: 'I need no help at all', value: '1' },
        {
          label: 'Occasionally I need some help with personal care tasks',
          value: '2'
        },
        {
          label: 'I need help with the more difficult personal care tasks',
          value: '3'
        },
        {
          label: 'I need daily help with most or all personal care tasks',
          value: '4'
        }
      ],
      ...sharedOptions
    },
    {
      questionText:
        'When doing household tasks: (For example: cooking, cleaning the house, washing)',
      questionName: 'aqol_2',
      choices: [
        { label: 'I need no help at all', value: '1' },
        {
          label: 'Occasionally I need some help with household tasks',
          value: '2'
        },
        {
          label: 'I need help with the more difficult household tasks',
          value: '3'
        },
        {
          label: 'I need daily help with most or all household tasks',
          value: '4'
        }
      ],
      ...sharedOptions
    },
    {
      questionText:
        'Thinking about how easily you can get around your home and community:',
      questionName: 'aqol_3',
      choices: [
        {
          label:
            'I get around my home and community by myself without any difficulty',
          value: '1'
        },
        {
          label:
            'I find if difficult to get around my home and community by myself',
          value: '2'
        },
        {
          label:
            'I cannot get around the community by myself, but I can get around my home with some difficulty',
          value: '3'
        },
        {
          label:
            'I cannot get around either the community or my home by myself',
          value: '4'
        }
      ],
      ...sharedOptions
    },
    {
      questionText:
        'Because of your health, your relationships (for example: with your friends, partner, or parents) generally:',
      questionName: 'aqol_4',
      choices: [
        { label: 'Are very close and warm', value: '1' },
        { label: 'Are sometimes close and warm', value: '2' },
        {
          label: 'Are seldom close and warm',
          value: '3'
        },
        { label: 'I have no close and warm relationships', value: '4' }
      ],
      ...sharedOptions
    },
    {
      questionText: 'Thinking about your relationship with other people:',
      questionName: 'aqol_5',
      choices: [
        { label: 'I have plenty of friends, and am never lonely', value: '1' },
        {
          label: 'Although I have friends, I am occasionally lonely',
          value: '2'
        },
        {
          label: 'I have  some friends, but am often lonely for company',
          value: '3'
        },
        { label: 'I am socially isolated and feel lonely', value: '4' }
      ],
      ...sharedOptions
    },
    {
      questionText:
        'Thinking about your health and your relationship with your family:',
      questionName: 'aqol_6',
      choices: [
        {
          label: 'My role in the family is unaffected by my health',
          value: '1'
        },
        {
          label: 'There are some parts of my family role I cannot carry out',
          value: '2'
        },
        {
          label: 'There are many parts of my family role I cannot carry out',
          value: '3'
        },
        { label: 'I cannot carry out any part of my family role', value: '4' }
      ],
      ...sharedOptions
    },
    {
      questionText:
        'Thinking about your vision, including when using your glasses or contact lenses if needed:',
      questionName: 'aqol_7',
      choices: [
        { label: 'I see normally', value: '1' },
        {
          label:
            'I have some difficulty focusing on things, or I do not see them sharply. ' +
            'For example, small print, a newspaper or seeing objects in the distance.',
          value: '2'
        },
        {
          label:
            'I have a lot of difficulty seeing things. My vision is blurred. ' +
            'For example: I can see just enough to get by with.',
          value: '3'
        },
        {
          label:
            'I only see general shapes, or am blind. For example: I need a guide to move around.',
          value: '4'
        }
      ],
      ...sharedOptions
    },
    {
      questionText:
        'Thinking about your hearing, including using your hearing aid if needed:',
      questionName: 'aqol_8',
      choices: [
        { label: 'I hear normally', value: '1' },
        {
          label:
            'I have some difficulty hearing or I do not hear clearly. For example: I ask people to speak up, or ' +
            'turn up the TV or radio volume.',
          value: '2'
        },
        {
          label:
            'I have difficulty hearing things clearly. For example: Often I do not understand what is said. ' +
            'I usually do not take part in conversations because I cannot hear what is said.',
          value: '3'
        },
        {
          label:
            'I hear very little indeed. For example: I cannot fully understand loud voices ' +
            'speaking directly to me',
          value: '4'
        }
      ],
      ...sharedOptions
    },
    {
      questionText:
        'When you communicate with others: (For example, by talking, listening, writing, or signing.)',
      questionName: 'aqol_9',
      choices: [
        {
          label:
            'I have no trouble speaking to them or understanding what they are saying',
          value: '1'
        },
        {
          label:
            'I have some difficulty being understood by people who do not know me.' +
            ' I have no trouble understanding what others are saying to me',
          value: '2'
        },
        {
          label:
            'I am only understood by people who know me well. I have great trouble understanding what' +
            ' others are saying to me.',
          value: '3'
        },
        { label: 'I cannot adequately communicate with others', value: '4' }
      ],
      ...sharedOptions
    },
    {
      questionText: 'Thinking about how you sleep',
      questionName: 'aqol_10',
      choices: [
        {
          label: 'I am able to sleep without difficulty most of the time',
          value: '1'
        },
        {
          label:
            'My sleep is interrupted some of the time, but I am usually able to go back to sleep without difficulty',
          value: '2'
        },
        {
          label:
            'My sleep is interrupted most nights, but I am usually able to go back to sleep without difficulty',
          value: '3'
        },
        {
          label: 'I sleep in short bursts only. I am awake most of the night.',
          value: '4'
        }
      ],
      ...sharedOptions
    },
    {
      questionText: 'Thinking about how you generally feel:',
      questionName: 'aqol_11',
      choices: [
        { label: 'I do not feel anxious, worried or depressed', value: '1' },
        { label: 'I am slightly anxious, worried or depressed', value: '2' },
        {
          label: 'I feel moderately anxious, worried or depressed',
          value: '3'
        },
        { label: 'I am extremely anxious, worried or depressed', value: '4' }
      ],
      ...sharedOptions
    },
    {
      questionText: 'How much pain or discomfort do you experience',
      questionName: 'aqol_12',
      choices: [
        { label: 'None at all', value: '1' },
        { label: 'I have moderate pain', value: '2' },
        {
          label: 'I suffer from severe pain',
          value: '3'
        },
        { label: 'I suffer unbearable pain', value: '4' }
      ],
      ...sharedOptions
    }
  ]
};

export default australianQualityOfLife;
