import React from 'react';
import PropTypes from 'prop-types';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  FormText,
  InputGroup,
  InputGroupText
} from 'reactstrap';

function getIcon(icon) {
  return icon ? (
    <div className="input-group-append">
      <InputGroupText>
        <span className={icon} />
      </InputGroupText>
    </div>
  ) : null;
}

// disabling rule due to simplicity of inline component
// which is in itself required due to the complexity rule
// eslint-disable-next-line react/no-multi-comp
const FormikReactStrapInput = ({
  field: { ...fields },
  form: { touched, errors },
  submitCount,
  icon,
  // remove the follow from ...props
  /* eslint-disable  */
  questionName,
  initialValue,
  /* eslint-enable  */
  ...props
}) => (
  <FormGroup>
    <Label for={props.id} className={'label-color'}>
      {props.label}
    </Label>
    <InputGroup>
      <Input
        {...props}
        {...fields}
        invalid={Boolean(touched[fields.name] && errors[fields.name])}
      />
      {getIcon(icon)}
    </InputGroup>
    {props.helptext ? (
      <FormText id="{props.aria-describedby}">{props.helptext}</FormText>
    ) : (
      ''
    )}
    {touched[fields.name] && errors[fields.name] ? (
      <FormFeedback className="d-block">{errors[fields.name]}</FormFeedback>
    ) : (
      ''
    )}
  </FormGroup>
);

FormikReactStrapInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  submitCount: PropTypes.number,
  helptext: PropTypes.string,
  icon: PropTypes.string
};

export { FormikReactStrapInput };
