import React, { useEffect } from 'react';

const NotFound = () => {
  useEffect(() => {
    document.title = `Wellbeing Assist - Page Not Found`;
  });
  return <h1 className="text-center">404</h1>;
};

export default NotFound;
