import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '../Resource';

const Blocks = ({ blocks }) => (
  <React.Fragment>
    {blocks.edges.map(
      ({
        node: { id, heading, paragraph, headingColor, icon, alwaysOpen }
      }) => (
        <Block
          heading={heading}
          paragraph={paragraph}
          key={id}
          headingColor={headingColor}
          icon={icon}
          alwaysOpen={alwaysOpen}
        />
      )
    )}
  </React.Fragment>
);

Blocks.propTypes = {
  blocks: PropTypes.object
};

export { Blocks };
