/**
 * @flow
 * @relayHash 818bc7cecaadece83b67c3a6a2eeb465
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DashboardLineChart_data$ref = any;
type SubFocusBarChart_data$ref = any;
export type SubFocusQueryVariables = {|
  lookback: number,
  sitePk: number,
|};
export type SubFocusQueryResponse = {|
  +dashboardWellbeing: ?{|
    +surveyDatetime: ?string,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +label: ?string,
        +status: ?string,
        +mean: ?number,
      |}
    |}>,
  |},
  +$fragmentRefs: DashboardLineChart_data$ref & SubFocusBarChart_data$ref,
|};
export type SubFocusQuery = {|
  variables: SubFocusQueryVariables,
  response: SubFocusQueryResponse,
|};
*/


/*
query SubFocusQuery(
  $lookback: Int!
  $sitePk: Int!
) {
  ...DashboardLineChart_data
  ...SubFocusBarChart_data
  dashboardWellbeing(lookback: $lookback, sitePk: $sitePk) {
    surveyDatetime
    edges {
      node {
        id
        label
        status
        mean
      }
    }
  }
}

fragment DashboardLineChart_data on RelayQuery {
  dashboardOverview(sitePk: $sitePk) {
    edges {
      node {
        id
        topic
        fortnight
        score
      }
    }
  }
}

fragment SubFocusBarChart_data on RelayQuery {
  subfocusOverview(sitePk: $sitePk) {
    edges {
      node {
        topic
        questionText
        rawCount
        percentage
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "lookback",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sitePk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "sitePk",
  "variableName": "sitePk"
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dashboardWellbeing",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "lookback",
      "variableName": "lookback"
    },
    (v1/*: any*/)
  ],
  "concreteType": "WellbeingScoreConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "surveyDatetime",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "WellbeingScoreEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "WellbeingScore",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "label",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "mean",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "topic",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SubFocusQuery",
    "type": "RelayQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v3/*: any*/),
      {
        "kind": "FragmentSpread",
        "name": "DashboardLineChart_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "SubFocusBarChart_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SubFocusQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dashboardOverview",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "DashGraphPointConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DashGraphPointEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DashGraphPoint",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fortnight",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "score",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subfocusOverview",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "FocusBarPointConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "FocusBarPointEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "FocusBarPoint",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "questionText",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "rawCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "percentage",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SubFocusQuery",
    "id": null,
    "text": "query SubFocusQuery(\n  $lookback: Int!\n  $sitePk: Int!\n) {\n  ...DashboardLineChart_data\n  ...SubFocusBarChart_data\n  dashboardWellbeing(lookback: $lookback, sitePk: $sitePk) {\n    surveyDatetime\n    edges {\n      node {\n        id\n        label\n        status\n        mean\n      }\n    }\n  }\n}\n\nfragment DashboardLineChart_data on RelayQuery {\n  dashboardOverview(sitePk: $sitePk) {\n    edges {\n      node {\n        id\n        topic\n        fortnight\n        score\n      }\n    }\n  }\n}\n\nfragment SubFocusBarChart_data on RelayQuery {\n  subfocusOverview(sitePk: $sitePk) {\n    edges {\n      node {\n        topic\n        questionText\n        rawCount\n        percentage\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b51cf28fad1b07690418600aba5443b';
module.exports = node;
