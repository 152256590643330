import React from 'react';
import PropTypes from 'prop-types';
import { Flipped } from 'react-flip-toolkit';
import { LikeIconButton } from './LikeIconButton';
import { Link } from 'react-router-dom';

const ResourceCard = ({ title, subtitle, slug, liked, id, onLikeToggle }) => (
  <Flipped key={slug} flipId={slug}>
    <div className="col-12 col-md-6 col-lg-4" style={{ marginBottom: '1em' }}>
      <div className="resource-card">
        {/* wrap entire card in a link */}
        <Link
          to={`/resource/${slug}`}
          style={{
            display: 'block',
            marginLeft: '0px'
          }}
        >
          <div className="resource-card-title">
            <b>{title}</b>
            <div
              style={{ position: 'absolute', right: '1.75em', top: '0.25em' }}
            >
              <LikeIconButton
                liked={liked}
                id={id}
                onLikeToggle={onLikeToggle}
              />
            </div>
          </div>
        </Link>
        <div>
          <Link to={`/resource/${slug}`}>
            <div
              style={{
                backgroundImage: `url(/resources/${slug}.jpg)`
              }}
              className="resource-card-thumbnail"
            />
          </Link>
        </div>
      </div>
    </div>
  </Flipped>
);

ResourceCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  slug: PropTypes.string,
  liked: PropTypes.bool,
  id: PropTypes.string,
  onLikeToggle: PropTypes.func
};

export default ResourceCard;
