import { Likert } from '../components/form/Likert';
import { string } from 'yup';

const marks = {
  1: 'Never',
  2: 'Rarely',
  3: 'Once in a while',
  4: 'Some of the time',
  5: 'Monthly',
  6: 'Weekly',
  7: 'Almost daily'
};

const pawSurveyBullyingModuleLikertOptions = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks,
  max: 7,
  tooltip: false
};

const pawSurveyBullyingModule = {
  heading: 'People at Work Survey - Bullying subscale',
  questions: [
    {
      questionText:
        'Workplace bullying is repeated, unreasonable behaviour directed towards a worker or a group of workers that' +
        ' creates a risk to health and safety.' +
        ' Over the past 6 months, how often do you think bullying has occurred in your workplace?',
      questionName: 'bullying_module_1',
      ...pawSurveyBullyingModuleLikertOptions
    },
    {
      questionText:
        'Over the past 6 months, how often do you think violence has occurred in your workplace?' +
        'Please note: Violence could include hitting, kicking, grabbing, shoving, biting, objects being thrown at ' +
        'people, being threatened with a weapon, shouted at, sworn at, called names, or verbally confronted.',
      questionName: 'bullying_module_2',
      ...pawSurveyBullyingModuleLikertOptions
    }
  ]
};

export default pawSurveyBullyingModule;
