import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import ReactstrapRadio from './FormikReactStrapRadio';
import { Field } from 'formik';

const style = {
  maxWidth: '100%'
};

const RadioChoice = ({
  questionName,
  errors,
  submitCount,
  choices,
  ...props
}) => (
  <React.Fragment>
    {choices.map(({ label, value }) => (
      <Field
        key={value}
        type="radio"
        className="radioQuestion"
        name={questionName}
        label={label}
        value={value}
        component={ReactstrapRadio}
        style={style}
        {...props}
      />
    ))}
    <div>
      {submitCount > 0 && errors && errors[questionName] ? (
        <FormFeedback className="d-block">{errors[questionName]}</FormFeedback>
      ) : (
        ''
      )}
    </div>
  </React.Fragment>
);

RadioChoice.propTypes = {
  questionName: PropTypes.string,
  errors: PropTypes.object,
  submitCount: PropTypes.number,
  choices: PropTypes.array
};

export { RadioChoice };
