import React from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import environment from '../../relayEnvironment';
import graphql from 'babel-plugin-relay/macro';
import { PropTypes } from 'prop-types';
import { QueryRenderer } from 'react-relay';

const query = graphql`
  query ScheduleQuery {
    prepostSchedule {
      name
      start
      expires
      frontlineWorker
      manager
      forms
    }
  }
`;

const Schedule = () => (
  <div className="mt-5">
    <h2>Schedule</h2>
    <Row>
      <QueryRenderer
        environment={environment}
        query={query}
        render={({ props }) =>
          props
            ? props.prepostSchedule.map(sched => (
                <Col xs="12" sm="6" md="4" key={sched.name}>
                  <Card className="border mb-3">
                    <CardBody>
                      <CardTitle
                        tag="h4"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {sched.name}
                      </CardTitle>
                      <CardSubtitle tag="h6" className="mt-3">
                        {sched.manager ? (
                          <p>
                            <i className="ion-md-checkbox-outline text-primary lead" />{' '}
                            Includes managers
                          </p>
                        ) : (
                          <p>
                            <i className="ion-md-square-outline text-secondary lead" />{' '}
                            Not for managers{' '}
                          </p>
                        )}
                      </CardSubtitle>
                      <CardSubtitle tag="h6" className="mt-3">
                        {sched.frontlineWorker ? (
                          <p>
                            <i className="ion-md-checkbox-outline text-primary lead" />{' '}
                            Includes frontline workers
                          </p>
                        ) : (
                          <p>
                            <i className="ion-md-square-outline text-secondary lead" />{' '}
                            Not for frontline workers
                          </p>
                        )}
                      </CardSubtitle>
                      <p>Starts: {sched.start}</p>
                      <p>Ends: {sched.expires}</p>
                      <ul>
                        {sched.forms.map(item => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              ))
            : null
        }
      />
    </Row>
  </div>
);

Schedule.propTypes = {
  prepostSchedule: PropTypes.object
};

export default Schedule;
