import React from 'react';
import PropTypes from 'prop-types';
import '../assets/css/Resources.scss';
import ResourceCard from '../components/ResourceCard';
import environment from '../relayEnvironment';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import rand from 'random-seed';

// hard coded "always show this if the person is sad" resource slug
export const LOW_SLUG = 'help-seeking';

const getRandomResources = (available, mean) => {
  const used = [];
  // add hardcoded resource first if mean is in the low range
  if (mean < 40) {
    const ind = available.findIndex(res => res.node.slug === LOW_SLUG);
    used.push(...available.splice(ind, 1));
  }
  // add random resources from the list until it's filled out
  const randomGenerator = rand.create(sessionStorage.getItem('jwt'));
  while (used.length < 3 && available.length) {
    used.push(...available.splice(randomGenerator.range(available.length), 1));
  }
  return used;
};

const MappedResourcesInner = ({ props: innerProps, mean }) => {
  if (innerProps === null) return null;
  const available = innerProps.resources.edges.slice();

  const used = getRandomResources(available, mean);
  return (
    <>
      <div className="boxitem-title blue">
        <h2>Recommended Resources</h2>
      </div>
      <div className="boxitem-content">
        {mean === 0 ? (
          <div>
            We don't have enough information to accurately recommend helpful
            resources for you. Please check back later.
          </div>
        ) : (
          <>
            <Row>
              <Col sm="12">
                <p>
                  Based on your results, we recommend you take a look at these
                  resources.
                </p>
              </Col>{' '}
            </Row>
            <Row>
              {used.map(({ node: { title, subtitle, slug, liked, id } }) => (
                <ResourceCard
                  key={slug}
                  title={title}
                  subtitle={subtitle}
                  liked={liked}
                  slug={slug}
                  id={id}
                />
              ))}
            </Row>
          </>
        )}
        <Row>
          {' '}
          <Col sm="12">
            <p>
              You can also access our full{' '}
              <Link to="/resources">Resource Library</Link> for more support.
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

MappedResourcesInner.propTypes = {
  props: PropTypes.shape({
    resources: PropTypes.object
  }),
  mean: PropTypes.number
};

const query = graphql`
  query MappedResourcesQuery(
    $mappingLow: Boolean
    $mappingMedium: Boolean
    $mappingHigh: Boolean
  ) {
    resources(
      mappingLow: $mappingLow
      mappingMedium: $mappingMedium
      mappingHigh: $mappingHigh
      pageType: "GENERAL"
    ) {
      edges {
        node {
          slug
          title
          subtitle
          liked
          id
        }
      }
    }
  }
`;

// generate the bools used in the query
function getMappings(mean) {
  // set them to undefined rather than so we don't exclude anything with >1 mapping set in the query
  let mappingLow = undefined;
  let mappingMedium = undefined;
  let mappingHigh = undefined;

  if (mean > 80) {
    mappingHigh = true;
  } else if (mean < 40) {
    mappingLow = true;
  } else {
    mappingMedium = true;
  }
  return { mappingLow, mappingMedium, mappingHigh };
}

export const MappedResources = ({ mean }) => (
  <QueryRenderer
    environment={environment}
    query={query}
    variables={{
      ...getMappings(mean)
    }}
    render={props => <MappedResourcesInner mean={mean} {...props} />}
  />
);

MappedResources.propTypes = {
  mean: PropTypes.number
};
