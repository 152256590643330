import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from './ExternalLink';

const Eap = ({ organisation }) => {
  switch (organisation) {
    case 'colac':
      return (
        <div className="eap-details">
          Your Employee Assistance Program is available at:{' '}
          <ExternalLink href="http://swarh2/cah/content/human-resources">
            http://swarh2/cah/content/human-resources
          </ExternalLink>{' '}
          (Colac Intranet only)
        </div>
      );
    case 'bhs':
      return (
        <div className="eap-details">
          Your Employee Assistance Program details is available at:{' '}
          <ExternalLink href="https://www.convergeinternational.com.au/cvi/eap-critical-response/eap">
            Converge International
          </ExternalLink>
        </div>
      );
    default:
      return null;
  }
};

Eap.propTypes = {
  organisation: PropTypes.string
};
export default Eap;
