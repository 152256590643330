import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Menu from './Menu';
import Footer from './Footer';
import PageHeader from './PageHeader';
/**
 * Public route
 *
 * Extends react-router-dom.Route to include header and footer content.
 * Provides layout for exclusively non-logged in pages
 */
const PublicRoute = ({ component: Component, header, ...rest }) => {
  const renderContent = () => (
    <React.Fragment>
      <Menu path={rest.path} isAuthenticated={false} />
      <PageHeader {...header} />
      <Route {...rest} component={Component} />
      <Footer />
    </React.Fragment>
  );

  return renderContent();
};

PublicRoute.propTypes = {
  component: PropTypes.elementType,
  header: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  })
};

export default PublicRoute;
