import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../relayEnvironment';
import { commitMutation } from 'react-relay';
import AssessmentForm from '../components/AssessmentForm';
import k10 from '../surveys/k10';
import { nextPage } from '../__utils__/nextPage';

const mutation = graphql`
  mutation KTenMutation($input: PrePostMutationInput!) {
    prepostMutation(input: $input) {
      nextForm
    }
  }
`;

const KTen = () => {
  const [formResponse, setFormResponse] = useState({});

  const onSubmit = (values, { setSubmitting }) => {
    const responses = Object.entries(values).map(([key, value]) => ({
      question: key,
      answer: value
    }));

    const variables = {
      input: { formName: 'K10Form', responses: responses }
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: response => {
        setSubmitting(false);
        sessionStorage.setItem('nextForm', response.prepostMutation.nextForm);
        nextPage();
      },
      onError: ({ error }) => {
        setFormResponse({ error });
      }
    });
  };

  return (
    <AssessmentForm
      onSubmit={onSubmit}
      formResponse={formResponse}
      questions={k10.questions}
      instruction={k10.instruction}
      heading={k10.heading}
    />
  );
};

export default KTen;
