import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { object as yup } from 'yup';
import {
  Container,
  FormGroup,
  Button,
  Spinner,
  Alert,
  FormFeedback
} from 'reactstrap';
import PageHeader from './PageHeader';
import { useLocation } from 'react-router-dom';
import '../assets/css/Forms.scss';
import ErrorScroll from './form/ErrorScroll';

const AssessmentForm = ({
  onSubmit,
  heading,
  instruction,
  questions,
  formResponse
}) => {
  const schemaItems = questions
    .filter(question => question.validation)
    .reduce(
      (object, { questionName, validation }) => ({
        [questionName]: validation,
        ...object
      }),
      {}
    );

  const schema = yup().shape(schemaItems);

  const initialValues = questions
    .filter(question => 'initialValue' in question)
    .reduce(
      (object, { questionName, initialValue }) => ({
        [questionName]: initialValue,
        ...object
      }),
      {}
    );

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeader title={heading} />
      <Container>
        <div className="boxitem-container no-top">
          {useLocation().pathname !== '/survey' && (
            <div className="boxitem-content no-top">
              <FormFeedback className="d-block">
                Please complete this assessment to continue
              </FormFeedback>
            </div>
          )}
          {instruction && (
            <div className="boxitem-content">
              <p className="instruction-text">{instruction}</p>
            </div>
          )}
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setStatus }, errors) =>
              onSubmit(values, { setSubmitting, setStatus }, errors)
            }
          >
            {({ isSubmitting, errors, submitCount }) => (
              <Form>
                {questions.map(
                  (
                    { Component, questionText, questionNumber, ...props },
                    index
                  ) => {
                    // if no component is provided, don't render anything
                    if (!Component) return null;
                    return (
                      <ErrorScroll
                        {...{
                          errors,
                          isSubmitting,
                          questionName: props.questionName
                        }}
                        key={props.questionName}
                      >
                        <FormGroup>
                          <div className="boxitem-title blue">
                            <h3>
                              <span className="badge badge-secondary">
                                {/* if questionNumber is provided, use that, otherwise 
                              use the index (1-based) 
                              This is because RadioChoiceOther components mess up the schema by 
                              adding extra fields with Component=False
                              */}
                                {questionNumber || index + 1}
                              </span>
                              <span className="questionText">
                                {questionText}
                              </span>
                            </h3>
                          </div>
                          <div className="boxitem-content boxitem-form">
                            <Component
                              errors={errors}
                              submitCount={submitCount}
                              {...props}
                            />
                          </div>
                        </FormGroup>
                      </ErrorScroll>
                    );
                  }
                )}
                <div className="text-center">
                  <Button
                    size="lg"
                    type="submit"
                    data-testid="submitButton"
                    disabled={isSubmitting}
                    className="btn btn-primary mb-3"
                  >
                    {isSubmitting ? 'SUBMITTING' : 'SUBMIT'}
                    {isSubmitting && (
                      <Spinner size="lg" className={'ml-3'} color="success" />
                    )}
                  </Button>
                  {formResponse && formResponse.msg && (
                    <Alert className={'mt-3'} color={formResponse.color}>
                      {formResponse.msg}
                    </Alert>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
};

AssessmentForm.propTypes = {
  questions: PropTypes.array,
  heading: PropTypes.string,
  instruction: PropTypes.string,
  onSubmit: PropTypes.func,
  formResponse: PropTypes.object,
  questionName: PropTypes.string
};

export default AssessmentForm;
