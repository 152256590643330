import React from 'react';
import { Field } from 'formik';
import { FormikReactStrapPasswordInput } from './FormikReactStrapPasswordInput';

const CreatePasswordInput = () => (
  <Field
    label="Password"
    name="password"
    id="password"
    type="password"
    component={FormikReactStrapPasswordInput}
  />
);

export { CreatePasswordInput };
