import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../assets/css/PrivacyPolicy.css';

const PrivacyPolicy = () => (
  <section>
    <Container>
      <Row>
        <Col size={12}>
          <div className="boxitem-container no-top ">
            <div className="boxitem-title blue">
              <h2>Data Privacy</h2>
            </div>
            <div className="boxitem-content no-top">
              <p>
                What you need to know about data privacy when using Workplace
                Wellbeing Assist (WWA):
              </p>
              <ul>
                <li>
                  Your WWA survey responses are anonymous, and your managers and
                  organisation cannot identify you or your survey responses in
                  any way.
                </li>

                <li>
                  Your WWA personal dashboard results are confidential and can
                  only be accessed by you.
                </li>

                <li>
                  The information displayed on the organisation managers’
                  dashboard only shows the ‘aggregate’ or ‘group-level’ results
                  of all staff completing the survey at your workplace.
                </li>

                <li>
                  At least five staff members must complete the survey before
                  any results are displayed on the manager dashboard so managers
                  cannot tell who completed the survey and what ratings any
                  particular staff provided.
                </li>

                <li>
                  When the research team analyses and reports on the data from
                  the evaluation of the WWA, any identifying information is
                  completely removed, and results cannot be linked back to you
                  in any way.
                </li>

                <li>
                  The research team employ professional and industry standards
                  for the security and protection of all the information
                  collected through the WWA.
                </li>
              </ul>
              <p>
                For more information, or to request another copy of the Plain
                Language Information Statement you were provided at the start of
                the project that explains data privacy issues in more detail,
                please email: beri@federation.edu.au
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default PrivacyPolicy;
