import { Likert } from '../components/form/Likert';
import { string } from 'yup';

const marks = {
  1: 'Strongly Disagree',
  2: 'Disagree',
  3: 'Neither Agree or Disagree',
  4: 'Agree',
  5: 'Strongly Agree'
};

const psychologicalClimateLikertOptions = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks,
  max: 5,
  tooltip: false
};

const psychologicalClimate = {
  heading: 'Psychosocial Safety Climate Scale',
  instruction:
    'The following statements concern the psychological health and safety in your workplace. ' +
    'Please answer with the best option provided.',
  questions: [
    {
      questionText:
        'In my workplace, Senior Management acts quickly to correct problems/issues that' +
        " affect employees' psychological health.",
      questionName: 'psychological_climate_1',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        "Senior Management acts decisively when a concern of an employees' psychological status is raised.",
      questionName: 'psychological_climate_2',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'Senior Management show support for stress prevention through involvement and commitment.',
      questionName: 'psychological_climate_3',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'Psychological well-being of staff is a priority for this organisation.',
      questionName: 'psychological_climate_4',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'Senior Management clearly considers the psychological health of employees to be of great importance.',
      questionName: 'psychological_climate_5',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'Senior Management considers employee psychological health to be as important as productivity.',
      questionName: 'psychological_climate_6',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'There is good communication here about psychological safety issues which effect me.',
      questionName: 'psychological_climate_7',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'Information about workplace psychological well-being is always brought to my attention by my' +
        ' manager/supervisor.',
      questionName: 'psychological_climate_8',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'My contributions to resolving occupational health and safety concerns in the organisation are' +
        ' listened to.',
      questionName: 'psychological_climate_9',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        "Participation and consultation in psychological health and safety occurs with employees', unions," +
        ' and health and safety representatives in my workplace.',
      questionName: 'psychological_climate_10',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'Employees are encouraged to become involved in psychological safety and health matters.',
      questionName: 'psychological_climate_11',
      ...psychologicalClimateLikertOptions
    },
    {
      questionText:
        'In my organisation, the prevention of stress involves all levels of the organisation.',
      questionName: 'psychological_climate_12',
      ...psychologicalClimateLikertOptions
    }
  ]
};

export default psychologicalClimate;
