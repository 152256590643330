import React from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import moment from 'moment';
import { createFragmentContainer } from 'react-relay';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const ActivitySummaryList = ({
  setUserFilter,
  setActivityTypeFilter,
  list
}) => {
  if (!list) {
    return null;
  }
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">User ID</th>
          <th scope="col">Activity Type</th>
          <th scope="col">Time</th>
          <th scope="col">Data</th>
        </tr>
      </thead>
      <tbody>
        {list.activitySummary.edges.map(node => {
          const activity = node.node;
          return (
            <tr key={activity.time}>
              <td onClick={() => setUserFilter(activity.user)}>
                {activity.user}
              </td>
              <td
                title={activity.activityDesc}
                onClick={() => setActivityTypeFilter([activity.activityType])}
              >
                {activity.activityName}
              </td>
              <td>{new Date(activity.time).toLocaleString()}</td>
              <td title={activity.dataDesc}>
                {activity.activityType === 'login'
                  ? `${Math.floor(
                      moment.duration(activity.data * 1000).asMinutes()
                    )} minutes`
                  : activity.data}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

ActivitySummaryList.propTypes = {
  list: PropTypes.object,
  setUserFilter: PropTypes.func,
  setActivityTypeFilter: PropTypes.func
};

export default createFragmentContainer(withRouter(ActivitySummaryList), {
  list: graphql`
    fragment ActivitySummaryList_list on RelayQuery {
      activitySummary(
        first: $first
        user: $user
        activityType_In: $activityType_In
      ) {
        edges {
          node {
            user
            activityType
            data
            activityName
            activityDesc
            dataDesc
            time
          }
        }
      }
    }
  `
});
