/**
 * @flow
 * @relayHash 8ea9f2358d00612a90d928dd3ba11c7f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactUsMutationInput = {|
  name: string,
  email: string,
  message: string,
  clientMutationId?: ?string,
|};
export type ContactUsMutationVariables = {|
  input: ContactUsMutationInput
|};
export type ContactUsMutationResponse = {|
  +contactUsMutation: ?{|
    +id: ?string
  |}
|};
export type ContactUsMutation = {|
  variables: ContactUsMutationVariables,
  response: ContactUsMutationResponse,
|};
*/


/*
mutation ContactUsMutation(
  $input: ContactUsMutationInput!
) {
  contactUsMutation(input: $input) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ContactUsMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "contactUsMutation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ContactUsMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContactUsMutation",
    "type": "RelayMutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactUsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ContactUsMutation",
    "id": null,
    "text": "mutation ContactUsMutation(\n  $input: ContactUsMutationInput!\n) {\n  contactUsMutation(input: $input) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57aac00e953837273de72157dcb711ff';
module.exports = node;
