/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActivitySummaryList_list$ref: FragmentReference;
declare export opaque type ActivitySummaryList_list$fragmentType: ActivitySummaryList_list$ref;
export type ActivitySummaryList_list = {|
  +activitySummary: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +user: ?number,
        +activityType: string,
        +data: ?string,
        +activityName: ?string,
        +activityDesc: ?string,
        +dataDesc: ?string,
        +time: any,
      |}
    |}>
  |},
  +$refType: ActivitySummaryList_list$ref,
|};
export type ActivitySummaryList_list$data = ActivitySummaryList_list;
export type ActivitySummaryList_list$key = {
  +$data?: ActivitySummaryList_list$data,
  +$fragmentRefs: ActivitySummaryList_list$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ActivitySummaryList_list",
  "type": "RelayQuery",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "user",
      "type": "Float"
    },
    {
      "kind": "RootArgument",
      "name": "activityType_In",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "activitySummary",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "activityType_In",
          "variableName": "activityType_In"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "user",
          "variableName": "user"
        }
      ],
      "concreteType": "ActivitySummaryNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ActivitySummaryNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ActivitySummaryNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "user",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "activityType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "data",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "activityName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "activityDesc",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dataDesc",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "time",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd0017f09daf9415ae02e3f655c18a645';
module.exports = node;
