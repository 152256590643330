import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from 'react-relay';
import environment from '../relayEnvironment';
import { Container } from 'reactstrap';
import { Blocks } from '../components/Resource';
import PageHeader from '../components/PageHeader';
import VideoModal from '../components/VideoModal';
import { LikeIconButton } from '../components/LikeIconButton';

const query = graphql`
  query ResourceViewQuery($slug: String!) {
    resourcePageBySlug(slug: $slug) {
      title
      subtitle
      pageType
      videoUrl
      liked
      id
      blocks {
        edges {
          node {
            id
            heading
            headingColor
            icon
            alwaysOpen
            paragraph
          }
        }
      }
    }
  }
`;

const ResourceView = ({ match }) => {
  const { slug } = match.params;
  const variables = { slug: slug };
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={({ props }) => {
        if (!props) {
          return <PageHeader title=" " subtitle="Loading" />;
        }

        const {
          title,
          pageType,
          blocks,
          videoUrl,
          liked,
          id
        } = props.resourcePageBySlug;
        const subtitle =
          pageType === 'GENERAL'
            ? 'Personal Wellbeing Resource'
            : 'Workplace Resource';
        return (
          <>
            <PageHeader title={title} subtitle={subtitle} />
            <Container>
              {/* Resource header */}
              <div className="resource-header-container">
                <img
                  className="resource-header-image"
                  alt={title}
                  src={`/resources/${slug}.jpg`}
                />
                {videoUrl && (
                  <VideoModal
                    link={videoUrl}
                    className="resource-header-video-button btn btn-primary"
                  >
                    WATCH VIDEO
                  </VideoModal>
                )}
                <div className="resource-header-liked">
                  <LikeIconButton slug={slug} liked={liked} id={id} button />
                </div>
              </div>
              {/* List of blocks */}
              <Blocks blocks={blocks} />
            </Container>
          </>
        );
      }}
    />
  );
};

ResourceView.propTypes = {
  match: PropTypes.object,
  slug: PropTypes.string,
  resourcePageBySlug: PropTypes.object
};

export default ResourceView;
