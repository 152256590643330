import { Likert } from '../components/form/Likert';
import { Input } from '../components/FormInputs';
import { string } from 'yup';

const marks1 = {
  1: 'Excellent',
  2: 'Good',
  3: 'Fair',
  4: 'Poor'
};

const marks2 = {
  1: 'Yes, definitely',
  2: 'Yes, generally',
  3: 'No, not really',
  4: 'No, definitely not'
};

const marks3 = {
  1: 'Very satisfied',
  2: 'Satisfied',
  3: 'Neutral',
  4: 'Dissatisfied',
  5: 'Very dissatisfied'
};

const interventionSatisfactionLikertOptions1 = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks1,
  max: 4,
  tooltip: false
};

const interventionSatisfactionLikertOptions2 = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks2,
  max: 4,
  tooltip: false
};

const interventionSatisfactionLikertOptions3 = {
  validation: string().required('Please fill in the question above.'),
  Component: Likert,
  marks: marks3,
  max: 5,
  tooltip: false
};

const interventionSatisfaction = {
  heading: 'Intervention Satisfaction Questionnaire',
  questions: [
    {
      questionText:
        'How would you rate the quality of Workplace Wellbeing Assist?',
      questionName: 'intervention_satisfaction_1',
      ...interventionSatisfactionLikertOptions1
    },
    {
      questionText:
        'To what extent has Workplace Wellbeing Assist met your needs?',
      questionName: 'intervention_satisfaction_2',
      validation: string().required('Please fill in the question above.'),
      Component: Likert,
      marks: {
        1: 'Almost all of my needs have been met',
        2: 'Most of my needs have been met',
        3: 'Only a few of my needs have been met',
        4: 'None of my needs have been met'
      },
      max: 4,
      tooltip: false
    },
    {
      questionText: 'How would you rate the quality of the resources?',
      questionName: 'intervention_satisfaction_3',
      ...interventionSatisfactionLikertOptions1
    },
    {
      questionText: 'Did the resources contain useful information/strategies?',
      questionName: 'intervention_satisfaction_4',
      ...interventionSatisfactionLikertOptions2
    },
    {
      questionText:
        'How satisfied are you with the amount of information you received?',
      questionName: 'intervention_satisfaction_5',
      ...interventionSatisfactionLikertOptions3
    },
    {
      questionText:
        'Do you think Workplace Wellbeing Assist could be permanently implemented into the residential' +
        ' aged care sector?',
      questionName: 'intervention_satisfaction_6',
      ...interventionSatisfactionLikertOptions2
    },
    {
      questionText:
        'Was it useful to monitor your mental wellbeing over time by using Workplace Wellbeing Assist?',
      questionName: 'intervention_satisfaction_7',
      ...interventionSatisfactionLikertOptions2
    },
    {
      questionText:
        'Has Workplace Wellbeing Assist helped you to deal more effectively with your mental wellbeing?',
      questionName: 'intervention_satisfaction_8',
      validation: string().required('Please fill in the question above.'),
      Component: Likert,
      marks: {
        1: 'Yes, they helped a great deal',
        2: 'Yes, they helped somewhat',
        3: "No, they really didn't help",
        4: 'No, they seemed to make things worse'
      },
      max: 4,
      tooltip: false
    },
    {
      questionText:
        'Overall, how satisfied are you with Workplace Wellbeing Assist?',
      questionName: 'intervention_satisfaction_9',
      ...interventionSatisfactionLikertOptions3
    },
    {
      questionText:
        'Did Workplace Wellbeing Assist help you to identify and report problems to management?',
      questionName: 'intervention_satisfaction_10',
      ...interventionSatisfactionLikertOptions2
    },
    {
      questionText:
        'Do you think your workplace culture improved while using Workplace Wellbeing Assist?',
      questionName: 'intervention_satisfaction_11',
      ...interventionSatisfactionLikertOptions2
    },
    {
      questionText:
        'What were the best parts of the Workplace Wellbeing Assist program?',
      validation: string().required('Please fill in the question above.'),
      Component: Input,
      id: 'intervention_satisfaction_12',
      questionName: 'intervention_satisfaction_12',
      type: 'text',
      initialValue: ''
    },
    {
      questionText:
        'What were the worst parts of the Workplace Wellbeing Assist program?',
      validation: string().required('Please fill in the question above.'),
      Component: Input,
      id: 'intervention_satisfaction_13',
      questionName: 'intervention_satisfaction_13',
      type: 'text',
      initialValue: ''
    },
    {
      questionText:
        'What were your expectations of Workplace Wellbeing Assist?',
      validation: string().required('Please fill in the question above.'),
      Component: Input,
      id: 'intervention_satisfaction_14',
      questionName: 'intervention_satisfaction_14',
      type: 'text',
      initialValue: ''
    },
    {
      questionText:
        'Did you complete the survey on a regular basis (approx. weekly?). If not, what got in the way of' +
        ' you doing so?',
      validation: string().required('Please fill in the question above.'),
      Component: Input,
      id: 'intervention_satisfaction_15',
      questionName: 'intervention_satisfaction_15',
      type: 'text',
      initialValue: ''
    },
    {
      questionText:
        'How credible was the content of the Workplace Wellbeing Assist program?',
      validation: string().required('Please fill in the question above.'),
      Component: Input,
      id: 'intervention_satisfaction_16',
      questionName: 'intervention_satisfaction_16',
      type: 'text',
      initialValue: ''
    },
    {
      questionText:
        'Did you feel as though you have gained enough knowledge on ways to support or improve your mental' +
        ' wellbeing from the resources in Workplace Wellbeing Assist?',
      validation: string().required('Please fill in the question above.'),
      Component: Input,
      id: 'intervention_satisfaction_17',
      questionName: 'intervention_satisfaction_17',
      type: 'text',
      initialValue: ''
    },
    {
      questionText: 'How could we improve Workplace Wellbeing Assist?',
      validation: string().required('Please fill in the question above.'),
      Component: Input,
      id: 'intervention_satisfaction_18',
      questionName: 'intervention_satisfaction_18',
      type: 'text',
      initialValue: ''
    }
  ]
};

export default interventionSatisfaction;
