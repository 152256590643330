import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

const radioStyle = {
  width: '100%',
  maxWidth: '100%',
  paddingLeft: '0px'
};

const ReactstrapRadio = ({
  field,
  form: { setFieldValue, values },
  disabled = false,
  ...props
}) => (
  <FormGroup check style={radioStyle}>
    <Label for={props.id} className="radio-item">
      <Input
        {...props}
        type="radio"
        name={field.name}
        checked={values[field.name] === field.value}
        value={field.value}
        onChange={() => setFieldValue(field.name, field.value)}
      />
      {props.label}
      <span className="selected-indicator" />
    </Label>
  </FormGroup>
);

ReactstrapRadio.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string
};

export default ReactstrapRadio;
