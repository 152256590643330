import React from 'react';
import { Container } from 'reactstrap';
import AddUser from '../components/admin/AddUser';
import SiteTable from '../components/admin/SiteTable';
import Schedule from '../components/admin/Schedule';

const AdminPage = () => (
  <section>
    <Container>
      <AddUser />
      <h1 className="mt-5">Participant summary</h1>
      <SiteTable />
      <Schedule />
    </Container>
  </section>
);

export default AdminPage;
