import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardText, CardBody } from 'reactstrap';
import gauge from '../assets/images/gauge.png';
import pointer from '../assets/images/pointer.png';
import gaugeface from '../assets/images/gauge-face.png';
import '../assets/css/TopicCard.scss';

const TopicCard = ({
  cardTitleText,
  cardTextString,
  cardValue,
  indicatorColor
}) => {
  const [rotation, setRotation] = useState({ transform: 'rotateZ(0deg)' });
  /* The High/Medium/Low values and gauge are not linear.
       For instance, the low-to-high threshhold on the
       supplied design gauge is at 57 degrees from horizonal.
       So we use a quadratic to force the values to cross at
       the right points. Go here to preview the quadratic used:
       https://www.google.com/search?q=y%3D0-(x%5E2%2F200)%2B
       (12x%2F5)-31&oq=y%3D0-(x%5E2%2F200)%2B(12x%2F5)-31 */
  const transform =
    'rotateZ(' +
    (0 - (cardValue * cardValue) / 200 + (12 * cardValue) / 5 - 31).toString() +
    'deg)';

  useEffect(() => {
    if (cardValue === 0) return;
    // change the rotateZ after first render
    setRotation({ transform });
  }, [cardValue, transform]);

  const link = 'sub-focus/' + cardTitleText.toLowerCase();
  const showButton =
    ['', 'High', 'Moderate', 'Low'].indexOf(cardTitleText) === -1;
  const showPadding = showButton || cardTitleText !== '';
  return (
    <div>
      <Card className="TopicCard">
        <CardBody>
          <CardText> {cardTextString} </CardText>
          <div
            className={`gauge-wrapper ${showPadding && 'gauge-button-padding'}`}
          >
            <img className="gauge" src={gauge} alt="gauge" />
            <div className="pointer-animation-wrapper">
              <img
                className="pointer"
                src={pointer}
                style={rotation}
                alt="pointer"
              />{' '}
            </div>
            <div className="pointer-animation-wrapper">
              <img className="gauge" src={gaugeface} alt="" />
            </div>
          </div>
          <div className="gauge-text">
            <p className="text-center card-title">
              <b>{cardTitleText}</b>
              <br />
              {showButton ? (
                <a
                  href={link}
                  className="btn btn-primary btn-lg mt-3"
                  style={{ lineHeight: '40px' }}
                >
                  READ MORE
                </a>
              ) : (
                <span />
              )}
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

TopicCard.propTypes = {
  cardTitleText: PropTypes.string,
  cardValue: PropTypes.number,
  cardTextString: PropTypes.string,
  indicatorColor: PropTypes.string
};

export default TopicCard;
