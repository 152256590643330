import { Environment, RecordSource, Store } from 'relay-runtime';
import errorsMiddleware from './errorsMiddleware';
import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware
} from 'react-relay-network-modern/node8';

const network = new RelayNetworkLayer([
  authMiddleware({
    prefix: 'JWT ',
    allowEmptyToken: true,
    token: () => sessionStorage.getItem('jwt')
  }),
  errorsMiddleware(),
  urlMiddleware({
    url: () => Promise.resolve(process.env.REACT_APP_GRAPHQL_ENDPOINT)
  })
]);

const environment = new Environment({
  network: network,
  store: new Store(new RecordSource())
});

export default environment;
