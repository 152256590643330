import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import ReactstrapSmileyRadio from './FormikReactStrapSmileyRadio';
import { Field } from 'formik';

const Smiley = ({ questionName, errors, submitCount }) => (
  <React.Fragment>
    <div id="smiley">
      <Field
        type="radio"
        className="radioQuestion"
        name={questionName}
        label="1"
        value="1"
        component={ReactstrapSmileyRadio}
      />
      <Field
        type="radio"
        className="radioQuestion"
        name={questionName}
        label="2"
        value="2"
        component={ReactstrapSmileyRadio}
      />
      <Field
        type="radio"
        className="radioQuestion"
        name={questionName}
        label="3"
        value="3"
        component={ReactstrapSmileyRadio}
      />
      <Field
        type="radio"
        className="radioQuestion"
        name={questionName}
        label="4"
        value="4"
        component={ReactstrapSmileyRadio}
      />
      <Field
        type="radio"
        className="radioQuestion"
        name={questionName}
        label="5"
        value="5"
        component={ReactstrapSmileyRadio}
      />
    </div>
    <div>
      {submitCount > 0 && errors && errors[questionName] ? (
        <FormFeedback className="d-block">{errors[questionName]}</FormFeedback>
      ) : (
        ''
      )}
    </div>
  </React.Fragment>
);

Smiley.propTypes = {
  questionName: PropTypes.string,
  errors: PropTypes.object,
  submitCount: PropTypes.number
};

export default Smiley;
