import React from 'react';
import PropTypes from 'prop-types';
// import { Checkbox as reactCheckbox } from 'reactstrap';
import ReactstrapCheckbox from './ReactstrapCheckbox';
import { Field } from 'formik';

const FormikCheckbox = ({ questionName, label, elementKey, arrayHelper }) => (
  <Field
    type="checkbox"
    name={questionName}
    label={label}
    elementKey={elementKey}
    value={questionName}
    component={ReactstrapCheckbox}
    arrayHelper={arrayHelper}
  />
);

FormikCheckbox.propTypes = {
  questionName: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  submitCount: PropTypes.number,
  form: PropTypes.object,
  arrayHelper: PropTypes.object,
  elementKey: PropTypes.string
};

export default FormikCheckbox;
